import actionCreatorFactory from "typescript-fsa"

const actionCreator = actionCreatorFactory("User")

import { IBaseUser, IUser, ICredentials } from "./UsersModels"


export const login =
    actionCreator.async<{ payload: ICredentials },
                        { user: IUser },
                        { errorMessage: string }
                    >("LOGIN")


export const logout =
    actionCreator.async<void,
                        void,
                        void
                    >("LOGOUT")


export const getCurrentUserInfo =
    actionCreator.async<void,
                        { currentUser: IUser },
                        void
                    >("GET_CURRENT_USER_INFO")


export const getTeam =
    actionCreator.async<void,
                        { team: IBaseUser[] },
                        { errorMessage: string }
                    >("GET_TEAM")


export const toggleEditUser = actionCreator<void>("TOGGLE_EDIT_USER")

export const toggleEditUserDropdown =
    actionCreator<{ payload: { userId: number | null, editUserDropdownFlag: boolean }}>("TOGGLE_EDIT_USER_DROPDOWN")

export const searchTeam = actionCreator<{ searchText: string }>("SEARCH_TEAM")

export const editTeamMember =
    actionCreator.async<{ payload: IBaseUser },
                        { editTeamMemberResult: boolean },
                        { errorMessage: string }
                    >("EDIT_TEAM_MEMBER")


export const deleteTeamMember =
    actionCreator.async<{ id: number, editUserFlag: boolean },
                        { deleteTeamMemberResult: boolean },
                        { errorMessage: string }
                    >("DELETE_TEAM_MEMBER")





