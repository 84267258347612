import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { initialize } from "redux-form"
import { Dispatch, Action } from "redux"

import Loading from "../components/Loading"

import TeamSearch from "../components/TeamSearch"
import TeamListItem from "../components/TeamListItem"
import TeamEditUser from "../components/TeamEditUser"

import {
  getTeam, toggleEditUser, toggleEditUserDropdown,
  searchTeam, deleteTeamMember,
} from "../reducers/UsersActions"

import { RootState } from "../reducers"
import { IBaseUser } from "../reducers/UsersModels"

interface IStateProps {
  filteredTeam?: IBaseUser[],
  isLoadingTeam: boolean,
  editUserFlag: boolean,
  userId?: number | null,
  editUserDropdownFlag: boolean,
  currentUserAccountTypeId?: number,
}

interface IDispatchProps {
  getTeam: () => void,
  onToggleEditUser: () => void,
  onToggleEditUserDropdown: (userId: number | null, editUserDropdownFlag: boolean) => void,
  initializeForm: (data: IBaseUser) => void,
  onSearchTeam: (searchText: string) => void,
  onDeleteTeamMember: (id: number, editUserFlag: boolean) => void,
}

const Team: React.FunctionComponent<IStateProps & IDispatchProps> = (props) => {

  useEffect(() => {
    props.getTeam()
    document.title = "Team"
  }, [])

  const {
      filteredTeam, isLoadingTeam,
      editUserFlag, onToggleEditUser, initializeForm,
      onToggleEditUserDropdown, userId, editUserDropdownFlag,
      onSearchTeam, currentUserAccountTypeId, onDeleteTeamMember,
  } = props

  return (
    <React.Fragment>

      <Loading show={isLoadingTeam} text={`Loading team...`} imgClass="block-center" divClass="main__content" />

        {! isLoadingTeam && filteredTeam && (
          <div className="main__content">
            <div className="container" data-testid="team">
              <header>
                <div className="row">
                  <div className="col-sm-4">
                    <h1 className="section__title">Team</h1>
                  </div>
                  <TeamSearch onSearchTeam={onSearchTeam}/>
                </div>
              </header>
              <section className="listing-header listing-header--team">
                <span className="listing-header__label">Name</span>
                <span className="listing-header__label">Email</span>
                <span className="listing-header__label">Role</span>
              </section>
              <section>
                {filteredTeam.map((person) => (
                  <TeamListItem
                    key={person.id}
                    person={person}
                    userId={userId}
                    onToggleEditUser={onToggleEditUser}
                    initializeForm={initializeForm}
                    editUserDropdownFlag={editUserDropdownFlag}
                    onToggleEditUserDropdown={onToggleEditUserDropdown}
                    currentUserAccountTypeId={currentUserAccountTypeId}
                    onDeleteTeamMember={onDeleteTeamMember}
                    editUserFlag={editUserFlag}
                  />
                ))}
              </section>
              {/* <Paging /> */}
            </div>
          </div>
      )}

      <TeamEditUser onToggleEditUser={onToggleEditUser} editUserFlag={editUserFlag} />
    </React.Fragment>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  filteredTeam: state.users.filteredTeam,
  isLoadingTeam: state.users.isLoadingTeam,
  editUserFlag: state.users.editUserFlag,
  userId: state.users.userId,
  editUserDropdownFlag: state.users.editUserDropdownFlag,
  currentUserAccountTypeId: state.users.currentUser ? state.users.currentUser.account_type_id : undefined,
})


const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    getTeam: () => dispatch(getTeam.started()),
    onToggleEditUser: () => dispatch(toggleEditUser()),
    onToggleEditUserDropdown: (userId: number | null, editUserDropdownFlag: boolean) =>
      dispatch(toggleEditUserDropdown({ payload: { userId, editUserDropdownFlag }})),
    initializeForm: (data: IBaseUser) => dispatch(initialize("User", data)),
    onSearchTeam: (searchText: string) => dispatch(searchTeam({searchText})),
    onDeleteTeamMember: (id: number, editUserFlag: boolean) => dispatch(deleteTeamMember.started({id, editUserFlag})),
  }
)

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Team) as any,
)
