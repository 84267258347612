import React from "react"
import { Link } from "react-router-dom"

import { IFeedback, IFeedbackQuestion } from "../reducers/FeedbackModels"

import Icon from "./Icon"
import { dateFormat, timeFormat } from "../utils/utils"
import handleOutsideEventHook from "../utils/useHandleOutsideEventHook"
import Loading from "./Loading"

interface IOwnProps {
  item?: IFeedback,
  showFeedback: boolean,
  onShouldShowFeedback: (showFeedback: boolean, activeFeedback: IFeedback) => void,
  onDeleteReview: (reviewId: number) => void,
  isLoadingDeleteReview: boolean,
}

const FeedbackView: React.FunctionComponent<IOwnProps> = (props) => {

  const { showFeedback, onShouldShowFeedback, item, onDeleteReview, isLoadingDeleteReview } = props

  if (! item) {
    return null
  }

  const {
    base_photo, garment_name, style_number, username, user_email, review_name, date_sent, questions, reward, answer_id,
  } = item

  const ref = handleOutsideEventHook(() => onShouldShowFeedback(false, item), showFeedback).ref

  return (
    <aside
      className={`drawer drawer--left ${showFeedback && `show`}`}
      id="feedback-details"
      role="menu"
      aria-label="Feedback details"
      tabIndex={-1}
      ref={ref}
    >
      <Loading
        show={isLoadingDeleteReview}
        text={"Loading..."}
        imgClass="block-center"
        divClass="main__content"
      />

      {! isLoadingDeleteReview && (
        <>
          <Link
            className="drawer__close"
            to="#"
            data-dismiss="drawer"
            aria-label="Close"
            onClick={() => onShouldShowFeedback(false, item)}
          >
            <Icon name="cross-rounded" />
          </Link>

          <div className="drawer__content">

            <header className="drawer__header">
              <h3 className="drawer__title">{`Feedback Details ${review_name}`}</h3>
            </header>

            <section className="drawer__main">
              <div className="box box--default box--sm mb--md">
                <div className="row">

                  <div className="col-xs-2">
                    <img className="contain center" src={base_photo} alt="" width="50" />
                  </div>

                  <div className="col-xs-10 col-sm-6">
                    <div className="mb--base">
                      <h4 className="mb--none">{username}</h4>
                      {user_email}
                    </div>
                    <div className="mb--base">
                      {`${dateFormat(new Date(date_sent))} ${timeFormat(new Date(date_sent))}`}
                      <br />{review_name}
                    </div>
                    {/* <div className="mb--base">iOS [version]<br />Location</div> */}
                    {reward && <div className="mb--base">{`Reward: ${reward}`}</div>}
                  </div>

                  <div className="col-sm-4">
                    <article className="box box--light box--sm">
                      <figure className="mb--xs">
                        <img className="contain center" src={base_photo} alt="" />
                      </figure>
                      <h3 className="mb--none">
                        {garment_name}
                      </h3>
                      <small className="text--quiet upcase">
                        {style_number}
                      </small>
                    </article>
                  </div>

                </div>
              </div>

              {questions && questions.length > 0 &&
                questions.map((questionObject: IFeedbackQuestion) => {
                  const { answer, question } = questionObject
                  return (
                    <div className="form-group" key={question}>
                      <div className="text--quiet upcase">
                        {question}
                      </div>
                      <span className="text--base">{answer}</span>
                    </div>
                  )
                })}

            </section>
            <footer className="drawer__footer">
              <div className="row middle-sm">
                <div className="col-sm">
                  <Link className="link--danger upcase" to="#" onClick={() => onDeleteReview(answer_id)}>
                    Delete feedback
                  </Link>
                </div>
                <div className="col-sm txtr">
                  {/* <Link className="button button--primary" to="#">
                <Icon name="printer" /> Print Feedback
              </Link> */}
                </div>
              </div>
            </footer>
          </div>
        </>
      )}
    </aside>
  )
}

export default FeedbackView
