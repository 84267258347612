const binsData = () => {
  return [
    {
      name: "Washing",
      bins:
        [
          {
            id: 1,
            displayName: "Hand wash",
            internalName: "handWash",
          },
          {
            id: 2,
            displayName: "Machine wash cold",
            internalName: "machineWashCold",
          },
          {
            id: 3,
            displayName: "Machine wash warm",
            internalName: "machineWashWarm",
          },
          {
            id: 4,
            displayName: "Non-chlorine bleach",
            internalName: "nonChlorineBleach",
          },
          {
            id: 5,
            displayName: "Non-chlorine bleach for colors",
            internalName: "nonChlorineBleachForColors",
          },
          {
            id: 6,
            displayName: "Do not bleach",
            internalName: "doNotBleach",
          },
          {
            id: 7,
            displayName: "Do not wash",
            internalName: "doNotWash",
          },
        ],
    },
    {
      name: "Drycleaning",
      bins:
        [
          {
            id: 8,
            displayName: "Dry clean",
            internalName: "dryClean",
          },
          {
            id: 9,
            displayName: "Do not dry clean",
            internalName: "doNotDryClean",
          },
        ],
    },
    {
      name: "Drying",
      bins:
        [
          {
            id: 10,
            displayName: "Tumble dry",
            internalName: "tumbleDry",
          },
          {
            id: 11,
            displayName: "Tumble dry low",
            internalName: "tumbleDryLow",
          },
          {
            id: 12,
            displayName: "Do not tumble dry",
            internalName: "doNotTumbleDry",
          },
          {
            id: 13,
            displayName: "Do not wring or twist",
            internalName: "doNotWringOrTwist",
          },
        ],
    },
    {
      name: "Ironing",
      bins:
        [
          {
            id: 14,
            displayName: "Warm iron",
            internalName: "warmIron",
          },
          {
            id: 15,
            displayName: "Cool iron",
            internalName: "coolIron",
          },
          {
            id: 16,
            displayName: "Do not iron",
            internalName: "doNotIron",
          },
        ],
    },
  ]
}

export default binsData
