import React from "react"
import { Field, FormSection, formValueSelector } from "redux-form"
import { connect } from "react-redux"

import { RootState } from "../../reducers"
import Icon from "../Icon"
import binsData from "../../utils/bins"

interface IStateProps {
  additionalInfoWashing: string,
  additionalInfoDrycleaning: string,
  additionalInfoDrying: string,
  additionalInfoIroning: string,
}

const CareInstructions: React.FunctionComponent<IStateProps> = (props) => {

  const {
    additionalInfoWashing, additionalInfoDrycleaning, additionalInfoDrying, additionalInfoIroning,
  } = props

  const allAdditionalInfo: any = {
    additionalInfoWashing, additionalInfoDrycleaning, additionalInfoDrying, additionalInfoIroning,
  }

  const allBins = binsData()

  return (
    <fieldset className="box box--light box--shadowed box--grid mb--md">
      {allBins.map((binType) => (
        <FormSection name={binType.name} key={binType.name}>
          <div>
            <h3 className="title">{binType.name}</h3>
            <div className="form-check-selectors box-outset">
              {binType.bins.map((bin) => (
                <div key={bin.id} className="form-check-selector">
                  <Field
                    component="input"
                    className="form-check__input"
                    data-testid={`garment${bin.internalName}`}
                    type="radio"
                    name="bin"
                    id={bin.internalName}
                    value={bin.internalName}
                  />
                  <label
                    className="form-check-selector__label"
                    data-testid={bin.internalName}
                    htmlFor={bin.internalName}
                  >
                    <Icon name={bin.internalName} />
                    <span className="text--xs">{bin.displayName}</span>
                  </label>
                </div>
              ))}
            </div>
            <div className="form-group box-outset">
              <Field
                component="input"
                className="form-control"
                data-testid={`garmentFormAdditionalInfo${binType.name}`}
                type="text"
                name={`additionalInformation${binType.name}`}
                id={`additionalInformation${binType.name}`}
                data-empty={! allAdditionalInfo[`additionalInfo${binType.name}`]}
              />
              <label
                className="form-control-label"
                htmlFor={`additionalInformation${binType.name}`}
              >
                Additional information
              </label>
            </div>
          </div>
        </FormSection>
      ))}
    </fieldset>
  )
}

const selector = formValueSelector("Garment")

const mapStateToProps = (state: RootState): IStateProps => ({
  additionalInfoWashing: selector(state, "careInstructions.Washing.additionalInformationWashing"),
  additionalInfoDrycleaning: selector(state, "careInstructions.Drycleaning.additionalInformationDrycleaning"),
  additionalInfoDrying: selector(state, "careInstructions.Drying.additionalInformationDrying"),
  additionalInfoIroning: selector(state, "careInstructions.Ironing.additionalInformationIroning"),
})

export default connect(
  mapStateToProps,
  null,
)(CareInstructions)

