import { Action } from "redux"
import { isType } from "typescript-fsa"

import { IFeedback, IFilters } from "./FeedbackModels"
import { getReviews, deleteReview, shoudShowFeedback, removeReview } from "./FeedbackActions"
import { removeDuplicates } from "../utils/utils"


export interface IFeedbackState {
  isLoadingGetReviews: boolean,
  errorGetReviews?: string,
  reviews?: IFeedback[],
  filters: IFilters,

  showFeedback: boolean,
  activeFeedback?: IFeedback,

  isLoadingDeleteReview: boolean,
  errorDeleteReview?: string,
  deleteReviewResult: boolean,
}

export const initialState: IFeedbackState = {
  isLoadingGetReviews: false,
  errorGetReviews: undefined,
  reviews: undefined,
  filters: {types: [], lines: [], garments: []},

  showFeedback: false,
  activeFeedback: undefined,

  isLoadingDeleteReview: false,
  errorDeleteReview: undefined,
  deleteReviewResult: false,
}

export default (state: IFeedbackState = initialState, action: Action): IFeedbackState => {

  if (isType(action, getReviews.started)) {
    return {
      ...state,
      isLoadingGetReviews: true,
      errorGetReviews: undefined,
      reviews: undefined,
    }
  }

  if (isType(action, getReviews.done)) {
    const { reviews } = action.payload.result

    const allTypes = reviews.map((feedback: IFeedback) => feedback.garment_type)
    const allLines = reviews.map((feedback: IFeedback) => feedback.garment_line)
    const allGarments = reviews.map((feedback: IFeedback) => feedback.garment_name)

    const filters = {
      types: removeDuplicates(allTypes),
      lines: removeDuplicates(allLines),
      garments: removeDuplicates(allGarments),
    }

    return {
      ...state,
      isLoadingGetReviews: false,
      errorGetReviews: undefined,
      reviews,
      filters,
    }
  }

  if (isType(action, getReviews.failed)) {
    const { errorMessage } = action.payload.error
    return {
      ...state,
      isLoadingGetReviews: false,
      errorGetReviews: errorMessage,
      reviews: undefined,
    }
  }

  if (isType(action, removeReview)) {
    const { reviewId } = action.payload
    const { reviews } = state
    return {
      ...state,
      reviews: reviews ? reviews.filter((feedback: IFeedback) => feedback.answer_id !== reviewId) : reviews,
    }
  }

  if (isType(action, shoudShowFeedback)) {
    const { showFeedback, activeFeedback } = action.payload
    return {
      ...state,
      showFeedback,
      activeFeedback,
    }
  }


  if (isType(action, deleteReview.started)) {
    return {
      ...state,
      isLoadingDeleteReview: true,
      errorDeleteReview: undefined,
      deleteReviewResult: false,
    }
  }

  if (isType(action, deleteReview.done)) {
    const { deleteReviewResult } = action.payload.result

    return {
      ...state,
      isLoadingDeleteReview: false,
      errorDeleteReview: undefined,
      deleteReviewResult,
    }
  }

  if (isType(action, deleteReview.failed)) {
    const { errorMessage } = action.payload.error
    return {
      ...state,
      isLoadingDeleteReview: false,
      errorDeleteReview: errorMessage,
      deleteReviewResult: false,
    }
  }

  return state
}



