import React, { useLayoutEffect } from "react"
import { Dispatch, Action } from "redux"
import { connect } from "react-redux"
import { getGarments, changeFilters } from "../reducers/GarmentActions"
import CatalogueGridItem from "../components/CatalogueGridItem"
import CatalogueListItem from "../components/CatalogueListItem"
import CatalogueFilters from "../components/CatalogueFilters"
import CatalogueChooseView from "../components/CatalogueChooseView"
import CatalogueSearch from "../components/CatalogueSearch"
import Loading from "../components/Loading"
import { IShortGarment, IFilters } from "../reducers/GarmentModels"
import { RootState } from "../reducers"

interface IStateProps {
  isLoading: boolean,
  showListViewFlag: boolean,
  filteredGarments?: IShortGarment[],
}
interface IDispatchProps {
  getGarments: () => void,
  onChangeFilters: (filters: IFilters) => void,
}
const Catalogue: React.FunctionComponent<IStateProps & IDispatchProps> = (props) => {

  useLayoutEffect(() => {
    props.getGarments()
    document.title = "Catalogue"
  }, [])

  const { isLoading, showListViewFlag, onChangeFilters, filteredGarments } = props

  return (
    <React.Fragment>
      <Loading show={isLoading} text={`Loading...`} imgClass="block-center" divClass="main__content" />
      {! isLoading && filteredGarments && (
        <div className="main__content" data-testid="defaultPageAfterLogin">
          <div className="container" data-testid="catalogue">
            <header>
              <h1 className="section__title">Catalogue</h1>
            </header>
            <section className="listing-filters">
              <CatalogueFilters />
              <div className="listing-filters__group">
                <CatalogueChooseView />
                <CatalogueSearch onChangeFilters={onChangeFilters} />
              </div>
            </section>
            {showListViewFlag && (
              <div>
                <section className="listing-header listing-header--product">
                  <span className="listing-header__label">Basic info</span>
                  <span className="listing-header__label">Type</span>
                  <span className="listing-header__label">Line</span>
                  <span className="listing-header__label">Style No</span>
                </section>
                {filteredGarments.map((garment, i) => (
                  <CatalogueListItem garment={garment} key={garment.style_number} index={i} />
                ))}
              </div>
            )}
            {! showListViewFlag && (
              <section className="listing-grid">
                {filteredGarments.map((garment) => (
                  <CatalogueGridItem garment={garment} key={garment.style_number} />
                ))}
              </section>
            )}
            {/* <Paging /> */}
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
const mapStateToProps = (state: RootState): IStateProps => ({
  isLoading: state.garments.isLoading,
  showListViewFlag: state.garments.showListViewFlag,
  filteredGarments: state.garments.filteredGarments,
})
const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    getGarments: () => dispatch(getGarments.started()),
    onChangeFilters: (filters: IFilters) => dispatch(changeFilters({ filters })),
  }
)
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Catalogue)
