import { call, all } from "redux-saga/effects"


/* ------------- Sagas ------------- */

import {
  watchLogin, watchLogout, watchGetCurrentUser,
  watchGetAllTeam, watchEditTeamUser, watchDeleteTeamUser,
} from "./UsersSagas"

import {
  watchGetGarments, watchGetGarment, watchGetAddGarment,
} from "./GarmentSagas"

import {
  watchGetSupplyChainRequest, watchAddEntry, watchDeleteEntry,
} from "./SupplyChainSagas"

import {
  watchGetRecycleInbox, watchMarkGarmentAsReceivedSaga, watchMarkGarmentAsNotReceivedSaga,
} from "./RecycleSagas"

import {
  watchGetBrandSettings, watchSaveBrandSettings, watchGetRecycleOptions, watchSaveRecycleOptions,
} from "./SettingsSagas"

import { watchGetReviews, watchDeleteReview } from "./FeedbackSagas"

/* ------------- Connect Types To Sagas ------------- */

export default function* rootSaga() {
  try {
    yield all([
      call(watchLogin),
      call(watchLogout),
      call(watchGetCurrentUser),
      call(watchGetAllTeam),
      call(watchEditTeamUser),
      call(watchDeleteTeamUser),

      call(watchGetSupplyChainRequest),
      call(watchAddEntry),
      call(watchDeleteEntry),

      call(watchGetGarments),
      call(watchGetGarment),
      call(watchGetAddGarment),

      call(watchGetRecycleInbox),
      call(watchMarkGarmentAsReceivedSaga),
      call(watchMarkGarmentAsNotReceivedSaga),

      call(watchGetBrandSettings),
      call(watchSaveBrandSettings),
      call(watchGetRecycleOptions),
      call(watchSaveRecycleOptions),
      call(watchGetReviews),
      call(watchDeleteReview),
    ])
  } catch (err) {
    console.log("==============================")
    console.log(err)
    console.log("==============================")
  }
}

