import React, { createRef, useEffect } from "react"
import { findDOMNode } from "react-dom"
import { connect } from "react-redux"
import { Field, formValueSelector  } from "redux-form"

import { RootState } from "../../reducers"

interface IStateProps {
  garmentName: string,
  styleNumber: string,
  description: string,
  line: string,
  type: string,
  fabricContent: string,
  madeIn: string,
}

const BasicInformation: React.FunctionComponent<IStateProps> = (props) => {
  const descriptionTextarea = createRef<Field>()
  const fabricContentTextarea = createRef<Field>()

  useEffect(() => {
    if  (props.description) {
      autoSizeTextarea(descriptionTextarea.current)
    }
  }, [props.description])

  useEffect(() => {
    if  (props.fabricContent) {
      autoSizeTextarea(fabricContentTextarea.current)
    }
  }, [props.fabricContent])

  const autoSizeTextarea = (ref: any) => {
    const domNode = findDOMNode(ref) as any
    // Textarea element has 5 rows, so its height is 100px
    if (domNode && 100 < domNode.scrollHeight) {
      domNode.style.height = "auto"
      domNode.style.height = domNode.scrollHeight + "px"
    }
  }

  const { garmentName, styleNumber, description, line, type, fabricContent, madeIn } = props

  return (
    <div>
      <div className="form-group">
        <Field
          data-testid="garmentFormName"
          component="input"
          className="form-control"
          type="text" name="garmentName"
          id="garmentName"
          value=""
          data-empty={! garmentName}
        />
        <label className="form-control-label" htmlFor="garmentName">Garment name</label>
      </div>
      <div className="form-group">
        <Field
          component="input"
          className="form-control"
          data-testid="garmentFormStyleNumber"
          type="text" name="styleNumber"
          id="styleNumber"
          value=""
          data-empty={! styleNumber}
        />
        <label className="form-control-label" htmlFor="styleNumber">Style number</label>
      </div>
      <div className="form-group">
        <Field
          component="textarea"
          className="form-control"
          data-testid="garmentFormDescription"
          name="description"
          id="description"
          rows="5"
          data-empty={! description}
          ref={descriptionTextarea}
        />
        <label className="form-control-label" htmlFor="description">Description</label>
      </div>
      <div className="form-group">
        <Field
          component="input"
          className="form-control"
          data-testid="garmentFormLine"
          type="text"
          name="line"
          id="line"
          value=""
          data-empty={! line}
        />
        <label className="form-control-label" htmlFor="line">Line</label>
      </div>
      <div className="form-group">
        <Field
          component="input"
          className="form-control"
          data-testid="garmentFormType"
          type="text"
          name="type"
          id="type"
          value=""
          data-empty={! type}
        />
        <label className="form-control-label" htmlFor="type">Type</label>
      </div>
      <div className="form-group">
        <Field
          component="textarea"
          className="form-control"
          data-testid="garmentFormFabricContent"
          name="fabricContent"
          id="fabricContent"
          rows="5"
          data-empty={! fabricContent}
          ref={fabricContentTextarea}
        />
        <label className="form-control-label" htmlFor="fabricContent">Fabric content</label>
      </div>
      <div className="form-group">
        <Field
          component="input"
          className="form-control"
          data-testid="garmentFormMadeIn"
          type="text"
          name="madeIn"
          id="madeIn"
          value=""
          data-empty={! madeIn}
        />
        <label className="form-control-label" htmlFor="madeIn">Made in</label>
      </div>
    </div>
  )
}

const selector = formValueSelector("Garment")

const mapStateToProps = (state: RootState): IStateProps => ({
  garmentName: selector(state, "basicInformation.garmentName"),
  styleNumber: selector(state, "basicInformation.styleNumber"),
  description: selector(state, "basicInformation.description"),
  line: selector(state, "basicInformation.line"),
  type: selector(state, "basicInformation.type"),
  fabricContent: selector(state, "basicInformation.fabricContent"),
  madeIn: selector(state, "basicInformation.madeIn"),
})

export default connect(mapStateToProps, null)(BasicInformation)

