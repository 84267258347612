import request, { generatePostOptionsForRequest, generatePostOptionsForFileUpload } from "../utils/request"
import { call, put, takeLatest } from "redux-saga/effects"
import { SagaIterator } from "redux-saga"


import {
  getBrandSettings, saveBrandSettings, getRecycleOptions, saveRecycleOptions,
} from "../reducers/SettingsActions"

import { getCurrentUserInfo } from "../reducers/UsersActions"

import { notify } from "../reducers/GeneralActions"


/* ************************************************************* */
function* getBrandSettingsRequest() {
  try {
    const result = yield call(request, "/brands/brandSettings")
    yield put(getBrandSettings.done({ result }))
  } catch (err) {
    console.log(err)
    const { message } = err
    yield put(getBrandSettings.failed({ error: { errorMessage: message } }))
    yield put(notify("error", "Unable to get brand information."))
  }
}


export function* watchGetBrandSettings(): SagaIterator {
  yield takeLatest(getBrandSettings.started, getBrandSettingsRequest)
}
/* ************************************************************* */

function* saveBrandSettingsRequest(action: any) {

  const { payload } = action.payload

  try {
    const result = yield call(request, "/brands/brandSettings", generatePostOptionsForFileUpload(payload))
    yield put(saveBrandSettings.done({ params: { payload }, result }))
    yield put(notify("success", "Brand information was saved successfully."))
    yield put(getCurrentUserInfo.started())
  } catch (err) {
    console.log(err)
    const { message } = err
    yield put(saveBrandSettings.failed({ params: { payload }, error: { errorMessage: message } }))
    yield put(notify("error", "There was an error while saving brand information."))
  }
}

export function* watchSaveBrandSettings(): SagaIterator {
  yield takeLatest(saveBrandSettings.started, saveBrandSettingsRequest)
}
/* ************************************************************* */

/* ************************************************************* */
function* getRecycleOptionsRequest() {
  try {
    const result = yield call(request, "/brands/recycleOptions")
    yield put(getRecycleOptions.done({ result }))
  } catch (err) {
    console.log(err)
    const { message } = err
    yield put(getRecycleOptions.failed({ error: { errorMessage: message } }))
    yield put(notify("error", "Unable to get recycle information."))
  }
}


export function* watchGetRecycleOptions(): SagaIterator {
  yield takeLatest(getRecycleOptions.started, getRecycleOptionsRequest)
}
/* ************************************************************* */

function* saveRecycleOptionsRequest(action: any) {

  const { payload } = action.payload

  try {
    const result = yield call(
      request, "/brands/recycleOptions", generatePostOptionsForRequest({ recycleOptions: payload }),
    )
    yield put(saveRecycleOptions.done({ params: { payload }, result }))
    yield put(notify("success", "Recycle information was saved successfully."))
  } catch (err) {
    console.log(err)
    const { message } = err
    yield put(saveRecycleOptions.failed({ params: { payload }, error: { errorMessage: message } }))
    yield put(notify("error", "There was an error while saving recycle information."))
  }
}

export function* watchSaveRecycleOptions(): SagaIterator {
  yield takeLatest(saveRecycleOptions.started, saveRecycleOptionsRequest)
}
/* ************************************************************* */
