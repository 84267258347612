import actionCreatorFactory from "typescript-fsa"
import { IFeedback } from "./FeedbackModels"

const actionCreator = actionCreatorFactory("Feedback")

export const getReviews =
  actionCreator.async<  void,
                      { reviews: IFeedback[] },
                      { errorMessage: string }
                     >("GET_REVIEWS")

export const shoudShowFeedback =
  actionCreator<{showFeedback: boolean, activeFeedback?: IFeedback}>("SHOULD_SHOW_FEEDBACK")

export const removeReview =
  actionCreator<{reviewId: number}>("REMOVE_REVIEW")

export const deleteReview =
  actionCreator.async<{ reviewId: number },
                      { deleteReviewResult: boolean },
                      { errorMessage: string }
                   >("DELETE_REVIEW")
