import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"

import { RootState } from "../reducers"
import { IRecycleOptions } from "../reducers/SettingsModels"
import { getRecycleOptions, saveRecycleOptions } from "../reducers/SettingsActions"

import Loading from "./Loading"

interface IOwnProps {
  tab: string,
}

interface IStateProps {
  isLoadingGetRecycleOptions: boolean,
  recycleOptions?: IRecycleOptions,
  isLoadingSaveRecycleOptions: boolean,
}

interface IDispatchProps {
  onGetRecycleOptions: () => void,
  onSaveRecycleOptions: (payload: any) => void,
}

const SettingsRecycling: React.SFC<IOwnProps & IStateProps & IDispatchProps> = (props) => {

  const {
    tab, onGetRecycleOptions, isLoadingGetRecycleOptions, recycleOptions,
    onSaveRecycleOptions, isLoadingSaveRecycleOptions,
  } = props

  const [recyclingAddress, setRecyclingAddress] = useState(undefined) as any
  const [recyclingDiscountValue, setRecyclingDiscountValue] = useState("No discount") as any
  const [recyclingTerms, setRecyclingTerms] = useState(undefined) as any

  useEffect(() => {
    onGetRecycleOptions()
  }, [])

  useEffect(() => {
    if (recycleOptions) {
      const { recycleAddress, discount, terms } = recycleOptions
      setRecyclingAddress(recycleAddress)
      setRecyclingDiscountValue(discount)
      setRecyclingTerms(terms)
    }
  }, [recycleOptions])

  return (
    <div
      className={`tab-pane fade ${tab === "Recycling" && "active show"}`}
      role="tabpanel"
      id="tab-recycling"
      aria-labelledby="tab-toggle-recycling"
    >
      <Loading
        show={isLoadingGetRecycleOptions || isLoadingSaveRecycleOptions}
        text={"Loading..."}
        imgClass="block-center"
        divClass="col-sm-12"
      />
      {(! isLoadingGetRecycleOptions && ! isLoadingSaveRecycleOptions) && (
        <fieldset className="box box--light box--shadowed mb--md">
          <div className="form-group">
            <label
              className="form-control-label is-static"
              htmlFor="recycling-address"
            >
              Recycling address
          </label>
            <textarea
              className="form-control"
              id="recycling-address"
              rows={5}
              data-empty=""
              value={recyclingAddress}
              onChange={(e) => setRecyclingAddress(e.target.value)}
            />
            <div className="form-text">
              This is the address users will be instructed to send their recycling to.
                  </div>
          </div>
          <div className="form-group">
            <label className="form-control-label is-static" htmlFor="discount">Discount value</label>
            <select
              className="form-control"
              id="discount"
              value={recyclingDiscountValue}
              onChange={(event) => setRecyclingDiscountValue(event.target.value)}
            >
              <option value="10">10%</option>
              <option value="15">15%</option>
              <option value="20">20%</option>
              <option value="25">25%</option>
              <option value="No discount">No discount</option>
            </select>
          </div>
          <div className="form-group">
            <label className="form-control-label is-static" htmlFor="recycling-terms">
              Recycling terms
          </label>
            <textarea
              className="form-control"
              id="recycling-terms"
              rows={5}
              value={recyclingTerms}
              onChange={(e) => setRecyclingTerms(e.target.value)}
              data-empty=""
            />

            <div className="form-text">
              Enter any terms that you may want to apply to this discount.
          </div>
          </div>
          <div className="form-actions mt--md">
            <button
              className="button button--primary"
              // type="submit"
              onClick={() => onSaveRecycleOptions({
                recycleAddress: recyclingAddress,
                discount: recyclingDiscountValue,
                terms: recyclingTerms,
              })}
            >
              Save Settings
          </button>
            {/* <a className="upcase">Cancel</a> */}
          </div>
        </fieldset>
      )}
    </div>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  isLoadingGetRecycleOptions: state.settings.isLoadingGetRecycleOptions,
  recycleOptions: state.settings.recycleOptions,
  isLoadingSaveRecycleOptions: state.settings.isLoadingSaveRecycleOptions,
})

const mapDispatchToProps =
  (dispatch: Dispatch<Action>): IDispatchProps => (
    {
      onGetRecycleOptions: () => dispatch(getRecycleOptions.started()),
      onSaveRecycleOptions: (payload: any) => dispatch(saveRecycleOptions.started({ payload })),
    }
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingsRecycling)
