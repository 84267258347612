import React from "react"
import { Field, formValueSelector, change, FormSection } from "redux-form"
import { connect } from "react-redux"
import Dropzone from "react-dropzone"

import { youtubeVideoUrlParser } from "../../utils/utils"
import { chooseColor } from "../../reducers/GarmentActions"
import Icon from "../../components/Icon"
import { RootState } from "../../reducers"
import { Dispatch, Action } from "redux"
import { IGarmentUnitInformation, IRemovedPhotos } from "../../reducers/GarmentModels"
import { IPhotosObject } from "../../reducers/GeneralModels"

import { notify } from "../../reducers/GeneralActions"


interface IOwnProps {
  input: {
    value: IPhotosObject,
    onChange: any,
  },
  name: string,
  removedPhotos: IRemovedPhotos,
  chosenColor: string,
  meta: any,
  showPhotoSizeTooLargeNotification: () => void,
}

const MAX_PHOTO_SIZE = 3145728
const MAX_PHOTO_SIZE_STRING = "3MB"

const renderDropzoneInput: React.FunctionComponent<IOwnProps> = (field) => {

  const { removedPhotos, chosenColor, showPhotoSizeTooLargeNotification } = field
  const files = field.input.value
  const fileObjects = { ...files }

  const addPhoto = (filesToUpload: File[]) => {
    filesToUpload.forEach((file) => {
      fileObjects[file.name] = file
    })
    return fileObjects
  }

  const removePhoto = (file: File) => {
    const { name, size } = file

    // typescript TODO check if it works this way
    // if(! name)
    //   ({ originalname: name } = file)

    // only do this for photos that were already saved to server => they don"t have size set
    if (! size) {
      if (! removedPhotos[chosenColor]) {
        removedPhotos[chosenColor] = []
      }
      if (! removedPhotos[chosenColor].includes(name)) {
        removedPhotos[chosenColor].push(name)
      }
      field.meta.dispatch(change("Garment", "removedPhotos", removedPhotos))
    }


    delete fileObjects[name]
    return fileObjects
  }


  return (
    <div>
      {files && (
        <div className="uploaded-images">
          {Object.values(files).map((file, i) => (
            <figure key={i} className="uploaded-images__figure">
              <img
                src={file.preview}
                className="contain center"
                data-testid="garmentFormMediaPhotoPreview"
                alt={file.name}
              />
              <a
                className="uploaded-images__remove"
                data-testid={`garmentFormMediaRemovePhoto${i}`}
                onClick={(event) => {
                  event.stopPropagation()
                  field.input.onChange(removePhoto(file))
                }}
              >
                <Icon name="cross-rounded-filled" />
              </a>
            </figure>
          ))}
        </div>
      )}
      <Dropzone
        data-testid={`garmentFormMediaColorBox${chosenColor}`}
        name={field.name}
        onDrop={(filesToUpload) => {
          field.input.onChange(addPhoto(filesToUpload))
        }}
        maxSize={MAX_PHOTO_SIZE}
        onDropRejected={() => showPhotoSizeTooLargeNotification()}
        accept="image/png, image/jpeg"
        className="form-control-file__label form-control-file__label--lg"
      >
        Drag images to upload
      </Dropzone>
    </div>
  )
}


interface IStateProps {
  video: string,
  chosenColor?: string,
  unitInformation: IGarmentUnitInformation[],
  removedPhotos: IRemovedPhotos,
}

interface IDispatchProps {
  chooseColor: (color: string) => void,
  removeVideo: (color?: string) => void,
  showPhotoSizeTooLargeNotification: () => void,
}

const Media: React.FunctionComponent<IStateProps & IDispatchProps> = (props) => {

  const { video, unitInformation, removedPhotos, removeVideo, showPhotoSizeTooLargeNotification } = props
  let { chosenColor } = props

  const colors: {[colors: string]: string} = {}
  if (unitInformation) {
    unitInformation.forEach((unit) => {
      if (unit.color) {
        colors[unit.color] = unit.color
      }
    })
  }

  if (! chosenColor && Object.keys(colors).length > 0) {
    chosenColor = Object.keys(colors)[0]
  }
  return (
    <React.Fragment>
      {Object.keys(colors).length > 0 && (
        <React.Fragment>
          <p className="upcase">Media</p>
          <div className="hidden--sm-up">
            <select
              className="js-tab-selector form-control form-control--pill"
              onChange={(event) => props.chooseColor(event.target.value)}
            >
              {Object.keys(colors).map((color, i) => (
                <option value={color} id={color} key={i}>
                  {color}
                </option>
              ))}
            </select>
          </div>
        </React.Fragment>
      )}
      <ul className="tabs nav mb--none hidden--sm-down" role="tablist">
        {Object.keys(colors).map((color) => (
          <li key={color} style={{marginLeft: "5px"}}>
            <a
              className={`tab ${chosenColor === color && `active`}`}
              data-testid={`garmentFormMediaColor${color}`}
              onClick={() => props.chooseColor(color)}
            >
              {color}
            </a>
          </li>
        ))}
      </ul>

      {chosenColor && (
        <div className="tab-content mt--base">
          <fieldset className="box box--light box--shadowed box--grid mb--md">
            <FormSection name={chosenColor} key={chosenColor}>
              <div>
                <h3 className="title">Images</h3>
                <div className="form-group">
                  <Field
                    name={"photos"}
                    component={renderDropzoneInput as any}
                    className="form-control-file"
                    removedPhotos={removedPhotos}
                    chosenColor={chosenColor}
                    showPhotoSizeTooLargeNotification={showPhotoSizeTooLargeNotification}
                  />
                </div>
                <h3 className="title">Videos</h3>
                <div className="form-group">
                  <Field
                    component={"input"}
                    className="form-control"
                    data-testid="garmentFormMediaVideo"
                    type="text"
                    name={"video"}
                    id="video"
                    data-empty={! props.video}
                  />
                  <label className="form-control-label" htmlFor="video">
                    Paste Youtube video link here
                  </label>
                </div>
              </div>
              {video && (
                <React.Fragment>
                  <div className="row">
                    <div className="col-sm-12 mb--base mb-sm--none">
                      <iframe
                        width="100%"
                        height="230px"
                        data-testid="garmentFormVideoPreview"
                        src={`https://www.youtube.com/embed/${youtubeVideoUrlParser(video)}`}
                        frameBorder="0"
                        allow="autoplay encrypted-media"
                        allowFullScreen
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <a
                        className="link--danger upcase"
                        data-testid="garmentFormVideoRemove"
                        style={{ cursor: "pointer" }}
                        onClick={() => removeVideo(chosenColor)}
                      >
                        <div>Remove video</div>
                      </a>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </FormSection>
          </fieldset>
        </div>
      )}
    </React.Fragment>
  )
}

const selector = formValueSelector("Garment")

const mapStateToProps = (state: RootState): IStateProps => ({
  video: selector(state, `media.${state.garments.color}.video`),
  removedPhotos: selector(state, "removedPhotos"),
  unitInformation: selector(state, "unitInformation"),
  chosenColor: state.garments.color,
})

const mapDispatchToProps =
  (dispatch: Dispatch<Action>): IDispatchProps => (
    {
      chooseColor: (color: string) => dispatch(chooseColor({color})),
      removeVideo: (color) => dispatch(change("Garment", `media.${color}.video`, "")),
      showPhotoSizeTooLargeNotification:
        () => dispatch(notify("warning", `The maximum photo size is ${MAX_PHOTO_SIZE_STRING}.`, true)),
    }
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Media)
