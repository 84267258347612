import React from "react"
import { Link } from "react-router-dom"

import Icon from "./Icon"
import useHandleOutsideEventHook from "../utils/useHandleOutsideEventHook"

interface IOwnProps {
  onShowDropdown: () => void,
  onHideDropdown: () => void,
  showCondition: boolean,
  dropdownItems: object[]
}

const Dropdown: React.FunctionComponent<IOwnProps> = (props) => {

  const { onShowDropdown, onHideDropdown, showCondition, dropdownItems } = props
  const ref = useHandleOutsideEventHook(onHideDropdown, showCondition).ref

  return (
    <aside className="list-item__more" ref={ref}>
      <div className={`dropdown txtr ${showCondition && `show`}`} data-testid="dropdownContainer">
        <Link
          className="dropdown__toggle text--quiet"
          to="#"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={showCondition ? "true" : "false"}
          onClick={onShowDropdown}
        >
          <Icon name="dots-ver" />
        </Link>
        <ul className={`dropdown-menu dropdown-menu--right ${showCondition && `show`}`}>
          {dropdownItems.map((item: any) => {
            return (
              <li key={item.name} data-testid={item.name}>
                <Link className="dropdown-menu__item" to="#" onMouseDown={item.action}>
                  {item.name}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </aside>
  )
}

export default Dropdown
