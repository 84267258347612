import request, { generatePostOptionsForRequest } from "../utils/request"
import { call, put, takeLatest } from "redux-saga/effects"
import { SagaIterator } from "redux-saga"

import { getRecycleInbox, markGarmentAsReceived, markGarmentAsNotReceived } from "../reducers/RecycleActions"

import { notify } from "../reducers/GeneralActions"

/* ************************************************************* */
function* getRecycleInboxSaga() {

  try {
    const result = yield call(request, "/brands/recycleInbox")
    yield put(getRecycleInbox.done({ result }))
  } catch (err) {
    console.log(err)
    const { message } = err
    yield put(getRecycleInbox.failed({ error: { errorMessage: message } }))
    yield put(notify("error", "Unable to get garments."))
  }
}

export function* watchGetRecycleInbox(): SagaIterator {
  yield takeLatest(getRecycleInbox.started, getRecycleInboxSaga)
}
/* ************************************************************* */

/* ************************************************************* */
function* markGarmentAsReceivedSaga(action: any) {

  const { garmentCode } = action.payload
  try {
    const postOptions = generatePostOptionsForRequest({})
    const result = yield call(request, `/brands/garmentReceived/${garmentCode}`, postOptions)

    const { message } = result
    if (message === "Garment not found") {
      yield put(notify("error", message))
    } else {
      yield put(markGarmentAsReceived.done({ params: { garmentCode }, result }))
      yield put(getRecycleInbox.started())
    }
  } catch (err) {
    console.log(err)
    const { message } = err
    yield put(markGarmentAsReceived.failed({ params: { garmentCode }, error: { errorMessage: message } }))
    yield put(notify("error", message))
  }
}

export function* watchMarkGarmentAsReceivedSaga(): SagaIterator {
  yield takeLatest(markGarmentAsReceived.started, markGarmentAsReceivedSaga)
}
/* ************************************************************* */

/* ************************************************************* */
function* markGarmentAsNotReceivedSaga(action: any) {
  const { garmentCode } = action.payload
  try {
    const postOptions = generatePostOptionsForRequest({})
    const result = yield call(request, `/brands/garmentNotReceived/${garmentCode}`, postOptions)

    const { message } = result
    if (message === "Garment not found") {
      yield put(notify("error", message))
    } else {
      yield put(markGarmentAsNotReceived.done({ params: { garmentCode }, result }))
      yield put(getRecycleInbox.started())
    }
  } catch (err) {
    console.log(err)
    const { message } = err
    yield put(markGarmentAsNotReceived.failed({ params: { garmentCode }, error: { errorMessage: message } }))
    yield put(notify("error", message))
  }
}

export function* watchMarkGarmentAsNotReceivedSaga(): SagaIterator {
  yield takeLatest(markGarmentAsNotReceived.started, markGarmentAsNotReceivedSaga)
}
/* ************************************************************* */

