import React from "react"
import { Switch, Route, Redirect } from "react-router"
import { withRouter } from "react-router-dom"

import Login from "../components/Login"
import Logo from "../images/logo.svg"

// import ResetPassword from "../components/ResetPassword"


interface IProps {
  currentPath: string
}

const Auth: React.FunctionComponent<IProps> = (props: IProps) => {

  const { currentPath } = props

  let title = "Login"

  if (currentPath === "reset-password") {
    title = "Reset password"
  }

  document.title = title

  return (
    <div className="auth-canvas">
      <main className="auth-content">
{/* 
        <figure className="auth-brand-logo">
          <img className="contain center" src="../../images/placeholders/brand-logo.png" alt="" />
        </figure> */}

        <Switch>
          <Route exact path="/login" render={ () => <Login /> } />
          {/* <Route exact path="/reset-password" render={() => <ResetPassword />} /> */}
          <Redirect to="/login" />
        </Switch>

        <br />
        <br />
        <br />

        <figure className="txtc mt-md">
          <img className="semi-transparent" src={Logo} alt="" width="100" height="" />
          </figure>

      </main>
    </div>
  )
}


export default withRouter(Auth as any)

