import React from "react"

const NotFound = () => {

  return (
    <div className="main__content">
      <div className="container">
        <header>
          <h1>Page not found</h1>
        </header>
      </div>
    </div>
  )
}

export default NotFound
