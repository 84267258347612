import React from "react"
import Icon from "../components/Icon"

import { IFilters } from "../reducers/GarmentModels"


interface IOwnProps {
  onChangeFilters: (filters: IFilters) => void
}

const CatalogueSearch: React.FunctionComponent<IOwnProps> = (props) => {

  const { onChangeFilters } = props

  return (
    <div className="listing-filters__search">
      <div className="form-group-search">
        <Icon name="loupe" />
        <input
          className="form-control"
          data-testid="catalogueSearchFilter"
          type="search"
          name="search"
          placeholder="Type to search"
          onChange={(event) => onChangeFilters({ searchText: event.target.value } as IFilters)}
        />
      </div>
    </div>
  )
}

export default CatalogueSearch
