import React, { createRef, useState, useEffect, useRef } from "react"
import { withRouter, Link } from "react-router-dom"
import { Field, reduxForm, InjectedFormProps, change } from "redux-form"
import { getFormValues } from "redux-form/immutable"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"
import { findDOMNode } from "react-dom"

import Icon from "./Icon"
import landscape from "../images/image-landscape.png"
import SupplyChainEntryConnectGarments from "./SupplyChainEntryConnectGarments"
import SupplyChainEntryMedia from "./SupplyChainEntryMedia"
import Loading from "../components/Loading"
import SUPPLY_CHAIN from "../utils/supplyChainObject"

import {
  shouldShowEntryForm,
  addSupplyChainEntry,
  deleteSupplyChainEntry,
} from "../reducers/SupplyChainActions"

import { RootState } from "../reducers"
import { ISupplyChainEntry } from "../reducers/SupplyChainModels"

import useHandleOutsideEventHook from "../utils/useHandleOutsideEventHook"

interface IStateProps {
  entry: ISupplyChainEntry,

  showEntryFormFlag: boolean,
  isLoadingAddSupplyChainEntry: boolean,

  isLoadingDeleteSupplyChainEntry: boolean,

  isLoadingGetSupplyChain: boolean,
}

interface IDispatchProps {
  onShouldShowEntryForm: (showEntryFormFlag: boolean) => void,
  onAddSupplyChainEntry: (payload: FormData) => void,
  onDeleteSupplyChainEntry: (id: number) => void,
  changeLat: (lat: number) => void,
  changeLng: (lng: number) => void,
  changeAddress: (address: string) => void
}

interface IAddress {
  street: string,
  city: string,
  country: string,
  mapPhoto: string
}

interface IState {
  lat: number | null,
  lng: number | null,
  autocomplete: google.maps.places.Autocomplete | null,
  addressObject: IAddress
}

const SupplyChainEntryForm:
  React.FunctionComponent<IStateProps & IDispatchProps & InjectedFormProps<IStateProps> & IState> = (props) => {

  const [autocomplete, setAutocomplete] = useState(null)
  const [addressObject, setAddressObject] = useState({ street: "", city: "", country: "", mapPhoto: landscape })

  const addressRef = createRef<Field>()

  const {
    showEntryFormFlag, onShouldShowEntryForm, onDeleteSupplyChainEntry,
    isLoadingAddSupplyChainEntry, isLoadingDeleteSupplyChainEntry,
    handleSubmit,
  } = props

  const ref = useHandleOutsideEventHook(() => onShouldShowEntryForm(false), showEntryFormFlag).ref

  let { entry } = props

  useEffect(() => {
    if (props.isLoadingGetSupplyChain) {
      initAutocomplete()
    } else if (autocomplete) {
      (autocomplete as any).addListener("place_changed", fillInAddress)
    }

  }, [props.isLoadingGetSupplyChain])

  useEffect(() => {
    if (entry) {
      if (entry.address) {
        setAddressObject(destructureAddress(entry.address))
      } else {
        setAddressObject({street: "", city: "", country: "", mapPhoto: landscape})
      }
    }
  }, [props.entry])

  if (! entry) { entry = {} as ISupplyChainEntry }

  const { type_id, id, connectedGarments, name, address, readMoreUrl, description } = entry

  let typeName = null
  if (type_id) { typeName = SUPPLY_CHAIN[type_id].name }

  let mapPhoto = landscape

  let { street, city, country } = addressObject

  if (addressObject) {
    mapPhoto = addressObject.mapPhoto
  }

  const initAutocomplete = () => {
    if (addressRef.current) {
      setAutocomplete(new google.maps.places.Autocomplete(
        findDOMNode(addressRef.current) as any, {fields: ["formatted_address", "geometry.location"]}) as any,
      )
    }
    return null
  }

  const destructureAddress = (addressString: string): IAddress => {

    const addressArray = addressString.split(",")

    if (addressArray.length === 1) {
      country = addressArray[0]
    } else if (addressArray.length === 2) {
      city = addressArray[0]
      country = addressArray[1]
    } else if (addressArray.length === 3) {
      street = addressArray[0]
      city = addressArray[1]
      country = addressArray[2]
    } else if (addressArray.length > 3) {
      street = addressArray[0]
      city = addressArray[addressArray.length - 3]
      country = addressArray[addressArray.length - 1]
    }

    if (props.entry.lat && props.entry.lng ) {
      mapPhoto =  `https://maps.googleapis.com/maps/api/staticmap?center=${props.entry.lat},${props.entry.lng}
                      &zoom=15&size=250x125&markers=color:red%7Clabel:%7C${props.entry.lat},${props.entry.lng}
                      &key=AIzaSyB7R6Hsudf_QG-X5pUFR-bQx6nfmaSTXMY
                      &language=en`
    }

    return {street, city, country, mapPhoto}
  }

  const fillInAddress = () => {
    // Get the place details from the autocomplete object.
    let place = null
    if (autocomplete)  {
      place = (autocomplete as any).getPlace()
    }
    if (place && place.geometry) {
      props.changeLat(place.geometry.location.lat())
      props.changeLng(place.geometry.location.lng())
      props.changeAddress(place.formatted_address)
    }
  }

  const addEntry = () => {
    const formData = new FormData()
    const { photo } = props.entry

    formData.append("type_id", type_id.toString())

    if (name) { formData.append("name", name) }
    if (address) { formData.append("address", address) }
    if (props.entry.lat) { formData.append("lat", (props.entry.lat as any).toString()) }
    if (props.entry.lng) { formData.append("lng", (props.entry.lng as any).toString()) }
    if (readMoreUrl) { formData.append("readMoreUrl", readMoreUrl) }
    if (description) { formData.append("description", description) }
    if (photo && Object.keys(photo).length > 0) {
      if (typeof(photo) === "string") {
        formData.append("photo", photo)
      } else if (! photo.size) {
        formData.append("photo", photo.name)
      } else {
        formData.append("photo", photo, photo.name)
      }
    }
    if (connectedGarments) {
      formData.append("connectedGarments", JSON.stringify(connectedGarments.map((garment) => garment.id)))
    }
    if (id) { formData.append("entryId", id.toString()) }

    props.onAddSupplyChainEntry(formData)
  }

  return (
    <React.Fragment>

      <aside
        className={`drawer drawer--extended ${showEntryFormFlag && `show`}`}
        data-testid="supplyChainSupplierForm"
        id="add-to-supply-chain"
        role="menu"
        aria-label="Add to supply chain drawer"
        tabIndex={-1}
        ref={ref}
      >

        <Loading
          show={isLoadingAddSupplyChainEntry || isLoadingDeleteSupplyChainEntry}
          text={`Loading...`}
          imgClass="block-center"
          divClass="main__content"
        />

        {! isLoadingAddSupplyChainEntry && ! isLoadingDeleteSupplyChainEntry && (
          <form className="drawer__content" onSubmit={handleSubmit(addEntry)}>
            <header className="drawer__header">
              <h3 className="drawer__title" data-testid="supplyChainSupplierFormTitle">{typeName} Details</h3>
              <Link
                to="#"
                className="drawer__close"
                data-testid="supplyChainCloseSupplierForm"
                data-dismiss="drawer"
                aria-label="Close"
                onClick={() => {
                  onShouldShowEntryForm(false)
                }}
              >
                <Icon name="cross-rounded" />
              </Link>
            </header>
            <section className="drawer__main">
              <div className="form-group">
                <Field
                  component="input"
                  data-testid="supplyChainSupplierNameInput"
                  className="form-control"
                  type="text"
                  name="name"
                  id="supplier-name"
                  data-empty={! name}
                />
                <label className="form-control-label" htmlFor="name">
                  Supplier name
                </label>
              </div>
              <div className="form-group">
                <Field
                  component="input"
                  data-testid="supplyChainSupplierAddressInput"
                  className="form-control"
                  type="text"
                  name="address"
                  id="supplier-address"
                  data-empty={! address}
                  placeholder=""
                  ref={addressRef}
                />
                <label className="form-control-label" htmlFor="address">
                  Supplier address
                </label>
              </div>
              <div className="form-group">
                <div className="row mh--none">
                  <div className="col-sm ph--none">
                    <p className="text--sm text--quiet" data-testid="supplyChainSupplierAddressShow">
                      {street}
                      <br />
                      {city}
                      <br />
                      {country}
                    </p>
                  </div>
                  <div className="col-sm ph--none txtr--sm">
                    <img className="contain" src={mapPhoto} alt="" />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-control-label is-static position-relative mb--xs" htmlFor="upload">
                  Supplier image
                </label>
                <Field name="photo" component={SupplyChainEntryMedia as any} className="form-control-file" />
              </div>
              <div className="form-group">
                <Field
                  component="input"
                  className="form-control"
                  data-testid="supplyChainSupplierWebAddressInput"
                  type="text"
                  name="readMoreUrl"
                  id="supplier-web-address"
                  data-empty={! readMoreUrl}
                />
                <label className="form-control-label" htmlFor="readMoreUrl">
                  Web address
                </label>
              </div>
              <div className="form-group">
                <Field
                  component="input"
                  className="form-control"
                  data-testid="supplyChainSupplierDescriptionInput"
                  type="text"
                  name="description"
                  id="supplier-description"
                  value=""
                  data-empty={! description}
                />
                <label className="form-control-label" htmlFor="description">
                  Supplier description
                </label>
              </div>

              <SupplyChainEntryConnectGarments connectedGarments={connectedGarments} />
            </section>
            <footer className="drawer__footer">
              <div className="row reverse middle-sm">
                <div className="col-sm txtc txtr--sm mb--base mb-sm--none">
                  <button className="button button--primary" type="submit" data-testid="supplyChainSaveSupplier">
                    Save changes
                  </button>
                </div>
                {id && (
                  <div className="col-sm txtc txtl--sm">
                    <Link
                      className="link--danger upcase"
                      data-testid="supplyChainDeleteSupplier"
                      to="#"
                      onClick={() => onDeleteSupplyChainEntry(id)}
                    >
                      Delete supplier
                    </Link>
                  </div>
                )}
              </div>
            </footer>
          </form>
        )}
      </aside>
    </React.Fragment>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  showEntryFormFlag: state.supplyChain.showEntryFormFlag,
  entry: getFormValues("SupplyChainEntryForm")(state) as ISupplyChainEntry,

  isLoadingAddSupplyChainEntry: state.supplyChain.isLoadingAddSupplyChainEntry,

  isLoadingDeleteSupplyChainEntry: state.supplyChain.isLoadingDeleteSupplyChainEntry,

  isLoadingGetSupplyChain: state.supplyChain.isLoadingGetSupplyChain,
})

const mapDispatchToProps =
  (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    onShouldShowEntryForm: (showEntryFormFlag: boolean) => dispatch(shouldShowEntryForm({showEntryFormFlag})),
    onAddSupplyChainEntry: (payload: FormData) => dispatch(addSupplyChainEntry.started({payload})),
    onDeleteSupplyChainEntry: (id: number) => dispatch(deleteSupplyChainEntry.started({id})),
    changeLat: (lat: number) => dispatch(change("SupplyChainEntryForm", "lat",  lat)),
    changeLng: (lng: number) => dispatch(change("SupplyChainEntryForm", "lng",  lng)),
    changeAddress: (address: string) => dispatch(change("SupplyChainEntryForm", "address",  address)),
  }
)

const SupplyChainEntryFormReduxFormed = reduxForm({
  form: "SupplyChainEntryForm",
})(SupplyChainEntryForm as any)

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SupplyChainEntryFormReduxFormed) as any,
)
