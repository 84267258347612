import React from "react"

import { IFilters } from "../reducers/FeedbackModels"

interface IOwnProps {
  order: string,
  setOrder: (order: string) => void,
  allFilters: { garment_type: string, garment_line: string, garment_name: string },
  setAllFilters: (allFilters: any) => void,
  filters: IFilters,
}

const FeedbackFilters: React.SFC<IOwnProps> = (props) => {

  const { allFilters, setAllFilters, filters, order, setOrder } = props

  const { garment_type, garment_line, garment_name } = allFilters

  const { types, lines, garments } = filters

  return (
    <div className="listing-filters__options">
      <div className="form-group">
        <label className="form-control-label is-static" htmlFor="order">Sort</label>
        <select
          className="form-control"
          id="order"
          value={order}
          onChange={(event) => setOrder(event.target.value)}
        >
          <option value="name asc">Name ascending</option>
          <option value="name desc">Name descending</option>
          {/* <option>Date ascending</option>
          <option>Date descending</option> */}
        </select>
      </div>

      <div className="form-group">
        <label className="form-control-label is-static" htmlFor="type">Type</label>
        <select
          className="form-control"
          id="type"
          value={garment_type}
          onChange={(event) => setAllFilters({ ...allFilters, garment_type: event.target.value })}
        >
          <option value="all">All</option>

          {types.map((oneType: string) => {
            return (
              <option value={oneType} key={oneType}>{oneType}</option>
            )
          })}

        </select>
      </div>

      <div className="form-group">
        <label className="form-control-label is-static" htmlFor="line">Line</label>
        <select
          className="form-control"
          id="line"
          value={garment_line}
          onChange={(event) => setAllFilters({ ...allFilters, garment_line: event.target.value })}
        >
          <option value="all">All</option>

          {lines.map((oneLine: string) => {
            return (
              <option value={oneLine} key={oneLine}>{oneLine}</option>
            )
          })}

        </select>
      </div>

      <div className="form-group">
        <label className="form-control-label is-static" htmlFor="garment">Garment</label>
        <select
          className="form-control" id="garment"
          value={garment_name}
          onChange={(event) => setAllFilters({ ...allFilters, garment_name: event.target.value })}
        >
          <option value="all">All</option>

          {garments.map((oneGarment: string) => {
            return (
              <option value={oneGarment} key={oneGarment}>{oneGarment}</option>
            )
          })}

        </select>
      </div>

      <div className="form-group">
        {/* <Link className="upcase" to="#">Download CSV</Link> */}
      </div>
    </div>
  )
}

export default FeedbackFilters
