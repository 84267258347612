import React from "react"
import { Link } from "react-router-dom"

import { IFeedback } from "../reducers/FeedbackModels"

import Icon from "./Icon"
import { dateFormat, timeFormat, copyToClipboard } from "../utils/utils"
import Dropdown from "./Dropdown"

interface IOwnProps {
  item?: IFeedback,
  index: number,
  notifyStyleNumberCopied: (message: string) => void,
  onShouldShowFeedback: (showFeedback: boolean, activeFeedback: IFeedback) => void,
}

const FeedbackItem: React.SFC<IOwnProps> = (props) => {

  const { item, index, notifyStyleNumberCopied, onShouldShowFeedback,
  } = props

  if (! item) {
    return null
  }

  const {
    base_photo, garment_name, style_number, username, user_email, review_name, date_sent,
  } = item

  return (
    <article className="list-item list-item--feedback">
      <figure>
        <img className="contain center" src={base_photo} alt="" width="50" />
      </figure>
      <section className="list-item__details">
        <div>
          <a className="link--ui" href={`mailto:${user_email}`}>
            <h3 className="mb--none">{username}</h3>
          </a>
          <small className="block mt--xs">
            <a className="link--ui" href={`mailto:${user_email}`}>
              {user_email}
            </a>
          </small>
        </div>
        <time dateTime={dateFormat(new Date(date_sent))}>
          <span className="block">{dateFormat(new Date(date_sent))}</span>
          <small className="block mt--xs">{timeFormat(new Date(date_sent))}</small>
        </time>
        <span>
          <Link className="link--ui" to="#">{garment_name}</Link>

          <small className="block mt--xs" id={`styleNumber${index}`}>
            {style_number}
            <Link
              className="inline-block ml--xs valign-middle"
              onClick={() => copyToClipboard(index, (message) => notifyStyleNumberCopied(message))}
              to="#"
            >
              <Icon name="copy" />
            </Link>
          </small>

        </span><span>{review_name}</span>
        <div>
          <Link
            className="button button--primary button--sm mr-sm"
            to="#"
            data-toggle="drawer"
            onClick={() => onShouldShowFeedback(true, item)}
          >
            View
            </Link>
        </div>
      </section>

      {/* <Dropdown
        onShowDropdown={() => { }}
        onHideDropdown={() => { }}
        showCondition={false}
        dropdownItems={[
          {
            name: "Action",
            action: () => [],
          },
        ]}
      /> */}

    </article>
  )
}

export default FeedbackItem
