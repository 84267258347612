import request, { generateDeleteOptionsForRequest } from "../utils/request"
import { call, put, takeLatest } from "redux-saga/effects"
import { SagaIterator } from "redux-saga"

import { getReviews, deleteReview, shoudShowFeedback, removeReview } from "../reducers/FeedbackActions"

import { notify } from "../reducers/GeneralActions"


/* ************************************************************* */
function * getReviewsSaga() {

  try {
    const result = yield call(request, "/reviews/reviews")
    yield put(getReviews.done({ result }))
  } catch (err) {
    console.log(err)
    const { message } = err
    yield put(getReviews.failed({ error: { errorMessage: message }}))
    yield put(notify("error", "Unable to get reviews."))

  }
}

export function * watchGetReviews(): SagaIterator {
  yield takeLatest(getReviews.started, getReviewsSaga)
}
/* ************************************************************* */


/* ************************************************************* */
function * deleteReviewSaga(action: any) {

  const { reviewId } = action.payload

  try {
    const result = yield call(request, `/reviews/reviews/${reviewId}`, generateDeleteOptionsForRequest({}))
    yield put(deleteReview.done({ params: { reviewId }, result }))
    yield put(notify("success", "The review was deleted successfully."))
    yield put(shoudShowFeedback({ showFeedback: false }))
    yield put(removeReview({ reviewId }))
  } catch (err) {
    console.log(err)
    const { message } = err
    yield put(deleteReview.failed({ params: { reviewId }, error: { errorMessage: message }}))
    yield put(notify("error", "Error while deleting a review."))
  }
}

export function * watchDeleteReview(): SagaIterator {
  yield takeLatest(deleteReview.started, deleteReviewSaga)
}
/* ************************************************************* */
