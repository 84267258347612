import React, { useState } from "react"

import SettingsGeneral from "../components/SettingsGeneral"
import SettingsRecycling from "../components/SettingsRecycling"

const Settings: React.FC = () => {

  const [tab, setTab] = useState("General")

  return (
    <React.Fragment>
      <div className="main__content">
        <div className="container" data-testid="team">
          <header>
            <h1 className="section__title">Settings</h1>
          </header>

          <div className="mb--base hidden--sm-up">
            <select
              className="js-tab-selector form-control"
              onChange={(event) => setTab(event.target.value)}
            >
              <option value="General">General</option>
              <option value="Recycling">Recycling</option>
            </select>
          </div>

          <ul className="tabs nav" role="tablist">
            <li>
              <a
                onClick={() => setTab("General")}
                className={`tab ${tab === "General" && "active show"}`}
                href="#tab-general"
                data-toggle="tab"
                role="tab"
                aria-controls="tab-toggle-general"
                aria-selected="true"
                id="tab-toggle-general"
              >
                General
              </a>
            </li>
            <li>
              <a
                onClick={() => setTab("Recycling")}
                className={`tab ${tab === "Recycling" && "active show"}`}
                href="#tab-recycling"
                data-toggle="tab"
                role="tab"
                aria-controls="tab-toggle-recycling"
                aria-selected="true"
                id="tab-toggle-recycling"
              >
                Recycling
              </a>
            </li>
          </ul>

          <div className="tab-content mt--base">

            {tab === "General" && <SettingsGeneral tab={tab} />}

            {tab === "Recycling" && <SettingsRecycling tab={tab} />}

          </div>
        </div>
      </div>
    </React.Fragment>
  )
}


export default Settings
