import actionCreatorFactory from "typescript-fsa"


const actionCreator = actionCreatorFactory("General")


export const showNotification = actionCreator<
  { notificationType: string, notificationMessage: string, autoRemove: boolean }
>("SHOW_NOTIFICATION")

// for convenience
export function notify(notificationType: string, notificationMessage: string, autoRemove: boolean = false) {
  return showNotification({notificationType, notificationMessage, autoRemove})
}

export const clearNotification = actionCreator<void>("CLEAR_NOTIFICATION")

