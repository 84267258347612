import actionCreatorFactory from "typescript-fsa"

import { IGarment, IShortGarment, IFilters, IGarmentDropdownMenuControls } from "./GarmentModels"

const actionCreator = actionCreatorFactory("Garment")


export const getGarments =
  actionCreator.async<  void,
                      { garments: IShortGarment[] },
                      { errorMessage: string }
                     >("GET_GARMENTS")


export const getGarment =
  actionCreator.async<{ styleNumber: string },
                      { garment: IGarment },
                      { errorMessage: string }
                    >("GET_GARMENT")

export const addGarment =
  actionCreator.async<{ payload: { formData: FormData, data: IGarment} },
                      { addGarmentResult: boolean },
                      { errorMessage: string }
                    >("ADD_GARMENT")


export const showListView = actionCreator<{ showListViewFlag: boolean }>("SHOW_LIST_VIEW")

export const chooseColor = actionCreator<{ color: string }>("CHOOSE_COLOR")

export const chooseSupplyChainTypeId = actionCreator<{ supplyChainTypeId: number }>("CHOOSE_SUPPLY_CHAIN_TYPE_ID")

export const showDropdown = actionCreator<{ payload: IGarmentDropdownMenuControls }>("SHOW_DROPDOWN")

export const changeFilters = actionCreator<{ filters: IFilters }>("CHANGE_FILTERS")

