import { Action } from "redux"
import { isType } from "typescript-fsa"

import {
  getBrandSettings, saveBrandSettings, getRecycleOptions, saveRecycleOptions,
} from "./SettingsActions"
import { IRecycleOptions, IBrandSettings } from "./SettingsModels"


export interface ISettingsState {
  isLoadingGetBrandSettings: boolean,
  errorGetBrandSettings?: string,
  brandSettings?: IBrandSettings,

  isLoadingSaveBrandSettings: boolean,
  errorSaveBrandSettings?: string,
  saveBrandSettings: boolean,

  isLoadingGetRecycleOptions: boolean,
  errorGetRecycleOptions?: string,
  recycleOptions?: IRecycleOptions,

  isLoadingSaveRecycleOptions: boolean,
  errorSaveRecycleOptions?: string,
  saveRecycleOptions: boolean,
}

export const initialState: ISettingsState = {
  isLoadingGetBrandSettings: false,
  errorGetBrandSettings: undefined,
  brandSettings: undefined,


  isLoadingSaveBrandSettings: false,
  errorSaveBrandSettings: undefined,
  saveBrandSettings: false,

  isLoadingGetRecycleOptions: false,
  errorGetRecycleOptions: undefined,
  recycleOptions: undefined,

  isLoadingSaveRecycleOptions: false,
  errorSaveRecycleOptions: undefined,
  saveRecycleOptions: false,
}

export default (state: ISettingsState = initialState, action: Action): ISettingsState => {

  if (isType(action, getBrandSettings.started)) {
    return {
      ...state,
      isLoadingGetBrandSettings: true,
      errorGetBrandSettings: undefined,
      brandSettings: undefined,
    }
  }

  if (isType(action, getBrandSettings.done)) {
    const { brandSettings } = action.payload.result
    return {
      ...state,
      isLoadingGetBrandSettings: false,
      errorGetBrandSettings: undefined,
      brandSettings,
    }
  }

  if (isType(action, getBrandSettings.failed)) {
    const { errorMessage } = action.payload.error
    return {
      ...state,
      isLoadingGetBrandSettings: false,
      errorGetBrandSettings: errorMessage,
      brandSettings: undefined,
    }
  }

  if (isType(action, saveBrandSettings.started)) {
    return {
      ...state,
      isLoadingSaveBrandSettings: true,
      errorSaveBrandSettings: undefined,
      saveBrandSettings: false,
    }
  }

  if (isType(action, saveBrandSettings.done)) {
    return {
      ...state,
      isLoadingSaveBrandSettings: false,
      errorSaveBrandSettings: undefined,
      saveBrandSettings: true,
    }
  }

  if (isType(action, saveBrandSettings.failed)) {
    const { errorMessage } = action.payload.error
    return {
      ...state,
      isLoadingSaveBrandSettings: false,
      errorSaveBrandSettings: errorMessage,
      saveBrandSettings: false,
    }
  }


  if (isType(action, getRecycleOptions.started)) {
    return {
      ...state,
      isLoadingGetRecycleOptions: true,
      errorGetRecycleOptions: undefined,
      recycleOptions: undefined,
    }
  }

  if (isType(action, getRecycleOptions.done)) {
    const { recycleOptions } = action.payload.result
    return {
      ...state,
      isLoadingGetRecycleOptions: false,
      errorGetRecycleOptions: undefined,
      recycleOptions,
    }
  }

  if (isType(action, getRecycleOptions.failed)) {
    const { errorMessage } = action.payload.error
    return {
      ...state,
      isLoadingGetRecycleOptions: false,
      errorGetRecycleOptions: errorMessage,
      recycleOptions: undefined,
    }
  }

  if (isType(action, saveRecycleOptions.started)) {
    return {
      ...state,
      isLoadingSaveRecycleOptions: true,
      errorSaveRecycleOptions: undefined,
      saveRecycleOptions: false,
    }
  }

  if (isType(action, saveRecycleOptions.done)) {
    return {
      ...state,
      isLoadingSaveRecycleOptions: false,
      errorSaveRecycleOptions: undefined,
      saveRecycleOptions: true,
    }
  }

  if (isType(action, saveRecycleOptions.failed)) {
    const { errorMessage } = action.payload.error
    return {
      ...state,
      isLoadingSaveRecycleOptions: false,
      errorSaveRecycleOptions: errorMessage,
      saveRecycleOptions: false,
    }
  }


  return state
}
