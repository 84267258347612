import "whatwg-fetch"

import store from "../store"
import { push } from "connected-react-router"

import { logout } from "../reducers/UsersActions"


const baseUrl = process.env.NODE_ENV === "production" ? "https://backend.cacotec.com/w" : "https://backend.cacotec.com/w"


interface IOurResponse {
  status: string,
  statusMessage: string,
  [propName: string]: any
}

interface IFetchResponse {
  status: number,
  statusText: string,
  json(): Promise<any>
}

export function generatePostOptionsForRequest(data: object): RequestInit {
  return generateOptions(data, "POST")
}

export function generatePutOptionsForRequest(data: object): RequestInit {
  return generateOptions(data, "PUT")
}

export function generatePutOptionsForFileUpload(data: object): RequestInit {
  return {
    method: "PUT",
    body: data,
  } as RequestInit
}

export function generatePostOptionsForFileUpload(data: object): RequestInit {
  return {
    method: "POST",
    body: data,
  } as RequestInit
}

export function generateDeleteOptionsForRequest(data: object): RequestInit {
  return generateOptions(data, "DELETE")
}

function generateOptions(data: object, method: string): RequestInit {
  return {
    method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  } as RequestInit
}

function parseResponse(response: IOurResponse): IOurResponse {
  if (response.status !== "ok") {
    const error = new Error(response.statusMessage)
    // error.response = response;
    throw error
  }
  return response
}

function parseJSON(response: IFetchResponse): Promise<any> | null {
  if (response.status === 204 || response.status === 205) {
    return null
  }
  return response.json()
}

function checkStatus(response: IFetchResponse): IFetchResponse {
  if (response.status >= 200 && response.status < 300) {
    return response
  }

  if (response.status === 401) {
    store.dispatch(logout.started())
  }

  const error = new Error(response.statusText)
  // error.response = response;
  throw error
}

export default function request(url: string, options?: RequestInit): Promise<IOurResponse> {

  let optionsModified: RequestInit

  if (! options) {
    optionsModified = {} as RequestInit
  } else {
    optionsModified = options
  }
  optionsModified.credentials = "include"
  optionsModified.mode = "cors"

  return fetch(`${baseUrl}${url}`, optionsModified)
    .then(checkStatus)
    .then(parseJSON)
    .then(parseResponse)
}


