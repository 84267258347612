import React from "react"

interface IOwnProps {
  order: string,
  setOrder: (order: string) => void,
  allFilters: { type: string, status: string },
  setAllFilters: (allFilters: any) => void,
  types: string[],
}

const RecyclingInboxFilters: React.SFC<IOwnProps> = (props) => {

  const { allFilters, setAllFilters, types, order, setOrder } = props

  const { type, status } = allFilters

  return (
    <form className="listing-filters__options">

      <div className="form-group">
        <label className="form-control-label is-static" htmlFor="order">Sort</label>
        <select
          className="form-control"
          id="order"
          value={order}
          onChange={(event) => setOrder(event.target.value)}
        >
          <option value="name asc">Name ascending</option>
          <option value="name desc">Name descending</option>
          {/* <option>Date ascending</option>
          <option>Date descending</option> */}
        </select>
      </div>

      <div className="form-group">
        <label className="form-control-label is-static" htmlFor="type">Type</label>
        <select
          className="form-control"
          id="type"
          value={type}
          onChange={(event) => setAllFilters({ ...allFilters, type: event.target.value })}
        >
          <option value="all">All</option>

          {types.map((oneType: string) => {
            return (
              <option value={oneType} key={oneType}>{oneType}</option>
            )
          })}

        </select>
      </div>

      <div className="form-group">
        <label className="form-control-label is-static" htmlFor="status">Status</label>
        <select
          className="form-control"
          id="status"
          value={status}
          onChange={(event) => setAllFilters({ ...allFilters, status: event.target.value })}
        >
          <option value="all">All</option>
          <option value="1">Received</option>
          <option value="0">Not received</option>
        </select>
      </div>

    </form>
  )
}

export default RecyclingInboxFilters
