import React from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"

import { RootState } from "../reducers"
import Icon from "../components/Icon"
import { showListView } from "../reducers/GarmentActions"


interface IStateProps {
  showListViewFlag: boolean,
}

interface IDispatchProps {
  onShowListView: (showListViewFlag: boolean) => void,
}

const CatalogueChooseView: React.FunctionComponent<IStateProps & IDispatchProps> = (props) => {


  const { showListViewFlag, onShowListView } = props

  return (
    <div className="listing-filters__actions">
      <Link
        className={`${!showListViewFlag && `active`} listing-filters__action`}
        data-testid="catalogueGridView"
        to="#"
        onClick={() => onShowListView(false)}
      >
        <Icon name="grid" />
      </Link>
      <Link
        className={`${showListViewFlag && `active`} listing-filters__action`}
        data-testid="catalogueListView"
        to="#"
        onClick={() => onShowListView(true)}
      >
        <Icon name="listing" />
      </Link>
    </div>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  showListViewFlag: state.garments.showListViewFlag,
})

const mapDispatchToProps =
  (dispatch: Dispatch<Action>): IDispatchProps => (
    {
      onShowListView: (showListViewFlag: boolean) => dispatch(showListView({showListViewFlag})),
    }
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CatalogueChooseView)
