import { Action } from "redux"
import { isType } from "typescript-fsa"

import {
  showNotification, clearNotification,
} from "./GeneralActions"


export interface IGeneralState {
  notificationType: string,
  notificationMessage: string,
  autoRemove: boolean,
}

export const initialState: IGeneralState = {
  notificationType: "",
  notificationMessage: "",
  autoRemove: false,
}

export default (state: IGeneralState = initialState, action: Action): IGeneralState => {

  if (isType(action, showNotification)) {
    const { notificationType, notificationMessage, autoRemove } = action.payload

    return {
      ...state,
      notificationType,
      notificationMessage,
      autoRemove,
    }
  }

  if (isType(action, clearNotification)) {
    return {
      ...state,
      notificationType: "",
      notificationMessage: "",
      autoRemove: false,
    }
  }

  return state
}



