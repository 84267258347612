import React from "react"
import { Link } from "react-router-dom"
import placeholder from "../images/garment-placeholder.svg"

import { IShortGarment } from "../reducers/GarmentModels"


interface IOwnProps {
  garment: IShortGarment
}

const CatalogueGridItem: React.FunctionComponent<IOwnProps> = (props) => {


  const STATUS_DRAFT = -1

  const { garment } = props

  if (! garment) {
    return null
  }

  const { name, base_photo, style_number, status } = garment

  return (
    <article className="grid-item" data-testid="catalogueGridViewGarmentItem">
      {/* <div className="grid-item__more dropdown">
        <a className="dropdown__toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <Icon name="dots-ver" />
        </a>
        <ul className="dropdown-menu dropdown-menu--right">
          <li><a className="dropdown-menu__item" href="#">Dropdown link</a></li>
        </ul>
      </div> */}

      <Link className="grid-item__link" to={`/garment/${name}/${style_number}`}>
        <figure className="mb--xs">
        <img
          className="contain center"
          data-testid="catalogueListViewGarmentPhoto"
          src={base_photo ? base_photo : placeholder}
          alt=""
          style={base_photo ? {} : {width: "100%"}}
        />
        </figure>
        {
          status === STATUS_DRAFT &&
          <span className="pill pill--outline-quiet pill--sm" data-testid="catalogueGridViewDraftStatus">Draft</span>
        }
        <h3 className="mb--none" data-testid="catalogueGridViewGarmentName">{name}</h3>
        <small className="text--quiet upcase" data-testid="catalogueGridViewGarmentStyleNumber">{style_number}</small>
      </Link>
    </article>
  )
}

export default CatalogueGridItem
