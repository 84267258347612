import React, { useEffect } from "react"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import { initialize } from "redux-form"
import { Dispatch, Action } from "redux"


import Icon from "../components/Icon"
import SupplyChainList from "../components/SupplyChainList"
import SupplyChainListEmpty from "../components/SupplyChainListEmpty"
import SupplyChainEntryForm from "../components/SupplyChainEntryForm"
import Loading from "../components/Loading"
import { getSupplyChain, shouldShowEntryForm, showSearch, showDropdown } from "../reducers/SupplyChainActions"
import SUPPLY_CHAIN from "../utils/supplyChainObject"

import { RootState } from "../reducers"
import {
  ISupplyChainEntry, ISupplyChainEntryArrayObject, ISupplyChainDropdownMenuControls,
} from "../reducers/SupplyChainModels"

interface IStateProps {
  entries?: ISupplyChainEntry[],
  isLoadingGetSupplyChain: boolean,

  isLoadingDeleteSupplyChainEntry: boolean,
  showEntryFormFlag: boolean,
}

interface IDispatchProps {
  getSupplyChain: () => void,
  onShouldShowEntryForm: (value: boolean) => void,
  onShowSearch: (flag: boolean) => void,
  onShowDropdown: (payload: ISupplyChainDropdownMenuControls) => void,
  initializeForm: (params: object) => void,
}

const SupplyChain: React.FunctionComponent<IStateProps & IDispatchProps> = (props) => {

  useEffect(() => {
    props.getSupplyChain()
    document.title = "Supply Chain"
  }, [])


  const addNew = (typeId: number) => {
    props.onShowSearch(false)
    props.onShouldShowEntryForm(true)
    props.onShowDropdown({ id: null, dropdownFlag: false })
    props.initializeForm({ type_id: typeId, connectedGarments: [] })
  }


  const { entries, isLoadingGetSupplyChain, isLoadingDeleteSupplyChainEntry, showEntryFormFlag } = props

  const entriesForType: ISupplyChainEntryArrayObject = {}

  if (entries) {
    entries.map((entry) => {
    if (! entriesForType[entry.type_id]) {
      entriesForType[entry.type_id] = []
    }
    entriesForType[entry.type_id].push(entry)
  })}

  return (
    <React.Fragment>

      {! showEntryFormFlag && (
        <Loading
          show={isLoadingDeleteSupplyChainEntry}
          text={`Loading supply chain...`}
          imgClass="block-center"
          divClass="main__content"
        />
      )}

      <Loading
        show={isLoadingGetSupplyChain}
        text={`Loading supply chain...`}
        imgClass="block-center"
        divClass="main__content"
      />

      {entries && (
        <div className="main__content">
          <div className="container" data-testid="supplyChain">
            <header>
              <h1 className="section__title">Supply Chain</h1>
            </header>
            <div className="content-wrapper">
              {Object.keys(SUPPLY_CHAIN).map((id) => {
                const idNum = Number(id)
                const { name, photoPlaceholder } = SUPPLY_CHAIN[idNum]
                return (
                  <section className="content-column" key={id}>
                    <header className="content-column__header">
                      <p className="text--quiet upcase">{name}</p>
                      <p>
                        <Link
                          className="text--sm"
                          data-testid={`supplyChainAddNew${name}`}
                          data-toggle="drawer"
                          to="#"
                          onClick={() => addNew(idNum)}
                        >
                          <Icon className="icon mr--xs valign-middle" name="plus-rounded" />
                          Add New
                        </Link>
                      </p>
                    </header>
                    {entriesForType[idNum] && entriesForType[idNum].length > 0 ? (
                      <SupplyChainList
                        entriesForType={entriesForType[idNum]}
                        photoPlaceholder={photoPlaceholder}
                      />
                    ) : (
                      <SupplyChainListEmpty photoPlaceholder={photoPlaceholder} name={name} />
                    )}
                  </section>
                )
              })}
            </div>
          </div>
        </div>
      )}
      <SupplyChainEntryForm />
    </React.Fragment>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  entries: state.supplyChain.entries,
  isLoadingGetSupplyChain: state.supplyChain.isLoadingGetSupplyChain,

  isLoadingDeleteSupplyChainEntry: state.supplyChain.isLoadingDeleteSupplyChainEntry,
  showEntryFormFlag: state.supplyChain.showEntryFormFlag,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    getSupplyChain: () => dispatch(getSupplyChain.started()),
    onShouldShowEntryForm: (showEntryFormFlag: boolean) => dispatch(shouldShowEntryForm({showEntryFormFlag})),
    initializeForm: (data: object) => dispatch(initialize("SupplyChainEntryForm", data)),
    onShowSearch: (newShowSearchFlag: boolean) => dispatch(showSearch({newShowSearchFlag})),
    onShowDropdown: (payload: ISupplyChainDropdownMenuControls) => dispatch(showDropdown({ payload })),
  }
)

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SupplyChain) as any,
)
