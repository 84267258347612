import React from "react"
import { withRouter, Link, RouteComponentProps } from "react-router-dom"
import { connect } from "react-redux"
import { Field, formValueSelector, change } from "redux-form"
import { Dispatch, Action } from "redux"

import Icon from "./Icon"
import placeholder from "../images/garment-placeholder.svg"
import { RootState } from "../reducers"
import { showSearch } from "../reducers/SupplyChainActions"
import { IShortGarment } from "../reducers/GarmentModels"



interface IOwnProps {
  connectedGarments: IShortGarment[],
}

interface IStateProps {
  showSearchFlag: boolean,
  searchText: string,
  garments?: IShortGarment[],
}

interface IDispatchProps {
  onShowSearch: () => void,
  emptySearchField: () => void,
  updateConnectedGarments: (data: IShortGarment[]) => void,
}

type Props = IOwnProps & IStateProps & IDispatchProps

const SupplyChainEntryConnectGarments: React.FunctionComponent<Props> = (props) => {

  const {
    showSearchFlag,
    onShowSearch, searchText, garments,
    emptySearchField, updateConnectedGarments,
  } = props

  let { connectedGarments } = props

  let filteredGarments: IShortGarment[] = []
  if (garments && searchText) {
    filteredGarments = garments.filter(
      (garment) =>
        garment.name.toLowerCase().includes(searchText && searchText.toLowerCase()) ||
        garment.style_number.toLowerCase().includes(searchText && searchText.toLowerCase()))
  }

  const addGarment = (garment: IShortGarment) => {

    if (connectedGarments.every((connectedGarment) => connectedGarment.id !== garment.id)) {
      connectedGarments = [...connectedGarments, garment]
    }

    updateConnectedGarments(connectedGarments)
    emptySearchField()
  }

  const removeGarment = (garment: IShortGarment) => {
    updateConnectedGarments(connectedGarments.filter((connectedGarment) => connectedGarment.id !== garment.id))
  }

  const renderSearchInput = (field: any) => {
    return (
      <input
        {...field.input}
        autoFocus
        className="form-control"
        data-testid="supplyChainSearchInput"
        type="text"
        id="supplierNameSearch"
        data-empty={! searchText}
        onKeyPress={ (event) => {
          if (event.key === "Enter") {
            event.preventDefault()
          }
        }}
      />
    )
  }

  return (
    <aside className="drawer__extension">
      <header className="mb--base">
        <p className="text--quiet upcase">Garments for supplier</p>

        <Link
          className="text--sm"
          data-testid="supplyChainAddNewGarment"
          to="#"
          data-toggle="collapse"
          role="button"
          aria-expanded="false"
          onClick={() => onShowSearch()}
        >
          <Icon name="plus-rounded" className="icon mr--xs valign-middle" />
          Add New
        </Link>

        <div
          className={`collapse ${showSearchFlag && "show"}`}
          id="add-garment-for-suppliers"
          data-testid="supplyChainSearchGarments"
        >
          <div className="form-group mt--base mb--none">
            <Field component={renderSearchInput} name="searchText" />
            <label className="form-control-label" htmlFor="supplierNameSearch">
              Search catalogue to add
            </label>
          </div>
          <ul className="dropdown-menu dropdown-menu show" style={{ position: "relative" }}>
            {filteredGarments.map((garment) =>  (
              <li key={garment.style_number}>
                <Link
                  className="dropdown-menu__item"
                  data-testid="supplyChainGarmentItem"
                  to="#"
                  onClick={() => addGarment(garment)}
                >
                  {garment.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </header>
      <div className="drawer__extension__main">

        {connectedGarments &&
          Object.values(connectedGarments).map((garment) => (
            <article
              className="list-item list-item--product--sm"
              data-testid="supplyChainConnectedGarment"
              key={garment.style_number}
            >
              <figure>
                <img
                  className="contain center"
                  data-testid="supplyChainConnectedGarmentPhoto"
                  src={garment.base_photo ? garment.base_photo : placeholder}
                  alt={garment.name}
                  width="50"
                />
              </figure>
              <section className="list-item__details">
                <div>
                  <h3 className="text--xs mb--none" data-testid="supplyChainConnectedGarmentName">{garment.name}</h3>
                  <small className="text--quiet upcase" data-testid="supplyChainConnectedGarmentStyleNumber">
                    {garment.style_number}
                  </small>
                </div>
                <Link
                  className="text--danger upcase"
                  data-testid="supplyChainRemoveConnectedGarment"
                  to="#"
                  onClick={() => removeGarment(garment)}
                >
                  Remove
                </Link>
              </section>
            </article>
          ))}

      </div>
    </aside>
  )
}
const selector = formValueSelector("SupplyChainEntryForm")

const mapStateToProps = (state: RootState): IStateProps => ({
  showSearchFlag: state.supplyChain.showSearchFlag,
  searchText: selector(state, "searchText"),
  garments: state.garments.garments,
})

const mapDispatchToProps =
  (dispatch: Dispatch<Action>): IDispatchProps => (
    {
      onShowSearch: () => dispatch(showSearch({ newShowSearchFlag: undefined })),
      emptySearchField: () => dispatch(change("SupplyChainEntryForm", "searchText", "")),
      updateConnectedGarments:
        (data: IShortGarment[]) => dispatch(change("SupplyChainEntryForm", "connectedGarments", data)),
    }
  )

const SupplyChainEntryConnectGarmentsRoutered = withRouter(SupplyChainEntryConnectGarments as any)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SupplyChainEntryConnectGarmentsRoutered) as any
