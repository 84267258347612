import React from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"

import Icon from "../Icon"
import Dropdown from "../Dropdown"
import { showDropdown } from "../../reducers/GarmentActions"
import { ISupplyChainEntry } from "../../reducers/SupplyChainModels"
import { RootState } from "../../reducers"
import { IGarmentDropdownMenuControls } from "../../reducers/GarmentModels"


interface IOwnProps {
  entry: ISupplyChainEntry,
  photoPlaceholder: string,
  removeEntry: (id: number) => void,
}

interface IStateProps {
  dropdownFlag: boolean,
  entryId?: number,
}

interface IDispatchProps {
  onShowDropdown: (payload: IGarmentDropdownMenuControls) => void,
}

const GarmentFormSupplyChainListEntry: React.FunctionComponent<IOwnProps & IStateProps & IDispatchProps> = (props) => {

  const {
    entry, photoPlaceholder, dropdownFlag, entryId, onShowDropdown,
  } = props

  const { photo, name, address, id } = entry

  let photoPreview = ""

  if (typeof(photo) === "string") {
    photoPreview = photo
  } else if (photo instanceof Object && photo.preview) {
    photoPreview = photo.preview
  }

  return (
    <article className="list-item--supply-chain" data-testid="garmentFormSupplyChainListEntry">
      <Link className="list-item" to="#">

        <figure className="list-item__figure">
          {! photo && <Icon name={photoPlaceholder} />}

          {photo && <img className="contain center" src={photoPreview} alt={name} width="90" />}
        </figure>

        <section className="list-item__details">
          <h3 className="mb--none text--xs" data-testid="garmentFormSupplyChainListEntryName">{name}</h3>
          <span className="text--quiet" data-testid="garmentFormSupplyChainListEntryAddress">
            <Icon className="icon valign-middle" name="location"/>
            {address}
          </span>
        </section>

      </Link>
      <Dropdown
        onShowDropdown={() => onShowDropdown({ dropdownFlag: ! dropdownFlag, entryId: id })}
        onHideDropdown={() => onShowDropdown({ dropdownFlag: ! dropdownFlag, entryId: id })}
        showCondition={dropdownFlag && entryId === id}
        dropdownItems={[
          {
            name: "Remove entry",
            action: () => [props.removeEntry(id), onShowDropdown({ dropdownFlag: ! dropdownFlag, entryId: id })],
          },
        ]}
      />

    </article>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  dropdownFlag: state.garments.dropdownFlag,
  entryId: state.garments.entryId,
})

const mapDispatchToProps =
  (dispatch: Dispatch<Action>): IDispatchProps => (
    {
      onShowDropdown: (payload: IGarmentDropdownMenuControls) => dispatch(showDropdown({ payload })),
    }
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GarmentFormSupplyChainListEntry)
