import React from "react"
import Dropzone from "react-dropzone"
import Icon from "./Icon"
import { IPhoto } from "../reducers/GeneralModels"

interface IOwnProps {
  input: {
    value: IPhoto,
    onChange: any,
  },
  name: string,
}


const SupplyChainEntryMedia: React.FunctionComponent<IOwnProps> = (field) => {

  let chosenFile: IPhoto | null = field.input.value


  const addPhoto = (filesToUpload: File[]) => {
    chosenFile = filesToUpload[0]
    return chosenFile
  }

  const removePhoto = () => {
    chosenFile = null
    return chosenFile
  }

  return (
    <React.Fragment>
      {chosenFile && (
        <figure className="uploaded-images__figure">
          <img
            src={chosenFile.preview}
            className="contain center"
            alt={chosenFile.name}
            data-testid="supplyChainSupplierChosenPhoto"
          />
          <a
            className="uploaded-images__remove"
            onClick={(event) => {
              event.stopPropagation()
              field.input.onChange(removePhoto())
            }}
          >
            <Icon name="cross-rounded-filled" />
          </a>
        </figure>
      )}
      {!chosenFile && (
        <Dropzone
          name={field.name}
          data-testid="supplyChainSupplierImageUpload"
          multiple={false}
          onDrop={(fileToUpload) => {
            field.input.onChange(addPhoto(fileToUpload))
          }}
          accept="image/png, image/jpeg"
          className="form-control-file__label"
        >
          Drag image to upload
        </Dropzone>
      )}
    </React.Fragment>
  )
}

export default SupplyChainEntryMedia
