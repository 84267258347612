import { useEffect, useRef } from "react"

export default function useHandleOutsideEventHook(onHideFunction: () => void, showCondition: boolean) {

  const ref = useRef(null) as any

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      onHideFunction()
    }
  }

  const handleClickOutside = (event: any) => {
    if (
      ref.current && !ref.current.contains(event.target)
      && !event.target.className.includes("message")
      && !event.target.className.includes("false")
      && !event.target.className.includes("supply-chain")
      && !event.target.className.includes("supply-chain")
      || (event.target.text && !event.target.text.toLowerCase().includes("remove"))
    ) {
      onHideFunction()
    }
  }

  useEffect(() => {
    if (showCondition) {
      document.addEventListener("keydown", handleHideDropdown)
      document.addEventListener("click", handleClickOutside)
      return () => {
        document.removeEventListener("keydown", handleHideDropdown)
        document.removeEventListener("click", handleClickOutside)
      }
    }
  }, [onHideFunction])

  return { ref }
}

