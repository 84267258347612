import React, { Component } from "react"
import { Dispatch, Action } from "redux"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"


import Icon from "./Icon"
import { clearNotification } from "../reducers/GeneralActions"

import { RootState } from "../reducers"


interface IProps {
  type: string,
  message: string,
  autoRemove: boolean,
}

interface IDispatchProps {
  onClearNotification: () => void,
}

interface IState {
  timer?: number,
}


export class Notification extends Component<IProps & IDispatchProps, IState> {

  public constructor(props: any) {
    super(props)

    this.state = { timer: undefined } as IState
  }

  public componentDidUpdate(prevProps: IProps) {
    const { autoRemove } = this.props
    if (autoRemove && prevProps.message !== this.props.message) {
      this.restartTimer()
    }
  }

  public restartTimer = () => {
    const { timer: currentTimer } = this.state
    clearTimeout(currentTimer)
    const newTimer = window.setTimeout(() => this.removeNotification(), 5000)
    this.setState({ timer: newTimer })
  }


  public removeNotification = () => {
    const { timer: currentTimer } = this.state
    clearTimeout(currentTimer)
    this.setState({ timer: undefined })

    const { onClearNotification } = this.props
    onClearNotification()
  }

  public render() {

    const { type, message } = this.props

    if (type.length === 0 || message.length === 0) { return null }


    let typeClassName: string | null = null
    let iconType: string | null = null

    switch (type) {
      case "success": typeClassName = "message--success"; iconType = "tick-rounded";  break
      case "info":  typeClassName = "message--info"; iconType = "info-rounded"; break
      case "warning": typeClassName = "message--warning"; iconType = "exclamation-mark-rounded"; break
      default /*"error"*/: typeClassName = "message--danger"; iconType = "arrow-down-rounded"; break
    }

    return (
      <React.Fragment>
        <aside
          className={`${typeClassName} message--fixed js-message message`}
          onClick={() => this.removeNotification()}
          data-testid="notificationContainer"
        >
          <div className="message__figure">
            <Icon name={iconType} />
          </div>
          {message}
        </aside>
      </React.Fragment>)
  }
}

const mapStateToProps = (state: RootState): IProps => ({
  type: state.general.notificationType,
  message: state.general.notificationMessage,
  autoRemove: state.general.autoRemove,
})

const mapDispatchToProps =
  (dispatch: Dispatch<Action>): IDispatchProps => (
    {
      onClearNotification: () => dispatch(clearNotification()),
    }
  )


export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Notification) as any,
)
