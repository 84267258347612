import { createStore, applyMiddleware, Store as ReduxStore } from "redux"
import { connectRouter, routerMiddleware } from "connected-react-router"
import createHistory from "history/createBrowserHistory"
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly"

import rootReducer, { RootState } from "./reducers"
import rootSaga from "./sagas"
import createSagaMiddleware from "redux-saga"

export const history = createHistory()
const initialState = {}
const sagaMiddleware = createSagaMiddleware()

const middleware = [sagaMiddleware, routerMiddleware(history)]

const composedEnhancers = composeWithDevTools(
  applyMiddleware(...middleware),
)

const store: ReduxStore<RootState> = createStore(
  connectRouter(history)(rootReducer),
  initialState,
  composedEnhancers,
)

sagaMiddleware.run(rootSaga)

export default store
