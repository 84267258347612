import actionCreatorFactory from "typescript-fsa"

const actionCreator = actionCreatorFactory("Settings")


export const getBrandSettings =
    actionCreator.async<void,
                        { brandSettings: any },
                        { errorMessage: string }
                      >("GET_BRAND_SETTINGS")

export const saveBrandSettings =
    actionCreator.async<{ payload: FormData },
                        { saveBrandSettingsResult: boolean },
                        { errorMessage: string }
                      >("EDIT_BRAND_SETTINGS")

export const getRecycleOptions =
    actionCreator.async<void,
                        { recycleOptions: any },
                        { errorMessage: string }
                      >("GET_RECYCLE_OPTIONS")

export const saveRecycleOptions =
    actionCreator.async<{ payload: any },
                        { saveRecycleOptionsResult: boolean },
                        { errorMessage: string }
                      >("EDIT_RECYCLEL_OPTIONS")
