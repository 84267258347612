import React from "react"
import Icon from "../components/Icon"

interface IOwnProps {
  onSearchTeam: (term: string) => void
}

const TeamSearch: React.FunctionComponent<IOwnProps> = (props) => {

  const { onSearchTeam } = props

  return (
    <div className="col-sm-8">
      <div className="row middle-sm txtr">
        <div className="col-sm-7 mb--sm mb-sm--none">
          {/* <Link className="button button--primary button--block-xs-only" to="#">
            Invite
          </Link> */}
        </div>
        <div className="col-sm-5 mb--sm mb-sm--none">
          <div className="form-group-search form-group-search--light">
            <Icon name="loupe" />
            <input
              className="form-control"
              data-testid="teamSearchFilter"
              type="search"
              name="search"
              placeholder="Type to search"
              onChange={(event) => onSearchTeam(event.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeamSearch
