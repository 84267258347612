import React from "react"
import { Link } from "react-router-dom"
import Dropdown from "./Dropdown"

import { IBaseUser } from "../reducers/UsersModels"

interface IOwnProps {
  person: IBaseUser,
  userId?: number | null,
  editUserFlag: boolean,
  editUserDropdownFlag: boolean,
  currentUserAccountTypeId?: number,

  onToggleEditUser: () => void,
  initializeForm: (data: IBaseUser) => void,
  onToggleEditUserDropdown: (userId: number | null, editUserDropdownFlag: boolean) => void,
  onDeleteTeamMember: (id: number, editUserFlag: boolean) => void,
}

const TeamListItem: React.FunctionComponent<IOwnProps> = (props) => {

  const {
    person, onToggleEditUser, initializeForm, onToggleEditUserDropdown,
    userId, editUserDropdownFlag, currentUserAccountTypeId,
    onDeleteTeamMember, editUserFlag,
  } = props

  if (!person) {
    return null
  }
  const { name, email, account_type_id, id } = person

  const editUser = () => {
    onToggleEditUser()
    initializeForm({ name, email, id, account_type_id })
  }

  return (
    <article className="list-item list-item--team" data-testid="teamUser">
      <div>
        <h3 className="mb--none" data-testid="teamUserName">

          {currentUserAccountTypeId === 2 && (
            <Link
              data-testid="teamUserNameLink"
              className="link--ui"
              to="#edit-user"
              data-toggle="drawer"
              onClick={() => editUser()}
            >
              {name}
            </Link>
          )}

          {currentUserAccountTypeId !== 2 && name}

        </h3>
      </div>
      <div className="list-item__details" data-testid="teamUserEmail">{email}</div>
      <div className="list-item__details" data-testid="teamUserAccountType">
        {account_type_id === 1 && <span className="pill pill--info">member</span>}
        {account_type_id === 2 && <span className="pill pill--warning">admin</span>}
        {account_type_id === 3 && <span className="pill pill--quiet">invited</span>}
      </div>
      {currentUserAccountTypeId === 2 &&
        <Dropdown
          onShowDropdown={() =>
            editUserDropdownFlag ? onToggleEditUserDropdown(null, false) : onToggleEditUserDropdown(id, true)}
          onHideDropdown={() => onToggleEditUserDropdown(null, false)}
          showCondition={editUserDropdownFlag && userId === id}
          dropdownItems={[
            {name: "Edit user", action: () => [editUser(), onToggleEditUserDropdown(null, false)]},
            {name: "Delete user", action: () => [
              onDeleteTeamMember(id, editUserFlag), onToggleEditUserDropdown(null, false),
            ]},
          ]}
        />
      }
    </article>
  )
}

export default TeamListItem
