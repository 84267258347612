import request, { generatePostOptionsForRequest, generateDeleteOptionsForRequest } from "../utils/request"

import { call, put, takeLatest } from "redux-saga/effects"
import { SagaIterator } from "redux-saga"

import {
  login, logout, getCurrentUserInfo, getTeam, editTeamMember, toggleEditUser, deleteTeamMember,
} from "../reducers/UsersActions"

import { notify } from "../reducers/GeneralActions"



/* ************************************************************* */
function * loginSaga(action: any) {

  const { payload } = action.payload

  try {
    const postOptions = generatePostOptionsForRequest(payload)
    const result = yield call(request, "/login", postOptions)

    yield put(login.done({ params: { payload }, result }))

  } catch (err) {
    console.log(err)
    const { message } = err
    yield put(login.failed({ params: { payload }, error: { errorMessage: message }}))
  }
}

export function * watchLogin(): SagaIterator {
  yield takeLatest(login.started, loginSaga)
}
/* ************************************************************* */

/* ************************************************************* */
function * logoutSaga() {

  try {
    const result = yield call(request, "/logout")

    yield put(logout.done({ result }))
  } catch (err) {
    console.log(err)
    yield put(logout.failed({ error: err }))
  }
}

export function * watchLogout(): SagaIterator {
  yield takeLatest(logout.started, logoutSaga)
}
/* ************************************************************* */


/* ************************************************************* */
function * getCurrentUser() {
  try {
    const result = yield call(request, "/users/currentUser")

    yield put(getCurrentUserInfo.done({ result }))
  } catch (err) {
    console.log(err)
    yield put(getCurrentUserInfo.failed({ error: err }))
  }
}

export function * watchGetCurrentUser(): SagaIterator {
  yield takeLatest(getCurrentUserInfo.started, getCurrentUser)
}
/* ************************************************************* */

/* ************************************************************* */
function * getAllTeam() {

  try {
    const result = yield call(request, "/users/team")

    yield put(getTeam.done({ result }))
  } catch (err) {
    console.log(err)
    const { message } = err
    yield put(getTeam.failed({ error: { errorMessage: message }}))
    yield put(notify("error", "Error while getting team information."))
  }
}

export function * watchGetAllTeam(): SagaIterator {
  yield takeLatest(getTeam.started, getAllTeam)
}
/* ************************************************************* */

/* ************************************************************* */
function * editTeamUser(action: any) {

  const { payload } = action.payload

  try {
    const result = yield call(request, "/users/user", generatePostOptionsForRequest(payload))

    yield put(editTeamMember.done({ params: { payload }, result }))
    yield put(notify("success", "The team member was saved successfully."))
    yield put(getTeam.started())
    yield put(toggleEditUser())
  } catch (err) {
    console.log(err)
    const { message } = err
    yield put(editTeamMember.failed({ params: { payload }, error: { errorMessage: message }}))
    yield put(notify("error", "There was an error while saving a team user."))
  }
}

export function * watchEditTeamUser(): SagaIterator {
  yield takeLatest(editTeamMember.started, editTeamUser)
}
/* ************************************************************* */

/* ************************************************************* */
function * deleteTeamUser(action: any) {

  const { id, editUserFlag } = action.payload

  try {
    const result = yield call(request, "/users/user", generateDeleteOptionsForRequest({ id }))

    yield put(deleteTeamMember.done({ params: { id, editUserFlag }, result }))
    yield put(notify("success", "The team member was deleted successfully."))

    yield put(getTeam.started())
    if (editUserFlag)  {
      yield put(toggleEditUser())
    }
  } catch (err) {
    console.log(err)
    const { message } = err

    yield put(deleteTeamMember.failed({ params: { id, editUserFlag }, error: { errorMessage: message }}))
    yield put(notify("error", "There was an error while deleting a team user."))
  }
}

export function * watchDeleteTeamUser(): SagaIterator {
  yield takeLatest(deleteTeamMember.started, deleteTeamUser)
}
/* ************************************************************* */
