import React from "react"
import { Link } from "react-router-dom"

import { IRecycledGarment } from "../reducers/RecycleModels"
import { StatusTypes } from "../reducers/RecycleReducers"

import Icon from "./Icon"
import { copyToClipboard } from "../utils/utils"
import { dateFormat } from "../utils/utils"

interface IOwnProps {
  item: IRecycledGarment,
  index: number,
  notifyStyleNumberCopied: (message: string) => void,
  onMarkGarmentAsReceived: (garmentCode: string) => void,
  onMarkGarmentAsNotReceived: (garmentCode: string) => void
}

const RecyclingInboxItem: React.SFC<IOwnProps> = (props) => {

  const { item, index, notifyStyleNumberCopied, onMarkGarmentAsReceived, onMarkGarmentAsNotReceived } = props

  const { added_on, name, brand_name, style_number, status, base_photo, user_address, garment_code } = item

  return (
    <article className="list-item list-item--recycling">

      <figure>
        <Link className="block" to={`/garment/${name}/${style_number}`}>
          <img className="contain center" src={base_photo} alt="" width="64" />
        </Link>
      </figure>

      <section className="list-item__details">
        <div>
          <Link className="link--ui" to={`/garment/${name}/${style_number}`}>
            <h3 className="mb--none">{name}</h3>
          </Link>
          <small className="text--quiet upcase">{brand_name}</small>
        </div>
        <small>{dateFormat(new Date(added_on))}</small>
        <small>{user_address}</small>
        <small id={`styleNumber${index}`}>{style_number}
          <Link
            className="inline-block ml--xs valign-middle"
            onClick={() => copyToClipboard(index, (message) => notifyStyleNumberCopied(message))}
            to="#"
          >
            <Icon name="copy" />
          </Link>
        </small>
        <div className="txtr">

          {status === StatusTypes.notReceived &&
            <button className="button button--primary button--sm" onClick={() => onMarkGarmentAsReceived(garment_code)}>
              <Icon name="tick-rounded" /> Received
          </button>
          }

          {status === StatusTypes.received &&
            <>
              <span className="pill pill--quiet">
                Received
              </span>
              <small className="block mt--sm" onClick={() => onMarkGarmentAsNotReceived(garment_code)}>
                <Link to="#">Not Received</Link>
              </small>
            </>
          }

        </div>
      </section>

    </article>
  )
}

export default RecyclingInboxItem
