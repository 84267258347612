import React from "react"
import { Link, withRouter } from "react-router-dom"
import { Field, reduxForm, formValueSelector } from "redux-form"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"


import Icon from "./Icon"
import Loading from "../components/Loading"
import { editTeamMember, deleteTeamMember } from "../reducers/UsersActions"

import { RootState } from "../reducers"
import { IBaseUser } from "../reducers/UsersModels"
import useHandleOutsideEventHook from "../utils/useHandleOutsideEventHook"

interface IOwnProps {
  onToggleEditUser: () => void,
  editUserFlag: boolean,
}

interface IStateProps {
  name: string,
  email: string,
  id: number,
  account_type_id: number,

  isLoadingEditTeamMember: boolean,

  isLoadingDeleteTeamMember: boolean,
}

interface IDispatchProps {
  onEditTeamMember: (payload: IBaseUser) => void,
  onDeleteTeamMember: (id: number, editUserFlag: boolean) => void,
}

const TeamEditUser: React.FunctionComponent<IOwnProps & IStateProps & IDispatchProps> = (props) => {
  const {
    onToggleEditUser, editUserFlag, name, email, id, account_type_id, onEditTeamMember,
    onDeleteTeamMember, isLoadingEditTeamMember, isLoadingDeleteTeamMember,
  } = props

  const ref = useHandleOutsideEventHook(() => onToggleEditUser(), editUserFlag).ref

  return (
    <React.Fragment>

      <aside
        className={`drawer drawer--right ${editUserFlag && `show`}`}
        data-testid="teamEditUser"
        id="edit-user"
        role="menu"
        aria-label="Edit user"
        tabIndex={-1}
        ref={ref}
      >
        <Loading
          show={isLoadingEditTeamMember || isLoadingDeleteTeamMember}
          text={`Loading team...`}
          imgClass="block-center"
          divClass="main__content"
        />
        {!isLoadingEditTeamMember && !isLoadingDeleteTeamMember && (
          <React.Fragment>
            <Link
              className="drawer__close"
              data-testid="teamCloseEditUser"
              to="#"
              data-dismiss="drawer"
              aria-label="Close"
              onClick={() => onToggleEditUser()}
            >
              <Icon name="cross-rounded" />
            </Link>
            <form className="drawer__content">
              <header className="drawer__header">
                <h3 className="drawer__title">Edit User</h3>
              </header>
              <section className="drawer__main">
                <div className="form-group">
                  <Field
                    component="input"
                    data-testid="teamEditUserNameInput"
                    className="form-control"
                    type="text"
                    name="name"
                    id="name"
                    data-empty={!name}
                  />
                  <label className="form-control-label" htmlFor="name">
                    Name
                  </label>
                </div>
                <div className="form-group">
                  <Field
                    component="input"
                    className="form-control"
                    data-testid="teamEditUserEmailInput"
                    type="email"
                    name="email"
                    id="email"
                    data-empty={!email}
                  />
                  <label className="form-control-label" htmlFor="email">
                    Email
                  </label>
                </div>
                <div className="form-actions mb--base">
                  {/* <Link className="button button--outline-dark button--sm mr--sm" to="#">
              Reset Password
            </Link>
            <small>A password reset link will be sent via email</small> */}
                </div>
                <div className="form-check">
                  {/* <Field
              component="input"
              className="form-check__input"
              type="checkbox"
              name="feature_1_1"
              id="feature_1_1-value_1_1"
              value="value_1_1"
            />
            <label className="form-check__label" htmlFor="feature_1_1-value_1_1">
              Can manage feature #1
            </label>
          </div>
          <div className="form-check">
            <Field
              component="input"
              className="form-check__input"
              type="checkbox"
              name="feature_1_2"
              id="feature_1_2-value_1_2"
              value="value_1_2"
            />
            <label className="form-check__label" htmlFor="feature_1_2-value_1_2">
              Can manage feature #2
            </label> */}
                </div>
              </section>
              <footer className="drawer__footer">
                <div className="row middle-sm">
                  <div className="col-sm">
                    <Link
                      className="link--danger upcase"
                      data-testid="teamEditUserDelete"
                      to="#"
                      onClick={() => onDeleteTeamMember(id, editUserFlag)}
                    >
                      Delete user
                    </Link>
                  </div>
                  <div className="col-sm txtr">
                    <Link
                      className="button button--primary"
                      data-testid="teamEditUserSave"
                      to="#"
                      onClick={() => onEditTeamMember({ name, email, id, account_type_id })}
                    >
                      Save details
                    </Link>
                  </div>
                </div>
              </footer>
            </form>
          </React.Fragment>
        )}
      </aside>
    </React.Fragment>
  )
}
const selector = formValueSelector("User")

const mapStateToProps = (state: RootState): IStateProps => ({
  name: selector(state, "name"),
  email: selector(state, "email"),
  id: selector(state, "id"),
  account_type_id: selector(state, "account_type_id"),

  isLoadingEditTeamMember: state.users.isLoadingEditTeamMember,

  isLoadingDeleteTeamMember: state.users.isLoadingDeleteTeamMember,
})

const mapDispatchToProps =
  (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    onEditTeamMember: (payload: IBaseUser) => dispatch(editTeamMember.started({ payload })),
    onDeleteTeamMember: (id: number, editUserFlag: boolean) => dispatch(deleteTeamMember.started({ id, editUserFlag })),
  }
)

const TeamEditUserReduxFormed = reduxForm({
  form: "User",
})(TeamEditUser as any)

const wr = withRouter(TeamEditUserReduxFormed as any)

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(wr) as any
