import React from "react"
import { render } from "react-dom"
import { Provider } from "react-redux"
import { ConnectedRouter } from "connected-react-router"
import store, { history } from "./store"
import App from "./containers/App"

// import registerServiceWorker from "./registerServiceWorker"

import "./index.css"
import "./ours.css"

const target = document.querySelector("#root")

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  target,
)

// registerServiceWorker();
