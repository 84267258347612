import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"

import { RootState } from "../reducers"
import { notify } from "../reducers/GeneralActions"
import { IFeedback, IFilters } from "../reducers/FeedbackModels"
import { getReviews, shoudShowFeedback, deleteReview } from "../reducers/FeedbackActions"

import FeedbackFilters from "../components/FeedbackFilters"
import FeedbackSearch from "../components/FeedbackSearch"
import FeedbackItem from "../components/FeedbackItem"
import { filterGarments, sortAndSearchGarments } from "../utils/utils"
import Loading from "../components/Loading"
import FeedbackView from "../components/FeedbackView"

interface IStateProps {
  reviews?: IFeedback[],
  filters: IFilters,
  isLoadingGetReviews: boolean,
  showFeedback: boolean,
  activeFeedback?: IFeedback,
  isLoadingDeleteReview: boolean,
}

interface IDispatchProps {
  notifyStyleNumberCopied: (message: string) => void,
  onGetReviews: () => void,
  onDeleteReview: (reviewId: number) => void,
  onShouldShowFeedback: (showFeedback: boolean, activeFeedback: IFeedback) => void,
}

const Feedback: React.FunctionComponent<IStateProps & IDispatchProps> = (props) => {

  const {
    notifyStyleNumberCopied, onGetReviews, filters, isLoadingGetReviews,
    showFeedback, onShouldShowFeedback, activeFeedback,
    onDeleteReview, isLoadingDeleteReview,
  } = props

  useEffect(() => {
    onGetReviews()
  }, [])

  const [reviews, setReviews] = useState(props.reviews)

  useEffect(() => {
   setReviews(props.reviews)
  }, [props.reviews])

  const [order, setOrder] = useState("name asc")
  const [allFilters, setAllFilters] = useState({ garment_type: "all", garment_line: "all", garment_name: "all" })
  const [searchText, setSearchText] = useState("") as any

  const [filteredReviews, setFilteredReviews] = useState(undefined) as any

  useEffect(() => {
    if (reviews) {
      const filteredGarments = filterGarments(reviews, allFilters)
      if (filteredGarments) {
        setFilteredReviews(sortAndSearchGarments(filteredGarments, searchText, order))
      }
    }

  }, [reviews, allFilters, order, searchText])


  return (
    <>

      <Loading
        show={isLoadingGetReviews}
        text={`Loading...`}
        imgClass="block-center"
        divClass="main__content"
      />

      {! isLoadingGetReviews &&
        <div className="main__content">
          <div className="main__content">
            <div className="container">
              <header>
                <h1 className="section__title">Feedback</h1>
              </header>

              <section className="listing-filters">

                <FeedbackFilters
                  order={order}
                  setOrder={setOrder}
                  allFilters={allFilters}
                  setAllFilters={setAllFilters}
                  filters={filters}
                />

                <FeedbackSearch
                  searchText={searchText}
                  setSearchText={setSearchText}
                />

              </section>

              <section className="listing-header listing-header--feedback">
                <span className="listing-header__label">User info</span>
                <span className="listing-header__label">Date</span>
                <span className="listing-header__label">Garment</span>
                <span className="listing-header__label">Survey</span>
                <span className="listing-header__label"> </span>
              </section>

              <section>

                {filteredReviews && filteredReviews.map((review: IFeedback, index: number) => {
                  return (
                    <FeedbackItem
                      key={review.answer_id}
                      item={review}
                      index={index}
                      notifyStyleNumberCopied={notifyStyleNumberCopied}
                      onShouldShowFeedback={onShouldShowFeedback}
                    />
                  )
                })}

                <FeedbackView
                  showFeedback={showFeedback}
                  onShouldShowFeedback={onShouldShowFeedback}
                  item={activeFeedback}
                  onDeleteReview={onDeleteReview}
                  isLoadingDeleteReview={isLoadingDeleteReview}
                />

              </section>
            </div>
          </div>
        </div>
      }
    </>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  reviews: state.feedback.reviews,
  filters: state.feedback.filters,
  isLoadingGetReviews: state.feedback.isLoadingGetReviews,
  showFeedback: state.feedback.showFeedback,
  activeFeedback: state.feedback.activeFeedback,
  isLoadingDeleteReview: state.feedback.isLoadingDeleteReview,
})


const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    notifyStyleNumberCopied: (message: string) => dispatch(notify("success", message, true)),
    onGetReviews: () => dispatch(getReviews.started()),
    onDeleteReview: (reviewId: number) => dispatch(deleteReview.started({ reviewId })),
    onShouldShowFeedback: (showFeedback: boolean, activeFeedback: IFeedback) =>
      dispatch(shoudShowFeedback({ showFeedback, activeFeedback })),
  }
)

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Feedback) as any,
)
