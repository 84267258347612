import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import Dropzone from "react-dropzone"
import { Dispatch, Action } from "redux"

import { RootState } from "../reducers"
import { getBrandSettings, saveBrandSettings } from "../reducers/SettingsActions"
import { IBrandSettings } from "../reducers/SettingsModels"

import Loading from "./Loading"

interface IOwnProps {
  tab: string,
}

interface IStateProps {
  brandSettings?: IBrandSettings,
  isLoadingGetBrandSettings: boolean,
  isLoadingSaveBrandSettings: boolean,
}

interface IDispatchProps {
  onGetBrandSettings: () => void,
  onSaveBrandSettings: (payload: FormData) => void,
}

const SettingsGeneral: React.SFC<IOwnProps & IStateProps & IDispatchProps> = (props) => {

  const {
    tab, onGetBrandSettings, brandSettings, isLoadingGetBrandSettings, onSaveBrandSettings, isLoadingSaveBrandSettings,
  } = props

  const [brandName, setBrandName] = useState("")
  const [siteAddress, setSiteAddress] = useState("")
  const [brandLogo, setBrandLogo] = useState(undefined) as any

  useEffect(() => {
    onGetBrandSettings()
  }, [])

  useEffect(() => {
    if (brandSettings) {
      const { name, website, logo } = brandSettings
      setBrandName(name ? name : "")
      setSiteAddress(website ? website : "")
      setBrandLogo(logo)
    }
  }, [brandSettings])

  const saveBrandSettings = () => {
    const formData = new FormData()
    if (brandName) {
      formData.append("name", brandName)
    }
    if (siteAddress) {
      formData.append("website", siteAddress)
    }

    if (brandLogo && Object.keys(brandLogo).length > 0) {
      if (typeof (brandLogo) === "string") {
        formData.append("logo", brandLogo)
      } else if (! brandLogo.size) {
        formData.append("logo", brandLogo.name)
      } else {
        formData.append("logo", brandLogo, brandLogo.name)
      }
    }

    onSaveBrandSettings(formData)
  }

  return (
    <div
      className={`tab-pane fade in ${tab === "General" && "active show"}`}
      role="tabpanel"
      id="tab-general"
      aria-labelledby="tab-toggle-general"
    >
      <Loading
        show={isLoadingGetBrandSettings || isLoadingSaveBrandSettings}
        text={"Loading..."}
        imgClass="block-center"
        divClass="col-sm-12"
      />

      {(! isLoadingGetBrandSettings && ! isLoadingSaveBrandSettings) && (
        <fieldset className="box box--light box--shadowed mb--md">
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              name="company-name"
              id="company-name"
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)}
              data-empty="false"
            />
            <label className="form-control-label" htmlFor="company-name">Company name</label>
          </div>

          <div className="form-group">
            <input
              className="form-control"
              type="text"
              name="site-address"
              id="site-address"
              value={siteAddress}
              onChange={(e) => setSiteAddress(e.target.value)}
              data-empty="false"
            />
            <label className="form-control-label" htmlFor="site-address">Site address</label>
            <div className="form-text">No spaces. This will become your-site-address.cacotec.com</div>
          </div>

          <div className="form-group">
            <div className="media">
              <div className="media__left">
                <img
                  className="contain"
                  src={brandLogo ? (brandLogo.preview ? brandLogo.preview : brandLogo) : "//placehold.it/200x100"}
                  alt="logo placeholder"
                  style={{ width: "200px", height: "100px" }}
                />
                {brandLogo &&
                  <div className="form-text" onClick={() => setBrandLogo("")}>
                    <a href="#">Remove existing logo</a>
                  </div>
                }
              </div>
              <div className="media__body">
                <Dropzone
                  name="logo"
                  multiple={false}
                  onDrop={(fileToUpload) => setBrandLogo(fileToUpload[0])}
                  accept="image/png, image/jpeg"
                  className="form-control-file__label"
                >
                  Upload your logo
                </Dropzone>
                <div className="form-text">Upload a PNG or a JPG file to set a logo for your site.</div>
              </div>
            </div>
          </div>
          <div className="form-actions mt--md">
            <button
              className="button button--primary"
              onClick={saveBrandSettings}
            >
              Save Settings
          </button>
            {/* <a className="upcase">Cancel</a> */}
          </div>
        </fieldset>
      )}

    </div>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  isLoadingGetBrandSettings: state.settings.isLoadingGetBrandSettings,
  isLoadingSaveBrandSettings: state.settings.isLoadingSaveBrandSettings,
  brandSettings: state.settings.brandSettings,
})

const mapDispatchToProps =
  (dispatch: Dispatch<Action>): IDispatchProps => (
    {
      onGetBrandSettings: () => dispatch(getBrandSettings.started()),
      onSaveBrandSettings: (payload: FormData) => dispatch(saveBrandSettings.started({ payload })),
    }
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingsGeneral)
