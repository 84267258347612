import request, { generatePutOptionsForFileUpload, generateDeleteOptionsForRequest } from "../utils/request"
import { call, put, takeLatest } from "redux-saga/effects"
import { SagaIterator } from "redux-saga"


import {
  getSupplyChain, addSupplyChainEntry, deleteSupplyChainEntry, shouldShowEntryForm,
} from "../reducers/SupplyChainActions"

import { notify } from "../reducers/GeneralActions"


/* ************************************************************* */
function* getSupplyChainRequest() {
  try {
    const result = yield call(request, "/supplyChain/entries")
    yield put(getSupplyChain.done({ result }))
  } catch (err) {
    console.log(err)
    const { message } = err
    yield put(getSupplyChain.failed({ error: { errorMessage: message }}))
    yield put(notify("error", "Error while getting supply chain entries."))
  }
}

export function* watchGetSupplyChainRequest(): SagaIterator {
  yield takeLatest(getSupplyChain.started, getSupplyChainRequest)
}
/* ************************************************************* */

/* ************************************************************* */
function* addEntry(action: any) {

  const { payload } = action.payload

  try {
    yield call(request, "/supplyChain/entry", generatePutOptionsForFileUpload(payload))
    yield put(addSupplyChainEntry.done({ params: { payload }}))
    yield put(notify("success", "The entry was saved successfully."))
    yield put(getSupplyChain.started())
    yield put(shouldShowEntryForm({ showEntryFormFlag: false }))
  } catch (err) {
    console.log(err)
    const { message } = err
    yield put(addSupplyChainEntry.failed({ params: { payload }, error: { errorMessage: message }}))
    yield put(notify("error", "Error while adding a supply chain entry."))
  }
}

export function* watchAddEntry(): SagaIterator {
  yield takeLatest(addSupplyChainEntry.started, addEntry)
}
/* ************************************************************* */

/* ************************************************************* */
function* deleteEntry(action: any) {

  const { id } = action.payload

  try {
    yield call(request, "/supplyChain/entry", generateDeleteOptionsForRequest({ id }))
    yield put(deleteSupplyChainEntry.done({ params: { id }}))
    yield put(notify("success", "The entry was deleted successfully."))
    yield put(getSupplyChain.started())
    yield put(shouldShowEntryForm({ showEntryFormFlag: false }))
  } catch (err) {
    console.log(err)
    const { message } = err
    yield put(deleteSupplyChainEntry.failed({ params: { id }, error: { errorMessage: message }}))
    yield put(notify("error", "Error while deleting a supply chain entry."))

  }
}

export function* watchDeleteEntry(): SagaIterator {
  yield takeLatest(deleteSupplyChainEntry.started, deleteEntry)
}
/* ************************************************************* */
