import actionCreatorFactory from "typescript-fsa"

import { ISupplyChainEntry, ISupplyChainDropdownMenuControls } from "./SupplyChainModels"

const actionCreator = actionCreatorFactory("SupplyChain")



export const getSupplyChain =
    actionCreator.async<void,
                        { entries: ISupplyChainEntry[] },
                        { errorMessage: string }
                    >("GET_SUPPLY_CHAIN")


export const addSupplyChainEntry =
    actionCreator.async<{ payload: FormData },
                        void,
                        { errorMessage: string }
                    >("ADD_SUPPLY_CHAIN_ENTRY")

export const deleteSupplyChainEntry =
    actionCreator.async<{ id: number },
                        void,
                        { errorMessage: string }
                    >("DELETE_SUPPLY_CHAIN_ENTRY")



export const shouldShowEntryForm = actionCreator<{ showEntryFormFlag: boolean }>("TOGGLE_EDIT_USER_DROPDOWN")

export const showSearch = actionCreator<{ newShowSearchFlag?: boolean }>("SEARCH_FLAG")

export const showDropdown = actionCreator<{ payload: ISupplyChainDropdownMenuControls }>("SHOW_DROPDOWN")
