import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"

import { getRecycleInbox, markGarmentAsReceived, markGarmentAsNotReceived } from "../reducers/RecycleActions"
import { RootState } from "../reducers"
import { notify } from "../reducers/GeneralActions"
import { IRecycledGarment } from "../reducers/RecycleModels"

import Paging from "../components/Paging"
import RecyclingInboxFilters from "../components/RecyclingInboxFilters"
import RecyclingInboxSearch from "../components/RecyclingInboxSearch"
import RecyclingInboxItem from "../components/RecyclingInboxItem"
import { filterGarments, sortAndSearchGarments } from "../utils/utils"
import Loading from "../components/Loading"


interface IStateProps {
  recycleInbox?: IRecycledGarment[],
  isLoadingGetRecycleInbox: boolean,
  types: string[]
}

interface IDispatchProps {
  onGetRecycleInbox: () => void,
  onMarkGarmentAsReceived: (garmentCode: string) => void,
  onMarkGarmentAsNotReceived: (garmentCode: string) => void,
  notifyStyleNumberCopied: (message: string) => void,
}

const RecyclingInbox: React.FunctionComponent<IStateProps & IDispatchProps> = (props) => {

  const {
    onGetRecycleInbox, recycleInbox, isLoadingGetRecycleInbox, types,
    notifyStyleNumberCopied, onMarkGarmentAsReceived, onMarkGarmentAsNotReceived,
  } = props

  const [order, setOrder] = useState("name asc")
  const [allFilters, setAllFilters] = useState({ type: "all", status: "all" })
  const [searchText, setSearchText] = useState("") as any

  const [filteredRecycleInbox, setFilteredRecycleInbox] = useState(undefined) as any


  useEffect(() => {
    onGetRecycleInbox()
  }, [])

  useEffect(() => {
    if (recycleInbox) {
      const filteredGarments = filterGarments(recycleInbox, allFilters)
      if (filteredGarments) {
        setFilteredRecycleInbox(sortAndSearchGarments(filteredGarments, searchText, order))
      }
    }

  }, [recycleInbox, allFilters, order, searchText])

  return (
    <>
      <Loading
        show={isLoadingGetRecycleInbox}
        text={`Loading...`}
        imgClass="block-center"
        divClass="main__content"
      />

      {!isLoadingGetRecycleInbox && (
        <div className="main__content">
          <div className="main__content">
            <div className="container">
              <header>
                <h1 className="section__title">Recycling Inbox</h1>
              </header>
              <section className="listing-filters">

                <RecyclingInboxFilters
                  order={order}
                  setOrder={setOrder}
                  allFilters={allFilters}
                  setAllFilters={setAllFilters}
                  types={types}

                />

                <RecyclingInboxSearch
                  searchText={searchText}
                  setSearchText={setSearchText}
                />

              </section>

              <section className="listing-header listing-header--recycling">
                <span className="listing-header__label">Basic info</span>
                <span className="listing-header__label">Date sent</span>
                <span className="listing-header__label">Return addr.</span>
                <span className="listing-header__label">Style No</span>
                <span className="listing-header__label">Received?</span>
              </section>

              <section>

                {filteredRecycleInbox &&
                  filteredRecycleInbox.map((item: any, index: number) => {
                    return (
                      <RecyclingInboxItem
                        item={item}
                        index={index}
                        key={item.style_number}
                        notifyStyleNumberCopied={notifyStyleNumberCopied}
                        onMarkGarmentAsReceived={onMarkGarmentAsReceived}
                        onMarkGarmentAsNotReceived={onMarkGarmentAsNotReceived}
                      />
                    )
                  })}

              </section>

              {/* <Paging /> */}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  recycleInbox: state.recycle.recycleInbox,
  isLoadingGetRecycleInbox: state.recycle.isLoadingGetRecycleInbox,
  types: state.recycle.types,
})


const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    onGetRecycleInbox: () => dispatch(getRecycleInbox.started()),
    onMarkGarmentAsReceived: (garmentCode: string) => dispatch(markGarmentAsReceived.started({ garmentCode })),
    onMarkGarmentAsNotReceived: (garmentCode: string) => dispatch(markGarmentAsNotReceived.started({ garmentCode })),
    notifyStyleNumberCopied: (message: string) => dispatch(notify("success", message, true)),
  }
)

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RecyclingInbox) as any,
)
