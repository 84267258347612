import React from "react"
import { Switch, Route } from "react-router"

import Navigation from "./Navigation"
import Notification from "../components/Notification"

import Logout from "../components/Logout"
import Garment from "./Garment"
import Catalogue from "./Catalogue"
import Team from "./Team"
import SupplyChain from "./SupplyChain"
import RecyclingInbox from "./RecyclingInbox"
import Feedback from "./Feedback"
import Settings from "./Settings"

import NotFound from "./NotFound"

interface IProps {
  currentPath: string
}

const Home: React.FunctionComponent<IProps> = (props: IProps) => {


  const { currentPath } = props

  return (

    <React.Fragment>
      {currentPath.indexOf("/garment") === 0 ?

        <Switch>
          <Route path="/garment/:name/:styleNumber" component={Garment} />
          <Route exact path="/garment" component={Garment} />
        </Switch>

        :

        <main className={`main ${currentPath === `/supply-chain` && `supply-chain`}`}>

          <Navigation />

          <Switch>
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/team" component={Team} />
            <Route exact path="/notFound" component={NotFound} />
            <Route exact path="/supply-chain" component={SupplyChain} />
            <Route exact path="/recycling-inbox" component={RecyclingInbox} />
            <Route exact path="/feedback" component={Feedback} />
            <Route exact path="/settings" component={Settings} />

            <Route path="*" component={Catalogue} />
          </Switch>

        </main>
      }
      <Notification />

    </React.Fragment>
  )
}

export default Home
