import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Route } from "react-router"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { Dispatch, Action } from "redux"

import Auth from "./Auth"
import Home from "./Home"
import Loading from "../components/Loading"

import { getCurrentUserInfo } from "../reducers/UsersActions"

import { RootState } from "../reducers"
import { IUser } from "../reducers/UsersModels"


interface ILocationParams {
  pathname: string,
}

interface IStateProps {
  user?: IUser,
  userLoading: boolean,
}

interface IDispatchProps {
  getCurrentUserInfo: () => void
}

const App: React.FunctionComponent<IStateProps & IDispatchProps & RouteComponentProps<ILocationParams>> = (props) => {

  useEffect(() => {
    const { user } = props
    if (! user) {
      props.getCurrentUserInfo()
    }
  }, [])

  const whatToRender = () => {
    const { user, userLoading } = props

    if (user) {
      return (<Home currentPath={props.location.pathname} />)
    }

    if (userLoading) {
      return (
        <Loading
        show={true}
        text={`Loading...`}
        imgClass="block-center"
        divClass="main__content"
        />
      )
    }

    return (<Auth />)
  }

  return (
    <Route path="/" render={whatToRender}/>
  )

}

const mapStateToProps = (state: RootState): IStateProps => ({
  user: state.users.currentUser,
  userLoading: state.users.isLoadingCurrentUser,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    getCurrentUserInfo: () => dispatch(getCurrentUserInfo.started()),
  }
)

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(App) as any,
)
