import { Action } from "redux"
import { isType } from "typescript-fsa"

import { getRecycleInbox } from "./RecycleActions"
import { IRecycledGarment } from "./RecycleModels"

export const StatusTypes = Object.freeze({
  notReceived: 0,
  received: 1,
})


export interface IRecycleState {
  isLoadingGetRecycleInbox: boolean,
  errorGetRecycleInbox?: string,
  recycleInbox?: IRecycledGarment[],
  types: string[],
}

export const initialState: IRecycleState = {
  isLoadingGetRecycleInbox: false,
  errorGetRecycleInbox: undefined,
  recycleInbox: undefined,
  types: [],

}

export default (state: IRecycleState = initialState, action: Action): IRecycleState => {

  if (isType(action, getRecycleInbox.started)) {
    return {
      ...state,
      isLoadingGetRecycleInbox: true,
      errorGetRecycleInbox: undefined,
      recycleInbox: undefined,
    }
  }

  if (isType(action, getRecycleInbox.done)) {
    const { recycleInbox } = action.payload.result
    let allTypes: any
    if (recycleInbox && recycleInbox.length > 0) {
      allTypes = recycleInbox.map((item: any) => item.type)
    }

    return {
      ...state,
      isLoadingGetRecycleInbox: false,
      errorGetRecycleInbox: undefined,
      recycleInbox,
      types: allTypes
      ? allTypes.filter((type: string, position: number) => allTypes.indexOf(type) === position)
      : state.types,
    }
  }

  if (isType(action, getRecycleInbox.failed)) {
    const { errorMessage } = action.payload.error
    return {
      ...state,
      isLoadingGetRecycleInbox: false,
      errorGetRecycleInbox: errorMessage,
      recycleInbox: undefined,
    }
  }

  return state
}
