import React from "react"

import Icon from "./Icon"

interface IOwnProps {
  searchText?: string,
  setSearchText: (searchText: string) => void,
}

const FeedbackSearch: React.SFC<IOwnProps> = (props) => {

  const { searchText, setSearchText } = props

  return (
    <div className="listing-filters__group">
      <div className="listing-filters__search ml--base">
        <div className="form-group-search">
          <Icon name="loupe" />
          <input
            className="form-control"
            type="search"
            name="search"
            value={searchText}
            placeholder="Type to search"
            onChange={(event) => setSearchText(event.target.value)}
          />
        </div>
      </div>
    </div>
  )
}

export default FeedbackSearch
