import { Action } from "redux"
import { isType } from "typescript-fsa"

import { IUser, IBaseUser } from "./UsersModels"

import {
  login, logout, getCurrentUserInfo, getTeam, toggleEditUser, toggleEditUserDropdown,
  searchTeam, editTeamMember, deleteTeamMember,
} from "./UsersActions"

export interface IUsersState {
  isLoadingLogin: boolean,
  errorLogin?: string,

  isLoadingLogout: boolean,
  errorLogout: boolean,

  isLoadingCurrentUser: boolean,
  errorCurrentUser: boolean,
  currentUser?: IUser,

  isLoadingTeam: boolean,
  errorTeam?: string,
  team?: IBaseUser[],

  editUserFlag: boolean,
  editUserDropdownFlag: boolean,
  userId?: number | null,

  filteredTeam?: IBaseUser[],

  isLoadingEditTeamMember: boolean,
  errorEditTeamMember?: string,
  editTeamMemberResult: boolean,

  isLoadingDeleteTeamMember: boolean,
  errorDeleteTeamMember?: string,
  deleteTeamMemberResult: boolean,
}


export const initialState: IUsersState = {
  isLoadingLogin: false,
  errorLogin: undefined,

  isLoadingLogout: false,
  errorLogout: false,

  isLoadingCurrentUser: true, // In order to show loading gif on refresh/first visit
  errorCurrentUser: false,
  currentUser: undefined,

  isLoadingTeam: false,
  errorTeam: undefined,
  team: undefined,

  editUserFlag: false,
  editUserDropdownFlag: false,
  userId: undefined,

  filteredTeam: undefined,

  isLoadingEditTeamMember: false,
  errorEditTeamMember: undefined,
  editTeamMemberResult: false,

  isLoadingDeleteTeamMember: false,
  errorDeleteTeamMember: undefined,
  deleteTeamMemberResult: false,
}

export default (state: IUsersState = initialState, action: Action): IUsersState => {

  if (isType(action, login.started)) {
    return {
      ...state,
      isLoadingLogin: true,
      errorLogin: undefined,
      currentUser: undefined,
    }
  }

  if (isType(action, login.done)) {
    const { user } = action.payload.result
    return {
      ...state,
      isLoadingLogin: false,
      errorLogin: undefined,
      currentUser: user,
    }
  }

  if (isType(action, login.failed)) {
    const { errorMessage } = action.payload.error
    return {
      ...state,
      isLoadingLogin: false,
      errorLogin: errorMessage,
      currentUser: undefined,
    }
  }

  if (isType(action, logout.started)) {
    return {
      ...state,
      isLoadingLogout: true,
      errorLogout: false,
    }
  }

  if (isType(action, logout.done)) {
    return {
      ...state,
      isLoadingLogout: false,
      errorLogout: false,
      currentUser: undefined,
    }
  }

  if (isType(action, logout.failed)) {
    return {
      ...state,
      isLoadingLogout: false,
      errorLogout: true,
    }
  }

  if (isType(action, getCurrentUserInfo.started)) {
    return {
      ...state,
      isLoadingCurrentUser: true,
      errorCurrentUser: false,
      currentUser: undefined,
    }
  }

  if (isType(action, getCurrentUserInfo.done)) {
    const { currentUser } = action.payload.result
    return {
      ...state,
      isLoadingCurrentUser: false,
      errorCurrentUser: false,
      currentUser,
    }
  }

  if (isType(action, getCurrentUserInfo.failed)) {
    return {
      ...state,
      isLoadingCurrentUser: false,
      errorCurrentUser: true,
      currentUser: undefined,
    }
  }

  if (isType(action, getTeam.started)) {
    return {
      ...state,
      isLoadingTeam: true,
      errorTeam: undefined,
      team: undefined,
    }
  }

  if (isType(action, getTeam.done)) {
    const { team } = action.payload.result
    return {
      ...state,
      isLoadingTeam: false,
      errorTeam: undefined,
      team,
      filteredTeam: team,
    }
  }

  if (isType(action, getTeam.failed)) {
    const { errorMessage } = action.payload.error
    return {
      ...state,
      isLoadingTeam: false,
      errorTeam: errorMessage,
      team: undefined,
    }
  }

  if (isType(action, toggleEditUser)) {
    return {
      ...state,
      editUserFlag: ! state.editUserFlag,
    }
  }

  if (isType(action, toggleEditUserDropdown)) {
    const { payload } = action.payload

    if (! payload) { return state }

    const { userId } = payload

    return {
      ...state,
      editUserDropdownFlag: ! state.editUserDropdownFlag,
      userId,
    }
  }

  if (isType(action, searchTeam)) {
    const { searchText } = action.payload
    const { team } = state

    if (! team) { return state }

    return {
      ...state,
      filteredTeam: team.filter((user) => user.name.toLowerCase().includes(searchText.toLowerCase())),
    }
  }

  if (isType(action, editTeamMember.started)) {
    return {
      ...state,
      isLoadingEditTeamMember: true,
      errorEditTeamMember: undefined,
      editTeamMemberResult: false,
    }
  }

  if (isType(action, editTeamMember.done)) {
    return {
      ...state,
      isLoadingEditTeamMember: false,
      errorEditTeamMember: undefined,
      editTeamMemberResult: true,
    }
  }

  if (isType(action, editTeamMember.failed)) {
    const { errorMessage } = action.payload.error
    return {
      ...state,
      isLoadingEditTeamMember: false,
      errorEditTeamMember: errorMessage,
      editTeamMemberResult: false,
    }
  }

  if (isType(action, deleteTeamMember.started)) {
    return {
      ...state,
      isLoadingDeleteTeamMember: true,
      errorDeleteTeamMember: undefined,
      deleteTeamMemberResult: false,
    }
  }

  if (isType(action, deleteTeamMember.done)) {
    return {
      ...state,
      isLoadingDeleteTeamMember: false,
      errorDeleteTeamMember: undefined,
      deleteTeamMemberResult: true,
    }
  }

  if (isType(action, deleteTeamMember.failed)) {
    const { errorMessage } = action.payload.error
    return {
      ...state,
      isLoadingDeleteTeamMember: false,
      errorDeleteTeamMember: errorMessage,
      deleteTeamMemberResult: false,
    }
  }



  return state
}








