import React from "react"
import { Field } from "redux-form"


const GarmentStatus = () => {
  return (
    <form>
      <div className="form-group">
        <Field
          component="select"
          data-testid="garmentFormStatus"
          className="form-control form-control--pill"
          name="status"
        >
          <option value="-1">Draft</option>
          <option value="1">Active</option>
          <option value="0">Disabled</option>
        </Field>
      </div>
    </form>
  )
}

export default GarmentStatus
