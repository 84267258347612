
import { Prompt } from "react-router"
import React, { useState, useEffect } from "react"
import { Dispatch, Action } from "redux"
import {
  Field, FormSection, reduxForm, InjectedFormProps, formValueSelector, isDirty as reduxFormIsDirty, initialize,
} from "redux-form"
import { Link, RouteComponentProps} from "react-router-dom"
import { connect } from "react-redux"

import BasicInformation from "../components/Forms/BasicInformation"
import UnitInformation from "../components/Forms/UnitInformation"
import CareInstructions from "../components/Forms/CareInstructions"
import Media from "../components/Forms/Media"
import GarmentFormSupplyChain from "../components/Forms/GarmentFormSupplyChain"
import GarmentStatus from "../components/Forms/GarmentStatus"
import GarmentLogInfo from "../components/Forms/GarmentLogInfo"
import Icon from "../components/Icon"
import Loading from "../components/Loading"
import { youtubeVideoUrlParser } from "../utils/utils"

import {

  getGarment, addGarment,
} from "../reducers/GarmentActions"

import { RootState } from "../reducers"
import { IGarment } from "../reducers/GarmentModels"

import { getSupplyChain } from "../reducers/SupplyChainActions"


interface IMatchParams {
  name: string,
  styleNumber: string,
}

interface IStateProps {
  garment?: IGarment,
  loadingGetGarment: boolean,
  errorGetGarment?: string,

  loadingAddGarment: boolean,

  isDirty: boolean,
}

interface IProps extends IStateProps, RouteComponentProps<IMatchParams> {
}

interface IDispatchProps {
  getGarment: (styleNumber: string) => void,
  addGarment: (payload: { formData: FormData, data: IGarment, history: any}) => void,
  getSupplyChain: () => void,
  initializeForm: (data: IGarment) => void,
}

interface IState {
  styleNumber: string,
  name: string,
}


const Garment: React.FunctionComponent<IProps & IDispatchProps & InjectedFormProps<IProps> & IState> = (props: any) => {


  const [styleNumber, setStyleNumber] = useState("")
  const [name, setName] = useState("")

  useEffect(() => {
    props.getSupplyChain()
    document.title = "New Garment"

    window.scrollTo(0, 0)

    onEditGarment(true)
  }, [])

  useEffect(() => {
    if (props.match.params.name) {
      onEditGarment(false)
      document.title = `Editing: ${props.match.params.name}`
    }
  }, [props.location.pathname])

  const onEditGarment = (editingFromCatalogue: boolean) => {

    let { garmentName, garmentStyleNumber } = { garmentName: "", garmentStyleNumber: ""}

    const { params } = props.match
    if (params) {
      const { name: nameFromParams, styleNumber: styleNumberFromParams } = params

      if (nameFromParams && styleNumberFromParams) {
        garmentName = nameFromParams
        garmentStyleNumber = styleNumberFromParams
        setStyleNumber(garmentStyleNumber)
        setName(garmentName)
      }
    }

    if (editingFromCatalogue) {
      if (garmentStyleNumber && garmentName) {
        props.getGarment(garmentStyleNumber)
        props.getSupplyChain()
        document.title = `Editing: ${garmentName}`
      } else {
        props.initializeForm({
          unitInformation: [{ upc: "", color: "", quantity: 0, size: "" }],
          status: -1,
          removedPhotos: {},
        } as any)
      }
    }
  }

  const onAddGarment = (data: IGarment) => {

    if (
      ! data.basicInformation ||
      ! data.basicInformation.garmentName ||
      ! data.basicInformation.styleNumber
      ) {
      alert("You must enter garment name and style number!")
      return
    }

    if (! data.unitInformation) {
      alert("You must enter unit information!")
      return
    } else {
      for (const info of data.unitInformation) {
        const { upc, quantity, color, size } = info
        if (! upc || upc.length === 0) {
          alert("You must enter UPC for the garment.")
          return
        }
        if (! quantity || quantity < 1) {
          alert("You must enter quantity.")
          return
        }
        if (! color || color.length === 0) {
          alert("You must enter color for the garment.")
          return
        }
        if (! size || size.length === 0) {
          alert("You must enter size for the garment.")
          return
        }
      }
    }


    if (! data.careInstructions) {
      alert("You must enter care instructions!")
      return
    }

    const formData = new FormData()
    Object.keys(data).forEach((key) => {
      if (key === "basicInformation") {
        formData.append("basicInformation", JSON.stringify(data[key]))
      } else if (key === "careInstructions") {
        formData.append("careInstructions", JSON.stringify(data[key]))
      } else if (key === "media") {
        const videos: {[color: string]: string | null} = {}
        const existingPhotos: {[color: string]: string[]} = {}

        Object.keys(data[key]).forEach((color) => {
          const photos = data[key][color].photos
          if (photos) {
            Object.keys(data[key][color].photos).forEach((photoName) => {

              const photoObject = data[key][color].photos[photoName]

              if (photoObject instanceof File) {
                formData.append(color, photoObject, photoName)
              } else {
                if (! existingPhotos[color]) { existingPhotos[color] = [] }

                existingPhotos[color].push(photoName)
              }
            })
          }

          const video: string = data[key][color].video as string
          if (video) {
            videos[color] = youtubeVideoUrlParser(video)
          }
        })

        formData.append("existingPhotos", JSON.stringify(existingPhotos))
        formData.append("videos", JSON.stringify(videos))

      } else if (key === "removedPhotos") {
        formData.append("removedPhotos", JSON.stringify(data[key]))
      } else if (key === "unitInformation") {
        formData.append("unitInformation", JSON.stringify(data[key]))
      } else if (key === "supplyChain") {

        const supplyChain: number[] = []

        Object.values(data[key]).forEach(
          (entries) => {
            entries.forEach((entry) => supplyChain.push(entry.id))
            return supplyChain
          },
        )

        formData.append("supplyChain", JSON.stringify(supplyChain))
      }
    })

    formData.append("status", data.status.toString())


    formData.append("edit", (styleNumber !== "") ? "true" : "false")

    const { history } = props
    props.addGarment({ formData, data, history })
  }


  const {
    handleSubmit, loadingGetGarment, errorGetGarment, loadingAddGarment,
    garment, isDirty,
  } = props

  if (isDirty) {
    window.onbeforeunload = () =>
      "Unsaved data will be lost. Are you sure you want to leave this page?"
  }


  return (
    <div className="main__content">
      <div className="container" data-testid="garment">
        <Prompt
          when={isDirty}
          message={(location) => location.pathname.startsWith("/garment")
            ? true
            : "Unsaved data will be lost. Are you sure you want to leave this page?"
          }
        />


        <Link className="link--back" data-testid="garmentBackToCatalogueLink" to="/dashboard">
          <Icon name="arrow-left-rounded" />
          Back to catalogue
        </Link>

        <Loading
          show={loadingGetGarment} text={`Loading ${name}...`}
          imgClass="contain center" divClass="col-sm-12"
        />

        <div className="row">
          <div className="col-sm-8 col-md-8 col-md-offset-1 col-lg-6 col-lg-offset-3">
            <header>
              <h1 data-testid="garmentFormTitle">
                {! name ? "New Garment" :
                  ! errorGetGarment ? `Editing ${name}` : `Unable to edit ${name}`
                }
              </h1>
            </header>
            <form onSubmit={handleSubmit(onAddGarment as any)}>
              <fieldset className="box box--light box--shadowed mb--md">
                <FormSection name="basicInformation">
                  <BasicInformation />
                </FormSection>
                <UnitInformation />
              </fieldset>
              <FormSection name="careInstructions">
                <CareInstructions />
              </FormSection>
              <FormSection name="media">
                <Media />
              </FormSection>
              <FormSection name="supplyChain">
                <GarmentFormSupplyChain />
              </FormSection>

              <Loading show={loadingAddGarment} imgClass="contain center" divClass="col-sm-12" />

              <div className="form-actions">
                {! name && ! loadingAddGarment &&
                  (<div className="form-check">
                    <Field
                      component="input"
                      className="form-check__input"
                      data-testid="garmentSaveAndCreateAnother"
                      type="checkbox"
                      name="saveAndCreateAnother"
                      id="saveAndCreateAnother"
                    />
                    <label
                      className="form-check__label"
                      htmlFor="saveAndCreateAnother"
                    >
                      Save and create another
                    </label>
                  </div>)
                }

                {! errorGetGarment && ! loadingAddGarment &&
                  <button
                    className="button button--primary button--lg"
                    data-testid="garmentSave"
                    type="submit"
                  >
                    Save garment
                  </button>
                }
              </div>
            </form>
          </div>
          <div className="col-sm-3 col-lg-2 col-lg-offset-1">
            <div className="garment-sidebar">
              <GarmentStatus />
              {garment && <GarmentLogInfo logInfo={garment.log} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const selector = formValueSelector("Garment")

const mapStateToProps = (state: RootState): IStateProps => ({
  loadingGetGarment: state.garments.isLoadingGetGarment,
  errorGetGarment: state.garments.errorGetGarment,
  garment: state.garments.garment,

  loadingAddGarment: state.garments.isLoadingAddGarment,

  isDirty: reduxFormIsDirty("Garment")(state),
})

const mapDispatchToProps =
  (dispatch: Dispatch<Action>): IDispatchProps => (
    {
      getGarment: (styleNumber: string) => dispatch(getGarment.started({styleNumber})),
      addGarment: (payload: { formData: FormData, data: IGarment}) => dispatch(addGarment.started({payload})),
      getSupplyChain: () => dispatch(getSupplyChain.started()),
      initializeForm: (data: IGarment) => dispatch(initialize("Garment", data)),
    }
  )


const GarmentConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Garment)


export default reduxForm<IProps>({
  form: "Garment",
  // initialValues: {
  //   "removedPhotos": {},
  // }
})(GarmentConnected as any)
