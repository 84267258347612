import { ISupplyChainTypesDefinition } from "../reducers/SupplyChainModels"

const SUPPLY_CHAIN: ISupplyChainTypesDefinition = {
  1: {
    type: "fabricManufacturers",
    photoPlaceholder: "fabric",
    name: "Fabric Manufacturers",
    shortName: "Fabric Man.",
  },
  2: {
    type: "garmentManufacturers",
    photoPlaceholder: "garment",
    name: "Garment Manufacturers",
    shortName: "Garment Man.",
  },
  3: {
    type: "shippers",
    photoPlaceholder: "shipper",
    name: "Shippers",
    shortName: "Shippers",
  },
  4: {
    type: "retailLocations",
    photoPlaceholder: "retail",
    name: "Retail Locations",
    shortName: "Retail Loc.",
  },
  5: {
    type: "miscellaneous",
    photoPlaceholder: "misc",
    name: "Miscellaneous",
    shortName: "Miscellaneous",
  },
}

export default SUPPLY_CHAIN
