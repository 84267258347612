import { Action } from "redux"
import { isType } from "typescript-fsa"

import {
  getSupplyChain, addSupplyChainEntry, deleteSupplyChainEntry, shouldShowEntryForm,
  showSearch, showDropdown,
} from "./SupplyChainActions"

import { ISupplyChainEntry } from "./SupplyChainModels"

export interface ISupplyChainState {
  isLoadingGetSupplyChain: boolean,
  errorGetSupplyChain?: string,
  entries?: ISupplyChainEntry[],

  entryId?: number | null,
  showEntryFormFlag: boolean,

  isLoadingAddSupplyChainEntry: boolean,
  errorAddSupplyChainEntry?: string,
  addSupplyChainEntryResult: boolean,

  isLoadingDeleteSupplyChainEntry: boolean,
  errorDeleteSupplyChainEntry?: string,
  deleteSupplyChainEntryResult: boolean,

  showSearchFlag: boolean,

  dropdownFlag: boolean
}

export const initialState: ISupplyChainState = {
  isLoadingGetSupplyChain: false,
  errorGetSupplyChain: undefined,
  entries: undefined,

  entryId: undefined,
  showEntryFormFlag: false,

  isLoadingAddSupplyChainEntry: false,
  errorAddSupplyChainEntry: undefined,
  addSupplyChainEntryResult: false,

  isLoadingDeleteSupplyChainEntry: false,
  errorDeleteSupplyChainEntry: undefined,
  deleteSupplyChainEntryResult: false,

  showSearchFlag: false,

  dropdownFlag: false,
}

export default (state: ISupplyChainState = initialState, action: Action): ISupplyChainState => {

  if (isType(action, getSupplyChain.started)) {
    return {
      ...state,
      isLoadingGetSupplyChain: true,
      errorGetSupplyChain: undefined,
      entries: undefined,
    }
  }

  if (isType(action, getSupplyChain.done)) {
    const { entries } = action.payload.result
    return {
      ...state,
      isLoadingGetSupplyChain: false,
      errorGetSupplyChain: undefined,
      entries,
    }
  }

  if (isType(action, getSupplyChain.failed)) {
    const { errorMessage } = action.payload.error
    return {
      ...state,
      isLoadingGetSupplyChain: false,
      errorGetSupplyChain: errorMessage,
      entries: undefined,
    }
  }

  if (isType(action, shouldShowEntryForm)) {
    const { showEntryFormFlag } = action.payload
    return {
      ...state,
      showEntryFormFlag,
    }
  }

  if (isType(action, addSupplyChainEntry.started)) {
    return {
      ...state,
      isLoadingAddSupplyChainEntry: true,
      errorAddSupplyChainEntry: undefined,
      addSupplyChainEntryResult: false,
    }
  }

  if (isType(action, addSupplyChainEntry.done)) {
    return {
      ...state,
      isLoadingAddSupplyChainEntry: false,
      errorAddSupplyChainEntry: undefined,
      addSupplyChainEntryResult: true,
    }
  }

  if (isType(action, addSupplyChainEntry.failed)) {
    const { errorMessage } = action.payload.error
    return {
      ...state,
      isLoadingAddSupplyChainEntry: false,
      errorAddSupplyChainEntry: errorMessage,
      addSupplyChainEntryResult: false,
    }
  }

  if (isType(action, deleteSupplyChainEntry.started)) {
    return {
      ...state,
      isLoadingDeleteSupplyChainEntry: true,
      errorDeleteSupplyChainEntry: undefined,
      deleteSupplyChainEntryResult: false,
    }
  }

  if (isType(action, deleteSupplyChainEntry.done)) {
    return {
      ...state,
      isLoadingDeleteSupplyChainEntry: false,
      errorDeleteSupplyChainEntry: undefined,
      deleteSupplyChainEntryResult: true,
    }
  }

  if (isType(action, deleteSupplyChainEntry.failed)) {
    const { errorMessage } = action.payload.error
    return {
      ...state,
      isLoadingDeleteSupplyChainEntry: false,
      errorDeleteSupplyChainEntry: errorMessage,
      deleteSupplyChainEntryResult: false,
    }
  }

  if (isType(action, showSearch)) {
    const { newShowSearchFlag } = action.payload
    let { showSearchFlag } = state

    if (newShowSearchFlag !== false) {
      showSearchFlag = ! showSearchFlag
    } else {
      showSearchFlag = newShowSearchFlag
    }
    return {
      ...state,
      showSearchFlag,
    }
  }

  if (isType(action, showDropdown)) {
    const { payload } = action.payload

    if (! payload) { return state }

    const { id, dropdownFlag } = payload
    return {
      ...state,
      entryId: id,
      dropdownFlag,
    }
  }

  return state
}







