import { combineReducers } from "redux"
import { reducer as formReducer } from "redux-form"

import UsersReducers, { IUsersState } from "./UsersReducers"
import GarmentsReducers, { IGarmentState } from "./GarmentReducers"
import SupplyChainReducers, { ISupplyChainState } from "./SupplyChainReducers"
import RecyleReducers, { IRecycleState } from "./RecycleReducers"
import SettingsReducers, { ISettingsState } from "./SettingsReducers"
import FeedbackReducers, { IFeedbackState } from "./FeedbackReducers"
import GeneralReducers, { IGeneralState } from "./GeneralReducers"

const rootReducer = combineReducers({
  users: UsersReducers,
  garments: GarmentsReducers,
  supplyChain: SupplyChainReducers,
  recycle: RecyleReducers,
  settings: SettingsReducers,
  feedback: FeedbackReducers,
  general: GeneralReducers,

  form: formReducer,
})


export type RootState = {
  users: IUsersState,
  garments: IGarmentState,
  supplyChain: ISupplyChainState,
  recycle: IRecycleState,
  settings: ISettingsState,
  feedback: IFeedbackState,
  general: IGeneralState,
}

export default rootReducer
