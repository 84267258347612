import React from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"

import Icon from "./Icon"
import { copyToClipboard } from "../utils/utils"
import placeholder from "../images/garment-placeholder.svg"

import { notify } from "../reducers/GeneralActions"
import { RootState } from "../reducers"
import { IShortGarment } from "../reducers/GarmentModels"
import { IUser } from "../reducers/UsersModels"



interface IOwnProps {
  garment: IShortGarment,
  index: number,
}

interface IStateProps {
  user?: IUser,
}

interface IDispatchProps {
  notifyStyleNumberCopied: (message: string) => void,
}

const CatalogueListItem: React.FunctionComponent<IOwnProps & IStateProps & IDispatchProps> = (props) => {

  const { user, garment, index, notifyStyleNumberCopied } = props

  if (!garment) {
    return null
  }

  const { name, base_photo, style_number, type, line } = garment

  const brand = user ? user.brand_name : ""

  return (
    <article data-testid="catalogueListViewGarmentItem" className="list-item list-item--product">
      <figure>
        <Link className="block" to={`/garment/${name}/${style_number}`}>
          <img
            className="contain center"
            data-testid="catalogueListViewGarmentPhoto"
            src={base_photo ? base_photo : placeholder}
            alt=""
          />
        </Link>
      </figure>
      <section className="list-item__details">
        <div>
          <Link className="link--ui" to={`/garment/${name}/${style_number}`}>
            <h3 className="mb--none" data-testid="catalogueListViewGarmentName">{name}</h3>
          </Link>
          <small className="text--quiet upcase" data-testid="catalogueListViewGarmentBrand" >{brand}</small>
        </div>
        <small data-testid="catalogueListViewGarmentType">{type}</small>
        <small data-testid="catalogueListViewGarmentLine">{line}</small>
        <small>
          <div id={`styleNumber${index}`} className="inline-block" data-testid="catalogueListViewGarmentStyleNumber">
            {style_number}
          </div>
          <Link
            className="inline-block ml--xs valign-middle"
            data-testid="catalogueListViewCopyStyleNumber"
            to="#"
            onClick={() => copyToClipboard(index, (message) => notifyStyleNumberCopied(message))}
          >
            <Icon name="copy" />
          </Link>
        </small>
      </section>
      {/* <aside className="list-item__more">
        <div className="dropdown txtr">
          <Link
            className="dropdown__toggle text--quiet"
            to="#"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <Icon name="dots-ver" />
          </Link>
          <ul className="dropdown-menu dropdown-menu--right">
            {[0, 1, 2, 3].map(i => (
              <li key={i}>
                <a className="dropdown-menu__item" href="#">
                  Dropdown link
                </a>
              </li>
            ))}
          </ul>
        </div>
      </aside> */}
    </article>
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  user: state.users.currentUser,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    notifyStyleNumberCopied: (message: string) => dispatch(notify("success", message, true)),
  }
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CatalogueListItem)
