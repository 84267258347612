import React from "react"

import { ILogEntry } from "../../reducers/GarmentModels"
import { dateFormat, timeFormat } from "../../utils/utils"

interface IOwnProps {
  logInfo: ILogEntry[]
}

const GarmentLogInfo: React.FunctionComponent<IOwnProps> = (props) => {

  const { logInfo } = props
  if (! logInfo || logInfo.length === 0) {
    return null
  }
  const logCreated = logInfo[logInfo.length - 1]
  const logLastChanged = logInfo[0]


  const dateCreated = dateFormat(new Date(logCreated.done_at))
  const dateLastChanged = `${
    dateFormat(new Date(logLastChanged.done_at))} ${timeFormat(new Date(logLastChanged.done_at))
  }`

  return (
    <ul className="list-unstyled list-spaced" data-testid="garmentLogInfo">
      <li>
        <div className="upcase text--quiet">Created by</div>
        <small data-testid="garmentLogInfoCreatedName">{logCreated.name}</small>
      </li>
      <li>
        <div className="upcase text--quiet">Date Created</div>
        <small data-testid="garmentLogInfoCreatedDate">{dateCreated}</small>
      </li>
      <li>
        <div className="upcase text--quiet">Last change</div>
        <small data-testid="garmentLogInfoChanged">
          {dateLastChanged}
          <br />
          {logLastChanged.name}
        </small>
      </li>
    </ul>
  )
}

export default GarmentLogInfo
