import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { changeFilters } from "../reducers/GarmentActions"
import { IFilters, FiltersOrderBy } from "../reducers/GarmentModels"
import { RootState } from "../reducers"
import { Dispatch, Action } from "redux"


interface IStateProps {
  order: FiltersOrderBy,
  types?: string[],
  lines?: string[],
  type: string,
  line: string,
  status: string,
  searchText: string,
}

interface IDispatchProps {
  onChangeFilters: (filters: IFilters) => void
}

const CatalogueFilters: React.FunctionComponent<IStateProps & IDispatchProps> = (props) => {


  const {
    order, types, type, lines, line, status, onChangeFilters, searchText,
  } = props

  return (
    <div className="listing-filters__options">
      <div className="form-group">
        <label className="form-control-label is-static" htmlFor="order">
          Sort
        </label>
        <select
          value={order}
          className="form-control"
          data-testid = "catalogueOrderFilter"
          id="order"
          onChange={(event) => onChangeFilters({ order: event.target.value, searchText } as IFilters)}
        >
          <option value="name asc">Name ascending</option>
          <option value="name desc">Name descending</option>
          {/* <option value="date asc">Date ascending</option>
          <option value="date desc">Date descending</option> */}
        </select>
      </div>
      <div className="form-group">
        <label className="form-control-label is-static" htmlFor="type">
          Type
        </label>
        <select
          className="form-control"
          data-testid = "catalogueTypeFilter"
          id="type"
          value={type}
          onChange={(event) => onChangeFilters({ chosenType: event.target.value, searchText } as IFilters)}
        >
          <option value={"all"}>All</option>
          {types &&
            types.map((oneType) => (
              <option key={oneType} value={oneType}>
                {oneType}
              </option>
            ))}
        </select>
      </div>
      <div className="form-group">
        <label className="form-control-label is-static" htmlFor="line">
          Line
        </label>
        <select
          className="form-control"
          data-testid = "catalogueLineFilter"
          id="line"
          value={line}
          onChange={(event) => onChangeFilters({ line: event.target.value, searchText } as IFilters)}
        >
          <option value={"all"}>All</option>
          {lines &&
            lines.map((oneLine) => (
              <option key={oneLine} value={oneLine}>
                {oneLine}
              </option>
            ))}
        </select>
      </div>
      <div className="form-group">
        <label className="form-control-label is-static" htmlFor="status">
          Status
        </label>
        <select
          className="form-control"
          data-testid = "catalogueStatusFilter"
          id="status"
          value={status}
          onChange={(event) => onChangeFilters({ status: event.target.value, searchText } as IFilters)}
        >
          <option value="all">All</option>
          <option value="-1">Draft</option>
          <option value="1">Active</option>
          <option value="0">Disabled</option>
        </select>
      </div>
    </div>
  )
}


const mapStateToProps = (state: RootState): IStateProps => ({
  types: state.garments.types,
  lines: state.garments.lines,
  order: state.garments.filters.order,
  type: state.garments.filters.chosenType,
  line: state.garments.filters.line,
  status: state.garments.filters.status,
  searchText: state.garments.filters.searchText,
})

const mapDispatchToProps =
  (dispatch: Dispatch<Action>): IDispatchProps => (
    {
      onChangeFilters: (filters: IFilters) => dispatch(changeFilters({ filters })),
    }
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CatalogueFilters)

