import actionCreatorFactory from "typescript-fsa"

import { IRecycledGarment } from "./RecycleModels"

const actionCreator = actionCreatorFactory("Garment")


export const getRecycleInbox =
  actionCreator.async<  void,
                      { recycleInbox: IRecycledGarment[] },
                      { errorMessage: string }
                     >("GET_RECYCLE_INBOX")

export const markGarmentAsReceived =
  actionCreator.async<{ garmentCode: string },
                      void,
                      { errorMessage: string }
                      >("MARK_GARMENT_AS_RECEIVED")

export const markGarmentAsNotReceived =
  actionCreator.async<{ garmentCode: string },
                    void,
                    { errorMessage: string }
                    >("MARK_GARMENT_AS_NOT_RECEIVED")
