export function youtubeVideoUrlParser(video: string): string | null {
  const regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
  const match = video.match(regExp)
  return match && match[1].length === 11 ? match[1] : null
}

export function dateFormat(fullDate: any) {

  return `${fullDate.getFullYear()}-${fullDate.getMonth() + 1 < 10 ?
    `0${fullDate.getMonth() + 1}` : fullDate.getMonth() + 1}-${fullDate.getDate() < 10 ?
      `0${fullDate.getDate()}` : fullDate.getDate()}`

}

export function timeFormat(fullDate: any) {
  if (fullDate.getHours()) {
    return `${fullDate.getHours() < 10 ?
      `0${fullDate.getHours()}` : fullDate.getHours()}:${fullDate.getMinutes() < 10 ?
        `0${fullDate.getMinutes()}` : fullDate.getMinutes()}`
  } else {
    return ""
  }
}

export function copyToClipboard(index: number, notifyStyleNumberCopied: (message: string) => void) {
  const inp = document.createElement("input")
  document.body.appendChild(inp)
  const element = document.getElementById(`styleNumber${index}`)
  let styleNumber = ""
  if (element && element.textContent) {
    styleNumber = element.textContent
  }

  if (styleNumber.length > 0) {
    inp.value = styleNumber
    inp.select()
    document.execCommand("copy")
    inp.remove()

    notifyStyleNumberCopied(`Copied style number: ${styleNumber}.`)
  }
}

export function filterGarments(garments: any, allFilters: any) {

  return garments.filter((item: any) => Object.keys(allFilters).every(
    (filter) =>
      (item as any)[filter] &&
      (item as any)[filter].toString().toLowerCase() === (allFilters as any)[filter].toString().toLowerCase()
      || (item as any)[filter] === 0 && `${(item as any)[filter]}` === (allFilters as any)[filter]
      || (allFilters as any)[filter] === "all",
  ))
}

export function sortAndSearchGarments(garments: any, searchText: string, order: string) {
  return garments.filter(
    (garment: any) => {

      if (garment.name) {

        return (garment.name.toLowerCase().includes(searchText && searchText.toLowerCase()) ||
          garment.style_number.toLowerCase().includes(searchText && searchText.toLowerCase())
        )
      } else if (garment.garment_name) {
        return (garment.garment_name.toLowerCase().includes(searchText && searchText.toLowerCase()) ||
          garment.style_number.toLowerCase().includes(searchText && searchText.toLowerCase())
        )
      }
    })
    .sort((a: any, b: any) => {
      const nameA = a.name ? a.name.toUpperCase() : a.garment_name.toUpperCase()
      const nameB = b.name ? b.name.toUpperCase() : b.garment_name.toUpperCase()
      if (order === "name asc") {
        if (nameA < nameB) { return -1 }
        if (nameA > nameB) { return 1 }
      } else if (order === "name desc") {
        if (nameA > nameB) { return -1 }
        if (nameA < nameB) { return 1 }
      }
      return 0
    })
}

export function removeDuplicates(array: string[]) {
  return array.filter((type, position) => type && array.indexOf(type) === position)
}
