import React, { useEffect } from "react"
import { Redirect } from "react-router"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"

import { RootState } from "../reducers"

import { logout } from "../reducers/UsersActions"
import { IUser } from "../reducers/UsersModels"


interface IStateProps {
  currentUser?: IUser,
  loading: boolean,
  error: boolean,
}

interface IDispatchProps {
  logout: () => void,
}

const Logout: React.FunctionComponent<IStateProps & IDispatchProps> = (props) => {

  useEffect(() => {
    if (currentUser) {
      props.logout()
    }
  }, [])

  const { currentUser, loading, error } = props
  return (
    <div className="auth-canvas">
      <div className="auth-content">
        <header>
          <h1 className="auth-content__title">
            {loading && "Logging you out..."}
            {error && "An error happened while trying to log you out, pleas try again later."}
            {! currentUser && (<Redirect to="/login" />)}
          </h1>
        </header>
      </div>
    </div>
  )
}


const mapStateToProps = (state: RootState): IStateProps => ({
  currentUser: state.users.currentUser,
  loading: state.users.isLoadingLogout,
  error: state.users.errorLogout,
})

const mapDispatchToProps =
  (dispatch: Dispatch<Action>): IDispatchProps => (
    {
      logout: () => dispatch(logout.started()),
    }
  )

export default withRouter(
  connect(
  mapStateToProps,
  mapDispatchToProps,
)(Logout) as any)
