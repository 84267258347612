import React from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router"
import { Link, NavLink, withRouter } from "react-router-dom"

import Icon from "../components/Icon"
import Logo from "../images/logo.svg"

import { RootState } from "../reducers"
import { IUser } from "../reducers/UsersModels"

interface IStateProps {
  user?: IUser,
}


const Navigation: React.FunctionComponent<IStateProps> = (props) => {

  const { user } = props

  if (!user) {
    return (<Redirect to="/login" />)
  }
  const { name, brand_logo, account_type_id } = user

  const toggleNav = () => {
    const nav = document.getElementById("navigation")
    if (nav) {
      if (nav.classList.contains("show")) {
        nav.classList.remove("show")
      } else {
        nav.classList.add("show")
      }
    }
  }

  const hideDrawer = () => {
    const navigationElement = document.getElementById("navigation")
    if (navigationElement) {
      navigationElement.classList.remove("show")
    }
  }

  const newGarmentClicked = () => {
    toggleNav()
  }

  return (
    <React.Fragment>
      <nav className="navigation" id="navigation">
        <div className="navigation__content">
          <div>

            <div className="navigation__block">
              <Link data-testid="logout" className="link--quiet right" to="/logout">
                <Icon name="off" />
              </Link>
              {name}
              <br />
              {account_type_id === 1 && <span className="text--quiet upcase regular">member</span>}
              {account_type_id === 2 && <span className="text--quiet upcase regular">admin</span>}
            </div>

            <Link
              className="button button--primary button--block button--square txtc"
              data-testid="garmentNavigationLink"
              to="garment"
              onClick={() => newGarmentClicked()}
            >
              <Icon name="plus-rounded" className="icon valign-middle mr--xs" />
              New garment
            </Link>

            <div className="navigation__block txtc">
              {/* <p className="mb--xs">
                Have a lot of stuff to add?
              </p>
              <a href="">
                Import from CSV
              </a> */}
            </div>

            <ul className="navigation__menu">
              <li>
                <NavLink
                  className="navigation__menu__item"
                  data-testid="catalogueNavigationLink"
                  activeClassName="active"
                  to="/catalogue"
                  onClick={hideDrawer}
                >
                  <Icon name="grid" />
                  Catalogue
                </NavLink>
              </li>
              <li>
                <NavLink
                  data-testid="teamNavigationLink"
                  className="navigation__menu__item"
                  activeClassName="active"
                  to="/team"
                  onClick={() => hideDrawer()}
                >
                  <Icon name="team" />
                  Team
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="navigation__menu__item"
                  data-testid="supplyChainNavigationLink"
                  activeClassName="active"
                  to="/supply-chain"
                  onClick={() => hideDrawer()}
                >
                  <Icon name="puzzle" />
                  Supply chain
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="navigation__menu__item"
                  activeClassName="active"
                  to="/recycling-inbox"
                  onClick={() => hideDrawer()}
                >
                  <Icon name="recycling" />
                  Recycling inbox
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="navigation__menu__item"
                  activeClassName="active"
                  to="/feedback"
                  onClick={() => hideDrawer()}
                >
                  <Icon name="feedback" />
                  Feedback
                </NavLink>
              </li>
               <li>
                <NavLink
                  className="navigation__menu__item"
                  activeClassName="active"
                  to="/settings"
                  onClick={hideDrawer}
                >
                  <Icon name="cog" />
                  Settings
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="navigation__block navigation__footer">
            <figure className="mb--base">
              <img className="contain center" src={brand_logo} alt="" />
            </figure>
            <figure>
              <img src={Logo} alt="" width="100" />
            </figure>
          </div>

        </div>
      </nav>
      <a className="navigation-toggle" href="#navigation" data-toggle="drawer" onClick={toggleNav}>
        <span>Menu</span>
      </a>
    </React.Fragment>
  )
}


const mapStateToProps = (state: RootState): IStateProps => ({
  user: state.users.currentUser,
})



export default withRouter(
  connect(
    mapStateToProps,
    null,
  )(Navigation) as any,
)
