import { Action } from "redux"
import { isType } from "typescript-fsa"

import {
  getGarments, getGarment, addGarment, changeFilters,
  showListView, chooseColor, chooseSupplyChainTypeId, showDropdown,
} from "./GarmentActions"

import { IGarment, IShortGarment, FiltersOrderBy } from "./GarmentModels"
import { filterGarments, sortAndSearchGarments, removeDuplicates } from "../utils/utils"

export interface IGarmentState {
  isLoading: boolean,
  error?: string,
  garments?: IShortGarment[],

  isLoadingGetGarment: boolean,
  errorGetGarment?: string,
  garment?: IGarment,

  isLoadingAddGarment: boolean,
  errorAddGarment?: string,
  addGarmentResult?: boolean,

  showListViewFlag: boolean,

  color?: string,

  filters: {
    order: FiltersOrderBy,
    chosenType: string,
    line: string,
    status: string,
    searchText: string,
  }

  types?: string[],
  lines?: string[],
  filteredGarments?: IShortGarment[],

  supplyChainTypeId: number,
  dropdownFlag: boolean,
  entryId?: number,
}

export const initialState: IGarmentState = {
  isLoading: false,
  error: undefined,
  garments: undefined,

  isLoadingGetGarment: false,
  errorGetGarment: undefined,
  garment: {} as IGarment,

  isLoadingAddGarment: false,
  errorAddGarment: undefined,
  addGarmentResult: undefined,

  showListViewFlag: false,

  color: undefined,

  filters: {
    order: "name asc",
    chosenType: "all",
    line: "all",
    status: "all",
    searchText: "",
  },

  types: undefined,
  lines: undefined,
  filteredGarments: undefined,

  supplyChainTypeId: 1,
  dropdownFlag: false,
  entryId: undefined,
}

export default (state: IGarmentState = initialState, action: Action): IGarmentState => {

  if (isType(action, getGarments.started)) {
    return {
      ...state,
      isLoading: true,
      error: undefined,
      garments: undefined,
    }
  }

  if (isType(action, getGarments.done)) {
    const { garments } = action.payload.result
    const allTypes = garments.map((garment: IShortGarment) => garment.type)
    const allLines = garments.map((garment: IShortGarment) => garment.line)
    return {
      ...state,
      isLoading: false,
      error: undefined,
      garments,
      filteredGarments: garments,
      types: removeDuplicates(allTypes),
      lines: removeDuplicates(allLines),
    }
  }

  if (isType(action, getGarments.failed)) {
    const { errorMessage } = action.payload.error
    return {
      ...state,
      isLoading: false,
      error: errorMessage,
      garments: undefined,
    }
  }

  if (isType(action, getGarment.started)) {
    return {
      ...state,
      isLoadingGetGarment: true,
      errorGetGarment: undefined,
      garment: undefined,
    }
  }

  if (isType(action, getGarment.done)) {
    const { garment } = action.payload.result
    return {
      ...state,
      isLoadingGetGarment: false,
      errorGetGarment: undefined,
      garment,
    }
  }

  if (isType(action, getGarment.failed)) {
    const { errorMessage } = action.payload.error
    return {
      ...state,
      isLoadingGetGarment: false,
      errorGetGarment: errorMessage,
      garment: undefined,
    }
  }

  if (isType(action, addGarment.started)) {
    return {
      ...state,
      isLoadingAddGarment: true,
      errorAddGarment: undefined,
      addGarmentResult: undefined,
    }
  }

  if (isType(action, addGarment.done)) {
    return {
      ...state,
      isLoadingAddGarment: false,
      errorAddGarment: undefined,
      addGarmentResult: true,
    }
  }

  if (isType(action, addGarment.failed)) {
    const { errorMessage } = action.payload.error
    return {
      ...state,
      isLoadingAddGarment: false,
      errorAddGarment: errorMessage,
      addGarmentResult: undefined,
    }
  }

  if (isType(action, addGarment.failed)) {
    return {
      ...state,
      isLoadingAddGarment: false,
      errorAddGarment: undefined,
      addGarmentResult: undefined,
      supplyChainTypeId: 1,
      dropdownFlag: false,
    }
  }

  if (isType(action, showListView)) {
    const { showListViewFlag } = action.payload
    return {
      ...state,
      showListViewFlag,
    }
  }

  if (isType(action, chooseColor)) {
    const { color } = action.payload
    return {
      ...state,
      color,
    }
  }

  if (isType(action, chooseSupplyChainTypeId)) {
    const { supplyChainTypeId } = action.payload
    return {
      ...state,
      supplyChainTypeId,
    }
  }

  if (isType(action, changeFilters)) {
    let { filters: { order, chosenType, line, status, searchText }} = action.payload
    const { filters: stateFilters } = state

    if (! order) { order = stateFilters.order }
    if (! chosenType) { chosenType = stateFilters.chosenType }
    if (! line) { line = stateFilters.line}
    if (! status) { status = stateFilters.status }
    if (! searchText) { searchText = initialState.filters.searchText }

    let selectedGarments: IShortGarment[] = []
    const { garments: allGarments } = state

    if (allGarments) {
      const allFilters = { type: chosenType, line, status }
      selectedGarments = filterGarments(allGarments, allFilters)
    }
    let filteredGarments: IShortGarment[]

    filteredGarments = sortAndSearchGarments(selectedGarments, searchText, order)

    return {
      ...state,
      filters: {
        chosenType,
        line,
        status,
        searchText,
        order,
      },
      filteredGarments,
    }
  }

  if (isType(action, showDropdown)) {
    const { payload } = action.payload

    if (! payload) { return state }

    const { dropdownFlag, entryId } = payload
    return {
      ...state,
      dropdownFlag,
      entryId,
    }
  }


  return state
}



