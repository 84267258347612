import request, { generatePutOptionsForFileUpload } from "../utils/request"
import { call, put, takeLatest } from "redux-saga/effects"
import { SagaIterator } from "redux-saga"
import { initialize } from "redux-form"

import {
  getGarments, getGarment, addGarment, changeFilters,
} from "../reducers/GarmentActions"

import { notify } from "../reducers/GeneralActions"

import { IFilters } from "../reducers/GarmentModels"

const defaultFilters: IFilters = {
  order: "name asc",
  chosenType: "all",
  line: "all",
  status: "all",
  searchText: "",
}


/* ************************************************************* */
function * getGarmentsSaga() {

  try {
    const result = yield call(request, "/garments/brandGarments")

    yield put(getGarments.done({ result }))
    yield put(changeFilters({ filters: defaultFilters}))
  } catch (err) {
    console.log(err)
    const { message } = err
    yield put(getGarments.failed({ error: { errorMessage: message }}))
    yield put(notify("error", "Unable to get garments."))

  }
}

export function * watchGetGarments(): SagaIterator {
  yield takeLatest(getGarments.started, getGarmentsSaga)
}
/* ************************************************************* */

/* ************************************************************* */
function * getGarmentSaga(action: any) {

  const { styleNumber } = action.payload

  try {
    const result = yield call(request, `/garments/garment/${styleNumber}`)

    yield put(getGarment.done({ params: { styleNumber }, result }))

    const { garment } = result
    yield put(initialize("Garment", {...garment}))

  } catch (err) {
    console.log(err)
    const { message } = err
    yield put(getGarment.failed({ params: { styleNumber }, error: { errorMessage: message }}))
    yield put(notify("error", "Error while getting garment."))

  }
}

export function * watchGetGarment(): SagaIterator {
  yield takeLatest(getGarment.started, getGarmentSaga)
}
/* ************************************************************* */

/* ************************************************************* */
function * addGarmentSaga(action: any) {
  const { payload } = action.payload

  if (! payload) { return }

  const { formData, data, history } = payload
  try {

    const result = yield call(request, "/garments/garment", generatePutOptionsForFileUpload(formData))

    yield put(addGarment.done({ params: { payload }, result }))
    yield put(notify("success", "The garment was saved successfully."))

    // Used for save and create functionality + to update form data on save
    const { saveAndCreateAnother, edit, basicInformation } = data
    const { message } = result

    if (saveAndCreateAnother) {
      yield put( initialize("Garment", {
        unitInformation: [{ upc: "", color: "", quantity: null, size: "" }],
        status: -1,
        removedPhotos: {},
        saveAndCreate: true,
      }))
    } else if (edit !== true) {
      const { garmentId } = message
      const { styleNumber, garmentName } = basicInformation
      yield put(getGarment.started({ styleNumber }))
      history.push(`/garment/${garmentName}/${styleNumber}`)
      yield put( initialize("Garment", {...data, edit: true, basicInformation: {...basicInformation, garmentId} }))
    }
  } catch (err) {
    console.log(err)
    const { message } = err
    yield put(addGarment.failed({ params: { payload }, error: { errorMessage: message } }))
    yield put(notify("error", "Error while saving garment."))

  }
}

export function * watchGetAddGarment(): SagaIterator {
  yield takeLatest(addGarment.started, addGarmentSaga)
}
/* ************************************************************* */
