import React from "react"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { initialize } from "redux-form"
import { Dispatch, Action } from "redux"

import Icon from "./Icon"
import Dropdown from "./Dropdown"

import {
  shouldShowEntryForm, showDropdown, deleteSupplyChainEntry,
} from "../reducers/SupplyChainActions"

import { RootState } from "../reducers"
import { ISupplyChainEntry, ISupplyChainDropdownMenuControls } from "../reducers/SupplyChainModels"


interface IOwnProps {
  photoPlaceholder: string,
  entriesForType: ISupplyChainEntry[],
}

interface IStateProps {
  entryId?: number | null,
  dropdownFlag: boolean,
}

interface IDispatchProps {
  onShouldShowEntryForm: (showEntryFormFlag: boolean) => void,
  initializeForm: (data: ISupplyChainEntry) => void,
  onShowDropdown: (payload: ISupplyChainDropdownMenuControls) => void,
  onDeleteSupplyChainEntry: (id: number) => void,
}

const SupplyChainList: React.FunctionComponent<IOwnProps & IStateProps & IDispatchProps> = (props) => {

  const {
    photoPlaceholder, entriesForType, entryId, onShowDropdown, dropdownFlag,
    onShouldShowEntryForm, onDeleteSupplyChainEntry,
  } = props

  const editEntry = (entry: ISupplyChainEntry, dropdownList: boolean) => {

    onShouldShowEntryForm(true)
    props.initializeForm(entry)
    props.onShowDropdown({ id: null, dropdownFlag: false })

    if (dropdownList) {
      onShowDropdown({ id: entry.id, dropdownFlag: false})
    }

  }

  const deleteEntry = (id: number) => {

    onDeleteSupplyChainEntry(id)
    onShowDropdown({ id, dropdownFlag: false})

  }

  return (
    <div className="content-column__body">
      {entriesForType.map((entry: ISupplyChainEntry) => {
        const { name, address, photo, id } = entry
        let photoPreview: string | undefined

        if (photo) {
          if (typeof(photo) === "string") {
            photoPreview = photo
          } else {
            photoPreview = photo.preview
          }
        }

        return (
          <article className="list-item--supply-chain" key={id} data-testid={`supplyChainSupplier${name}`}>
            <Link className="list-item" to="#" onClick={() => editEntry(entry, false)}>
              <figure className="list-item__figure">
                {! photo && <Icon name={photoPlaceholder} />}
                {photo && (
                  <img
                    className="contain center"
                    data-testid="supplyChainSupplierPhoto"
                    src={photoPreview}
                    alt={name}
                    width="90"
                  />
                )}
              </figure>
              <section className="list-item__details">
                <h3 className="mb--none text--xs" data-testid="supplyChainSupplierName">{name}</h3>
                <span className="text--quiet" data-testid="supplyChainSupplierAddress">
                  <Icon className="icon valign-middle" name="location" />
                  {address}
                </span>
              </section>
            </Link>


            <Dropdown
              onShowDropdown={
                () => dropdownFlag ?
                onShowDropdown({ id: null, dropdownFlag: false}) : onShowDropdown({ id, dropdownFlag: true})
              }
              onHideDropdown={() => onShowDropdown({ id: null, dropdownFlag: false})}
              showCondition={dropdownFlag && entryId === id}
              dropdownItems={[
                {name: "Edit supplier", action: () => editEntry(entry, true)},
                {name: "Delete supplier", action: () => deleteEntry(id)},
              ]}
            />

          </article>
        )
      })}
    </div>
  )
}
const mapStateToProps = (state: RootState): IStateProps => ({
  dropdownFlag: state.supplyChain.dropdownFlag,
  entryId: state.supplyChain.entryId,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    onShouldShowEntryForm: (showEntryFormFlag: boolean) => dispatch(shouldShowEntryForm({ showEntryFormFlag })),
    initializeForm: (data: ISupplyChainEntry) => dispatch(initialize("SupplyChainEntryForm", data)),
    onShowDropdown: (payload: ISupplyChainDropdownMenuControls) => dispatch(showDropdown({ payload })),
    onDeleteSupplyChainEntry: (id: number) => dispatch(deleteSupplyChainEntry.started({ id })),
  }
)

const wr = withRouter(SupplyChainList as any)

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(wr) as any

