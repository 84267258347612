import React from "react"
import { Field, formValueSelector, change } from "redux-form"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"
import { Link } from "react-router-dom"

import GarmentFormSupplyChainListEntry from "./GarmentFormSupplyChainListEntry"
import { chooseSupplyChainTypeId } from "../../reducers/GarmentActions"

import SUPPLY_CHAIN from "../../utils/supplyChainObject"
import { ISupplyChainEntry, ISupplyChainEntryArrayObject } from "../../reducers/SupplyChainModels"

import { RootState } from "../../reducers"


interface IStateProps {
  entries?: ISupplyChainEntry[],
  chosenTypeId: number,
  searchText: string,
  selectedEntriesForSupplyChainType: ISupplyChainEntry[],
}

interface IDispatchProps {
  onChooseSupplyChainTypeId: (id: number) => void,
  updateConnectedEntriesForType: (id: number, data: ISupplyChainEntry[]) => void,
  emptySearchField: () => void,
}


const GarmentFormSupplyChain: React.FunctionComponent<IStateProps & IDispatchProps> = (props) => {

  const {
    entries, chosenTypeId, searchText, onChooseSupplyChainTypeId,
    updateConnectedEntriesForType, emptySearchField,
  } = props

  let { selectedEntriesForSupplyChainType } = props

  const entriesForType: ISupplyChainEntryArrayObject = {}
  if (entries) {
    entries.map((entry: ISupplyChainEntry) => {
      if (! entriesForType[entry.type_id]) { entriesForType[entry.type_id] = [] }

      entriesForType[entry.type_id].push(entry)
    })
  }

  let filteredEntries: ISupplyChainEntry[] = []

  if (entriesForType[chosenTypeId] && searchText) {
    filteredEntries = entriesForType[chosenTypeId].filter((entry) =>
      entry.name.toLowerCase().includes(searchText && searchText.toLowerCase()),
    )
  }


  const addEntry = (entry: ISupplyChainEntry) => {

    if (! selectedEntriesForSupplyChainType) { selectedEntriesForSupplyChainType = [] }

    if (selectedEntriesForSupplyChainType.every((selectedEntry) => selectedEntry.id !== entry.id)) {
      selectedEntriesForSupplyChainType = [...selectedEntriesForSupplyChainType, entry]
    }

    updateConnectedEntriesForType(chosenTypeId, selectedEntriesForSupplyChainType)
    emptySearchField()
  }

  const removeEntry = (id: number) => {
    updateConnectedEntriesForType(
      chosenTypeId, selectedEntriesForSupplyChainType.filter((selectedEntry) => selectedEntry.id !== id),
    )
  }

  const photoPlaceholder = SUPPLY_CHAIN[chosenTypeId].photoPlaceholder

  return (
    <React.Fragment>
      <div className="hidden--sm-up">
        <select
          className="js-tab-selector form-control form-control--pill"
          onChange={(event) => onChooseSupplyChainTypeId(Number(event.target.value))}
        >
          {
            Object.keys(SUPPLY_CHAIN).map((id) => {
              const { shortName } = SUPPLY_CHAIN[Number(id)]
              return (
                <option value={id} key={id}>
                  {shortName}
                </option>
              )
            })
          }
        </select>
      </div>

      <ul className="tabs nav mb--none hidden--sm-down" role="tablist">
        {Object.keys(SUPPLY_CHAIN).map((id) => {
          const chosenType = SUPPLY_CHAIN[chosenTypeId].type
          const { type, shortName } = SUPPLY_CHAIN[Number(id)]
          return (
            <li key={type} style={{ marginRight: "4px" }}>
              <Link
                className={`tab ${type === chosenType && `active`}`}
                data-testid={`garmentFormSupplyChainTab${type}`}
                to="#"
                data-toggle="tab"
                aria-selected="true"
                onClick={() => onChooseSupplyChainTypeId(Number(id))}
              >
                {shortName}
              </Link>
            </li>
          )
        })}
      </ul>

      {chosenTypeId && (
        <div className="tab-content mt--base">
          <div className="tab-pane fade in active" role="tabpanel" id="supply-chain-fabric">
            <fieldset className="box box--light box--shadowed mb--md">
              <h3 className="title">Add {SUPPLY_CHAIN[chosenTypeId].name}</h3>

              <div className="form-group">
                <Field
                  component="input"
                  className="form-control"
                  data-testid={`garmentFormSupplyChainSearch${SUPPLY_CHAIN[chosenTypeId].type}`}
                  type="text"
                  name="searchSupplyChainType"
                  id="searchSupplyChainType"
                  value=""
                  data-empty={!searchText}
                />
                <label className="form-control-label" htmlFor="searchSupplyChainType">
                  Type to search your supply chain
                </label>
                <small className="text--quiet small">
                  * in order to connect supply chain entries you need to
                    <Link className="link" to="/supply-chain">
                    &nbsp;add them first
                    </Link>
                </small>
              </div>

              <ul className="dropdown-menu dropdown-menu show" style={{ position: "relative" }}>
                {filteredEntries.map((entry) => {
                  const { id, name } = entry
                  return (
                    <li key={id}>
                      <Link
                        className="dropdown-menu__item"
                        data-testid="garmentFormSupplyChainFilteredEntry"
                        to="#"
                        onClick={() => addEntry(entry)}
                      >
                        {name}
                      </Link>
                    </li>
                  )
                })}
              </ul>

              <div className="mb--base">
                {selectedEntriesForSupplyChainType &&
                  selectedEntriesForSupplyChainType.map((entry) => (
                    <GarmentFormSupplyChainListEntry
                      entry={entry}
                      key={entry.id}
                      photoPlaceholder={photoPlaceholder}
                      removeEntry={removeEntry}
                    />
                  ))}
              </div>
            </fieldset>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}



const selector = formValueSelector("Garment")

const mapStateToProps = (state: RootState): IStateProps => ({
  searchText: selector(state, "supplyChain.searchSupplyChainType"),
  selectedEntriesForSupplyChainType: selector(state, `supplyChain.${state.garments.supplyChainTypeId}`),
  entries: state.supplyChain.entries,
  chosenTypeId: state.garments.supplyChainTypeId,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => (
  {
    onChooseSupplyChainTypeId: (supplyChainTypeId: number) => dispatch(chooseSupplyChainTypeId({supplyChainTypeId})),
    emptySearchField: () => dispatch(change("Garment", "supplyChain.searchSupplyChainType", "")),
    updateConnectedEntriesForType:
      (id: number, data: ISupplyChainEntry[]) => dispatch(change("Garment", `supplyChain.${id}`, data)),
  }
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GarmentFormSupplyChain)

