import React from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { Field, reduxForm, formValueSelector, InjectedFormProps } from "redux-form"
import { Dispatch, Action } from "redux"


import Loading from "../components/Loading"
import { RootState } from "../reducers"
import { ICredentials } from "../reducers/UsersModels"

import { login } from "../reducers/UsersActions"

interface IFormProps {
  email: string,
  password: string,
}

interface IStateProps extends IFormProps {
  loadingLogin: boolean,
  error?: string,
}

interface IDispatchProps {
  login: (payload: ICredentials) => void
}



const Login: React.FunctionComponent<IStateProps & IDispatchProps & InjectedFormProps<IFormProps>> = (props) => {


  const { email, password, error, loadingLogin, handleSubmit } = props

  const onLogin = (data: ICredentials) => {
    props.login(data)
  }

  return (
    <React.Fragment>
      <Loading
        show={loadingLogin}
        text={"Loading..."}
        imgClass="contain center"
        divClass="col-sm-12"
      />
      {! loadingLogin && (
        <div className="auth-content__box">
          <header>
            <h1 data-testid="loginTitle" className="auth-content__title">Log In</h1>
          </header>
          { error && <p data-testid="loginError" className="text--danger">{error}</p> }
          <form onSubmit={handleSubmit(onLogin)}>
            <div className="form-group">
              <Field
                component="input"
                className="form-control"
                type="email"
                name="email"
                id="email"
                data-empty={! email}
              />
              <label className="form-control-label" htmlFor="email">
                Email address
              </label>
            </div>
            <div className="form-group">
              <Field
                component="input"
                className="form-control"
                type="password"
                name="password"
                id="password"
                value=""
                data-empty={! password}
              />
              <label className="form-control-label" htmlFor="password">
                Password
              </label>
            </div>
            {/* <div className="form-toggle mb--base">
              <Field
                component="input"
                className="form-check__input"
                type="checkbox"
                name="stay-signed-in"
                id="stay-signed-in-yes"
                value="yes"
              />
              <label className="form-check__label" htmlFor="stay-signed-in-yes">
                Stay signed in
              </label>
            </div>
            <div className="form-group mb--md">
              <Link to="/reset-password">
                <small>Forgot your password?</small>
              </Link>
            </div> */}
            <div className="form-actions mt--md">
              <button
                data-testid="loginButton"
                className="button button--primary button--square button--block button--lg"
                type="submit"
              >
                Log In
              </button>
            </div>
          </form>
        </div>
      )}
    </React.Fragment>
  )
}


const selector = formValueSelector("login")

const mapStateToProps = (state: RootState): IStateProps => ({
  email: selector(state, "email"),
  password: selector(state, "password"),
  loadingLogin: state.users.isLoadingLogin,
  error: state.users.errorLogin,
})

const mapDispatchToProps =
  (dispatch: Dispatch<Action>): IDispatchProps => (
    {
      login: (payload: ICredentials) => dispatch(login.started({payload})),
    }
  )

const LoginConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login as any)

export default reduxForm({
  form: "login",
})(LoginConnected as any)

