import React, { useEffect } from "react"
import { Field, FieldArray, initialize } from "redux-form"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import { chooseColor } from "../../reducers/GarmentActions"

import qrCodeLight from "../../images/qr-code-light.svg"
import { IUser } from "../../reducers/UsersModels"
import { IGarment } from "../../reducers/GarmentModels"
import { RootState } from "../../reducers"
import { Dispatch, Action } from "redux"


interface IOwnProps {
  fields: any,
  onChooseColor: (color: string) => void,
  user?: IUser,
  garment?: IGarment,
}

const renderUnitInformation: React.FunctionComponent<IOwnProps> = (props) => {

  const { fields, user, garment, onChooseColor } = props

  const QrCodeBaseUrl = "https://storage.googleapis.com/codes.cacotec.com/"

  useEffect(() => {
    const colors: string[] = []
    fields.map((field: any, index: number) => {
      const { color } = fields.get(index)
      if (color) {
        colors[color] = color
      }
    })
    onChooseColor(Object.keys(colors)[0])
  })

  let brandName: string = ""
  if (user) {
    brandName = user.brand_name
  }
  let styleNumber: string = ""

  if (garment && garment.basicInformation) {
    ({ basicInformation: { styleNumber } } = garment)
  }

  if (! brandName ) {
    return null
  }

  let listOfUpcs: string[] = []
  if (garment && garment.unitInformation) {
    listOfUpcs = garment.unitInformation.map((info) => info.upc)
  }

  const normalizeUpc = (value: any) =>  {
    if (value.includes("-")) {
      return value.replace(/-/g, "")
    }
    return value
   }

  return (
    <React.Fragment>
      <div className="mb--md mb-sm--base">
        {fields.map((field: any, index: number) => {
          const { upc, quantity, color, size } = fields.get(index)
          return (
            <React.Fragment key={index}>
              <div className="row">
                <div className="col-sm-8">
                  <div className="row">
                    <div className="col-sm-8">
                      <div className="form-group">
                        <Field
                          component="input"
                          className="form-control"
                          data-testid={`garmentFormUpc[${index}]`}
                          type="text"
                          name={`${field}.upc`}
                          data-empty={! upc}
                          id={`${field}.upc`}
                          normalize={normalizeUpc}
                        />
                        <label className="form-control-label" htmlFor={`${field}.upc`}>
                          UPC
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <Field
                          component="input"
                          className="form-control"
                          data-testid={`garmentFormQuantity[${index}]`}
                          type="number"
                          name={`${field}.quantity`}
                          data-empty={! quantity}
                          id={`${field}.quantity`}
                        />
                        <label className="form-control-label" htmlFor={`${field}.quantity`}>
                          Qty.
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-8">
                      <div className="form-group">
                        <Field
                          component="input"
                          className="form-control"
                          data-testid={`garmentFormColor[${index}]`}
                          type="text"
                          name={`${field}.color`}
                          data-empty={!color}
                          id={`${field}.color`}
                        />
                        <label className="form-control-label" htmlFor={`${field}.color`}>
                          Color
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <Field
                          component="input"
                          className="form-control"
                          data-testid={`garmentFormSize[${index}]`}
                          type="text"
                          name={`${field}.size`}
                          data-empty={!size}
                          id={`${field}.size`}
                        />
                        <label className="form-control-label" htmlFor={`${field}.size`}>
                          Size
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="qr-code">
                    <img
                      src={
                        listOfUpcs.includes(upc) && styleNumber
                          ? `${QrCodeBaseUrl}${brandName}/${styleNumber}/${upc}/${upc}-1.png`
                          : qrCodeLight
                      }
                      alt="QR code"
                    />
                    {quantity && quantity > 0 ? (
                      <Link className="qr-code__counter" to="#">
                        {quantity}
                      </Link>
                    ) : null}
                  </div>
                </div>
              </div>
              <Link
                className="link--danger small remove-upc-block"
                data-testid={`garmentFormUnitInfoRemove[${index}]`}
                to="#"
                onClick={() => fields.remove(index)}
              >
                Remove
              </Link>
            </React.Fragment>
          )
        })}
      </div>
      <Link
        className="button button--outline-dark button--sm"
        data-testid="garmentFormAddAnotherUnitInfo"
        to="#"
        onClick={() => [fields.push({})]}
      >
        Add Another
      </Link>
    </React.Fragment>
  )
}

interface IStateProps {
  user?: IUser,
  garment?: IGarment,
}

interface IDispatchProps {
  onChooseColor: (color: string) => void,
}

const UnitInformation: React.FunctionComponent<IStateProps & IDispatchProps> = (props) => {

  const { user, garment, onChooseColor } = props
  return (
    <FieldArray
      component={renderUnitInformation as any}
      name={`unitInformation`}
      onChooseColor={onChooseColor}
      user={user}
      garment={garment}
    />
  )
}

const mapStateToProps = (state: RootState): IStateProps => ({
  user: state.users.currentUser,
  garment: state.garments.garment,
})

const mapDispatchToProps =
  (dispatch: Dispatch<Action>): IDispatchProps => (
    {
      onChooseColor: (color: string) => dispatch(chooseColor({ color })),
    }
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(UnitInformation) as any,
)
