import React from "react"
import Icon from "./Icon"


interface IOwnProps {
  photoPlaceholder: string,
  name: string,
}

const SupplyChainListEmpty: React.FunctionComponent<IOwnProps> = (props) => {

  const { photoPlaceholder, name } = props

  return (
    <div className="content-column__body">
      <div className="box box--sm box--bordered" data-testid={`${photoPlaceholder}`}>
        <Icon name={photoPlaceholder} className="icon icon--xxl mb--sm" />
        <div className="txtc text-sm">
          You haven’t added any {name} yet. Once you do, you will be able to add them to garments of your
          choosing.
        </div>
      </div>
    </div>
  )
}

export default SupplyChainListEmpty
